import React from "react";
import { Button, Typography, Box, DialogTitle, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles, useTheme } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "./LoadingButton";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    color: theme.palette.primary.main,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

}));

const Modal = ({
  open,
  handleClose,
  title,
  content,
  savetext,
  cancelText,
  handleApprove,
  handleReject,
  maxWidth,
  loading = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (

    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={maxWidth || 'sm'}
        fullWidth
      >
          <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
            {title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon color="error" />
          </IconButton>
        <DialogContent dividers>
          {content}
        </DialogContent>
        {(cancelText || savetext) &&
          <DialogActions sx={{ m: 1 }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleReject}
              autoFocus
              disabled={loading}
            >
              {cancelText}
            </Button>
            <LoadingButton
              variant="contained"
              onClickHandler={handleApprove}
              loading={loading}
              text={savetext}
            />
          </DialogActions>}
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Modal;
