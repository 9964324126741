import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NoDataFound from "../../common/noDataFound";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles, useTheme } from "@mui/styles";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { ApiGet } from "../../helper/API/ApiData";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 536,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "10px",
    backgroundColor: theme.palette.bgWhite.main,
    borderRadius: "10px",
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    gap: "15px",
    border: `1px dotted  ${theme.palette.primary.main}`
  },

  list_card: {
    padding: "10px",
    backgroundColor: theme.palette.bgWhite.main,
    borderRadius: "10px",
    // height: "500px",
    border: `1px dotted  ${theme.palette.primary.main}`
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [series1, setSeries1] = useState([]);
  const [options1, setOptions1] = useState({
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    responsive: [],
  });

  const [dashboardData, setDashboardData] = useState();
  const [subPlanUser, setSubPlanUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDashboardData = () => {
    setLoading(true);
    ApiGet("admin/dashboard")
      .then((response) => {
        if (response.status === 200) {
          setDashboardData(response.data);
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const fetchSubUsers = () => {
    setLoading(true);
    ApiGet("admin/subscriptionPlanUserList")
      .then((response) => {
        if (response.status === 200) {
          setSubPlanUser(response.data);
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const fetchChartData = () => {
    setLoading(true);
    ApiGet("admin/getPlanGraph")
      .then((response) => {
        if (response.status === 200) {
          if(response.data.length > 0){
            const chartData = response.data[0];
            
            const updatedSeries = [chartData.marketPlan, chartData.learningPlan];
            const updatedOptions = {
              ...options1,
              labels: ["Market Plan Users", "Learning Plan users"],
            };
            setSeries1(updatedSeries);
            setOptions1(updatedOptions);

          }
         
        } else {
          toast.error(response.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    fetchDashboardData();
    fetchSubUsers();
    fetchChartData();
  }, []);

  const handleBoxClick = (name) => {
    if (name === "user") {
      navigate("/users");
    } else if (name === "subscribe") {
      navigate("/users?subscribedUser=true", { state: "1" });
    } else if (name === "feedback") {
      navigate("/feedback");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          {loading ? (
            <div style={{
                position: "absolute",
                top: "50%",
                left: "53%",
              transform: "translate(-50%, -50%)"
            }}>
              <StyledTableCell colSpan={8}>
                <Loader />
              </StyledTableCell>
            </div>
          ) : (
            <>
              <Grid item xs={12} md={3} sm={6}>
                <Box className={classes.card} sx={{ ":hover": { cursor: 'pointer' } }} onClick={() => handleBoxClick("user")}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    textAlign={'center'}
                  >
                    Total Users
                  </Typography>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.bgGray.main}
                    textAlign={'center'}
                  >
                    {dashboardData?.userCount}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <Box className={classes.card} sx={{ ":hover": { cursor: 'pointer' } }} onClick={() => handleBoxClick("subscribe")}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    textAlign={'center'}

                  >
                    Subscribe Users
                  </Typography>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.bgGray.main}
                    textAlign={'center'}
                  >
                    {dashboardData?.subscribeUser}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <Box className={classes.card} sx={{ ":hover": { cursor: 'pointer' } }}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    textAlign={'center'}
                  >
                    Users Expire Soon
                  </Typography>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.bgGray.main}
                    textAlign={'center'}
                  >
                    {dashboardData?.userExpireSoon}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <Box className={classes.card} sx={{ ":hover": { cursor: 'pointer' } }} onClick={() => handleBoxClick("feedback")}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    textAlign={'center'}
                  >
                    Pending Feedback
                  </Typography>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.bgGray.main}
                    textAlign={'center'}
                  >
                    {dashboardData?.pendingFeedback}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} my={4}>
                <Box className={classes.list_card}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    mb={1}
                  >
                    Expire Users
                  </Typography>
                  <TableContainer elevation={0} sx={{ maxHeight: 250 }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell align="left">Number</StyledTableCell>
                          <StyledTableCell align="left">Email</StyledTableCell>
                          <StyledTableCell align="left">Plan_End</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subPlanUser?.length > 0 ? subPlanUser?.map((item) => (
                            <>
                              <StyledTableRow key={item?.userId?._id}>
                                <StyledTableCell>
                                  {item?.userId?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {item?.userId?.mobileNo}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {item?.userId?.email}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                {item?.planData[0]?.planEndDate && moment(item?.planData[0]?.planEndDate).format("DD/MM/YYYY")}
                                </StyledTableCell>
                              </StyledTableRow>
                            </>

                        )) : <StyledTableRow>
                            <StyledTableCell colSpan={12}>
                              <NoDataFound elevation={0} />
                            </StyledTableCell>
                        </StyledTableRow >
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={5} my={4}>
                <Box className={classes.card} height={'200px'}>
                  <Typography
                    fontSize={"17px"}
                    fontWeight={"500"}
                    color={theme.palette.primary.main}
                    mb={1}
                  >
                    Subscription Plan Users
                  </Typography>
                  
                    <ReactApexChart
                      options={options1}
                      series={series1}
                      type="donut"
                      width={"135%"}
                    />

                </Box>
              </Grid> */}
            </>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
