import './loader.css'
import React from 'react'
import { Box } from '@mui/material'

function Loader() {
  return (
    <Box display={'flex'} justifyContent={'center'} padding={10}>
            <div className="loader"></div>
        </Box>
  )
}

export default Loader