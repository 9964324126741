import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';
import { ToastContainer } from 'react-toastify';



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));





const AuthLayout = ({ children }) => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header isopen={open} />
            <Sidebar isopen={open} handleDrawerClose={() => handleDrawerClose()} handleDrawerOpen={() => handleDrawerOpen()} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow:'hidden' }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    )
}

export default AuthLayout