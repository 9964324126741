import React from "react";
import { Dialog } from "@mui/material";

const CustomDialog = ({
  isOpen,
  maxWidth,
  handleClose,
  children,
  width,
  title
}) => {
  return (
    <>
      <Dialog
        onClick={() => { }}
        maxWidth={maxWidth ? maxWidth : "auto"}
        sx={{ width: width ? width : "auto" }}
        open={isOpen}
        onClose={handleClose}
        arial-labelledby="max-width-dialog-title"
        title={title}
      >
        {children}
      </Dialog>
    </>
  );
};

export default CustomDialog;

