import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TablePagination,
  TextField,
  Divider,
  Grid,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/Loader";
// import Modal from "../../common/Modal";
import CommonTextField from "../../common/commonTextField";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import moment from "moment";
import ConfirmationModal from "../../common/ConfirmationModal";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import Modal from "../../common/Modal";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  placeholder: {
    '&::placeholder': {
      fontWeight: 600
    },
  },
  
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  // p: 4,
  borderRadius: '10px',
};

function TabTable() {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabName, setTabName] = useState("");
  const [stockList, setStockList] = useState([]);
  const [index, setIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);

  // const handleOpen = () => setOpen(true);

  const handleClear = () => {
    setTabName("");
    setIndex(null);
    setOpen(false);
    setIsEdit(false);
    setConfirmationModal(false);
    setErrors({})
  };

  const tabEditHandler = (data) => {
    setIsEdit(true);
    setTabName(data.name);
    setIndex(data?._id);
  };

  const validation = () => {
    let obj = {};
    let flag = true;
    if (tabName === "") {
      obj.tabName = "Please enter the name";
      flag = false;
    }
    setErrors(obj);
    return flag;
  };

  const onDeleteButtonClick = (data) => {
    setConfirmationModal(true);
    setIndex(data._id);
  };
  const deleteStockType = () => {
    setLoading(true);
    ApiDelete(`admin/deleteTabs?id=${index}`)
      .then((res) => {
        const error = ErrorHandler(res)
        if (error) {
          toast.success(res.data.message);
          handleClear();
          fetchStockType();
        }
      })
      .catch((err) => { toast.error(err?.message); })
      .finally(() => { setLoading(false); });
  };

  const fetchStockType = () => {
    setLoading(true);
    ApiGet("admin/getTabs")
      .then((res) => {
        const error = ErrorHandler(res)
        if (error) {
          setStockList(res?.data);
          handleClear();
        }
      })
      .catch((err) => { toast.error(err?.message); })
      .finally(() => { setLoading(false); });
  };

  const onChangeHandler = (e) => {
    setTabName(e.target.value);
    setErrors({});
  };

  const renderDate = (data) => {
    const dateString = data;
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  const addStockType = () => {
    if (validation()) {
      setLoading(true);
      const capitalizedName = capitalizeWords(tabName); 

      const body = {
        name: capitalizedName
      };
      console.log("body is ", body)
      ApiPost("admin/addTabs", body)
        .then((response) => {
          const error = ErrorHandler(response)
          if (error) {
            toast.success(response.message);
            handleClear();
            fetchStockType();
          }
        })
        .catch((err) => { toast.error(err?.message); })
        .finally(() => { setLoading(false); });
    }
  };

  const handleEditSubmit = () => {
    if (validation()) {
      setLoading(true);
      const body = {
        id: index,
        name: tabName,
      };
      ApiPut("admin/editTabs", body)
        .then((res) => {
          const error = ErrorHandler(res)
          if (error) {
            toast.success(res.data.message);
            handleClear();
            fetchStockType();
          }
        })
        .catch((err) => { toast.error(err?.message); })
        .finally(() => { setLoading(false); setIsEdit(false); });
    }
  };

  useEffect(() => {
    fetchStockType();
  }, []);
  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Stock Type
          </Typography>
          <Button onClick={() => setOpen(true)} variant="contained">
            Add Stock Type
          </Button>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No.</StyledTableCell>
                <StyledTableCell align="left">Stock Name</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <Loader />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {stockList?.length > 0 ? stockList?.map((data, index) => (
                    <StyledTableRow key={data?._id}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {renderDate(data?.updatedAt)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box
                          display={"flex"}
                          gap={1}
                          justifyContent={"center"}
                        >
                          <EditIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => tabEditHandler(data)}
                          />
                          <DeleteIcon
                            sx={{
                              marginLeft: "10px",
                              color: theme.palette.error.main,
                              cursor: "pointer",
                            }}
                            onClick={(e) => onDeleteButtonClick(data)}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  )) : <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <NoDataFound elevation={0} />
                    </StyledTableCell>
                  </StyledTableRow>}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Modal
          open={open || isEdit}
          handleClose={handleClear}
          title={isEdit ? "Edit Stock Type" : "Add Stock Type"}
          savetext={isEdit ? "Update" : "Add"}
          cancelText={"Cancel"}
          handleReject={handleClear}
          handleApprove={isEdit ? handleEditSubmit : addStockType}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1} mb={'12px'}>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Stock Name"}
                    placeholder={"Enter a name for the stock type"}
                    type="text"
                    name="stockType"
                    value={tabName}
                    onChange={(e) => onChangeHandler(e)}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {errors?.tabName && (
                    <Typography
                      color="error"
                      fontSize={"12px"}
                      style={{ marginTop: "2px" }}
                    >
                      {errors.tabName}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          }
        />
        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={deleteStockType}
          headTitle={"Are you sure you want to delete this Stock Type?"}
          loading={loading}
        />
      </Box>
    </>
  );
}

export default TabTable;
