import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from './theme';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />
        <ThemeProvider theme={lightTheme}>
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
