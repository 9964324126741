import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Typography, TextField, Grid, Button, InputAdornment, IconButton, CircularProgress, useTheme, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { ApiPostNoAuth } from '../../helper/API/ApiData';
import STORAGEKEY from '../../config/APP/app.config';
import AuthStorage from '../../helper/AuthStorage';
import { isEdge } from 'react-device-detect';
import { generateToken, genrateToken, messaging } from '../../firebase';
import { onMessage } from 'firebase/messaging';

const useStyles = makeStyles((theme) => {
  return {
    login_main: {
      height: '100vh',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url("/login-wallpaper.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.8,
        zIndex: -1,
      }
    },
    login_card: {
      boxShadow: ' rgb(38, 57, 77) 0px 5px 25px -10px',
      // border:'3px outset #d8d8d8',
      width: '80%',
      maxWidth: '400px',
      padding: '25px',
      borderRadius: '20px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // background: '#7aa0ff00',
      opacity: 0.9,
    },
    login_button: {
      backgroundColor: '#1C293B !important',
      '&:hover': {
        background: '#10192B !important',
      },
      fontFamily: '"Poppins", sans-serif !important',
      width: '50%',
      fontSize: "14px"
    },
    image: {
      width: '180px',
      height: '65px',
      marginBottom: '15px',
      margin: 'auto',
      display: 'block'
    },
    login_title: {
      color: '#2f2f2f',
      textAlign: 'center',
      fontWeight: '400 !important',
      position: 'relative',
      fontWeight: 'bold',
      color: '#000',
      textShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
      '::before': {
        content: '""',
        position: 'absolute',
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        // border: '3px double #000',
        borderRadius: '8px',
        zIndex: -1,
      }
    },
    login_subtitle: {
      color: '#151F31',
      fontFamily: '"Kanit", sans-serif !important',
    },
    textfield: {
      '& label.Mui-focused': {
        color: '#151F31',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#151F31',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#151F31',
          borderWidth: '1px'
        },
      },
    },
  }
});

const Login = () => {
  const theme = useTheme()
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    setFormErrors({ ...formErrors, [name]: "" })
    if (name == "email") {
      setEmail(value)
    } else if (name == "password") {
      setPassword(value)
    }
  }

  const validation = () => {
    let flag = false
    const error = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      error.email = "Please enter the email"
      flag = true
    } else if (!emailRegex.test(email)) {
      error.email = "Please enter the correct email"
      flag = true
    }
    if (!password) {
      error.password = "Please enter the password"
      flag = true
    } else if (password && password.length < 8) {
      error.password = "Please enter minimum 8 character required"
      flag = true
    }
    setFormErrors(error);
    return flag
  }

  const submit = async (e) => {
    e.preventDefault();
    if (validation()) {
      return;
    }

    const body = {
      email: email,
      password: password
    };

    setLoading(true);
    try {
      const res = await ApiPostNoAuth("user/login", body);
      if (res.status === 200) {
        toast.success(res?.message);
        AuthStorage.setStorageData(STORAGEKEY.token, res?.data?.token, true);
        AuthStorage.setStorageJsonData(STORAGEKEY.userData, res?.data, true);
        AuthStorage.setStorageData(STORAGEKEY.refreshToken, res?.data?.refreshToken, true);
        navigate('/dashboard');

        // Try to generate the token after login
        const token = await generateToken();
        if (token) {
          console.log('FCM Token generated:', token);
        }

        onMessage(messaging, (payload) => {
          console.log("Notification payload:", payload);
        });

      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box className={classes.login_main}>
      <form onSubmit={submit}>

        <Box className={classes.login_card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <img src="Frame 345.png" alt="Login" className={classes.image} />

              <Typography variant="body1" className={classes.login_title} mt={3}>
                {/* SHAH APPLICATION ADMIN */}
                {/* Stock Inn */}
              </Typography>
              <Typography variant="h6" className={classes.login_title} mt={1}>
                LOGIN
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <TextField
                size='small'
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="filled"
                className={classes.textfield}
                onChange={(e) => handleChange(e)}
                name='email'
                style={{ color: 'red' }}
              />
              {formErrors?.email && (
                <Typography variant="body2" color="error" style={{ fontSize: '0.7rem', marginTop: '5px', fontFamily: '"Poppins", sans-serif' }}>
                  {formErrors?.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size='small'
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="filled"
                type={showPassword ? "text" : "password"}
                className={classes.textfield}
                InputProps={{
                  endAdornment: !isEdge && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility sx={{ color: "#151F31" }} /> : <VisibilityOff sx={{ color: "#151F31" }} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="password"
                onChange={(e) => handleChange(e)}
              />
              {formErrors?.password && (
                <Typography variant="body2" color="error" style={{ fontSize: '0.7rem', marginTop: '5px', fontFamily: '"Poppins", sans-serif' }}>
                  {formErrors?.password}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
              <Button
                size='medium'
                variant='contained'
                type='submit'
                disabled={loading}
                endIcon={loading && <CircularProgress size={24} color="inherit" />}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default Login