import React from 'react'
import { ToastContainer } from 'react-toastify'

const Layout = ({ children }) => {
    return (
        <>
            <div className='layout_main'>
                {children}
            </div>
        </>
    )
}

export default Layout