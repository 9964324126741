import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer from './users/userReducer';
import tipsReducer from './tips/tipsReducer';
import feedbackReducer from './feedback/feedbackReducer';
import reportReducer from './reportedFeedback/reportReducer';

const middleware = [thunk];

const rootReducer = combineReducers({
  users : userReducer,
  tips : tipsReducer,
  feedback : feedbackReducer,
  report : reportReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);


export default store;