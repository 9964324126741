import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";
import { ApiPost, ApiGet, ApiPut } from "../../helper/API/ApiData";
import ErrorHandler from "../../helper/errorHandler";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  card_main: {
    backgroundColor: theme.palette.bgWhite.main,
    padding: "15px",
    borderRadius: "10px",
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  input_main: {
    "& .MuiOutlinedInput-root": {
      "& input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
}));

const ReferralAdd = () => {
  //Hooks
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  //States
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    referDay: null,
    address: "",
  });

  //Event Handlers
  const onChangeHandler = (e) => {
    setSubscriptionData({
      ...subscriptionData,
      [e.target.name]: e.target.value,
    });

    if (errors[e.target.name] !== "") {
      setErrors({
        ...errors,
        [e.target.name]: "",
      });
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const onCancelHandler = () => {
    setSubscriptionData({
      referDay: null,
      address: "",
    });
    navigate("/subscription");
  };

  //API Calling Function
  const onAddHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const body = {
      referDay: subscriptionData.referDay,
      address: subscriptionData.address,
    };

    ApiPost("admin/addReferDay", body)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/subscription");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const onEditHandler = () => {
    setLoading(true);
    if (validation()) {
      return;
    }

    const payload = {
      id: subscriptionData._id,
      referDay: subscriptionData.referDay,
      address: subscriptionData.address,
    };

    ApiPut("admin/editReferDay", payload)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/subscription");
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const fetchData = () => {
    setLoading(true);

    ApiGet("user/getReferDay")
      .then(async (res) => {
        const error = await ErrorHandler(res);
        if (error) {
          if (res?.data) {
            setSubscriptionData(res?.data);
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //function
  const validation = () => {
    const obj = {};
    let flag = false;

    if (subscriptionData.referDay === null) {
      obj.referDay = "Please enter the referral days !";
      flag = true;
    }

    if (subscriptionData.address === "") {
      obj.address = "Please enter the address !";
      flag = true;
    }

    setErrors(obj);
    return flag;
  };

  //useEffects
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            {id ? "Edit Referral Days" : "Add Referral Days"}
          </Typography>
        </Box>
        <Box className={classes.card_main}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "230px",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Referral Days"}
                  placeholder={"Enter Days"}
                  name="referDay"
                  value={subscriptionData.referDay}
                  type="number"
                  inputProps={{ min: 0 }}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                />
                {errors.referralDays && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.referralDays}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Adress"}
                  placeholder={"Enter Adress"}
                  name="address"
                  value={subscriptionData.address}
                  type="text"
                  inputProps={{ min: 0 }}
                  onChange={onChangeHandler}
                />
                {errors.address && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.address}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={onCancelHandler}
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={id ? onEditHandler : onAddHandler}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReferralAdd;
