import React, { useEffect, useState } from "react";
import { useTheme, makeStyles, styled } from "@mui/styles";
import { Box, Typography, Tab, InputLabel, Avatar, Grid, TextField } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {  toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchReports, deleteReportFeedback } from "../../redux/reportedFeedback/repotActionns";
import ErrorHandler from "../../helper/errorHandler";
import CommonTable from "./CommonTable";
import Modal from "../../common/Modal";
import ViewModal from '../../common/ViewModal'

const useStyles = makeStyles((theme) => ({
    header_main: {
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tab_main: {
        "& .Mui-selected": {
            color: theme.palette.primary.main,
        },
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    modal_text_field: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '1px',
        },
    },
}));

const UserContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
});

const UserDetails = styled('div')({
    marginLeft: '16px',
});

const ReportedFeedback = () => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [status, setStatus] = useState("0");
    const [reportFeedbackData, setReportFeedbackData] = useState({});
    const [removedFeedbackData, setRemovedFeedbackData] = useState({});
    const [removeModal, setRemoveModal] = useState(false);
    const [id, setId] = useState();
    const [isModal, setIsModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const[rejectionReason , setRejectionReason] = useState("");
    const[errors , setErrors] = useState({})

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event, newValue) => {
        setStatus(newValue);
        setPage(0);
        // setRowsPerPage(10);
    };
    const clearState = ()=>{
        setLoading(false); 
        setRemoveModal(false);
        setRejectionReason("");
        setErrors({})
    }
  
    const getReportData = () => {
        setLoading(true);
        dispatch(fetchReports(page + 1, rowsPerPage, status))
            .then((res) => {
                const error = ErrorHandler(res);
                if (error) {
                    if (status === "0") {
                        setReportFeedbackData(res.data);
                    }
                    else {
                        setRemovedFeedbackData(res.data);
                    }
                }
            })
            .catch((err) => {
                toast.error(err?.message);
            }).finally(() => { setLoading(false); });
    };

    const onDeleteHandler = (id) => {
        setId(id);
        setRemoveModal(true);
    }
    const validation = ()=>{
        const obj = {};
        let flag = false;
        if(rejectionReason === ""){
            flag = true;
            obj.rejectionReason = "Please enter the rejection reason !"
        }
        setErrors(obj);
        return flag;
    }
    const deleteHandler = (id) => {
        if(validation()){
            return ;
        }
        setLoading(true);
        const payload = {
            id : id,
            reason : rejectionReason
        }
        dispatch(deleteReportFeedback(payload))
            .then((response) => {
                const error = ErrorHandler(response.data)
                if (error) {
                    toast.success(response.data.message);
                    getReportData();
                }
            })
            .catch((err) => { toast.error(err.message); })
            .finally(() => { clearState()});
    }

    const openModeHandler = (feedback) => {
        setSelectedFeedback(feedback);
        setIsModal(true);

    };

    const onCloseHandler = function(){
       clearState();
    }

    useEffect(() => {
        getReportData();
    }, [page, rowsPerPage, status]);

    return (
        <>
            <Box>
                <Box className={classes.header_main}>
                    <Typography variant="h6" sx={{ fontWeight: "600", color: theme.palette.primary.main }}>
                        Reported Feedback
                    </Typography>
                </Box>
                <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={status}>
                        <Box className={classes.tab_main} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab sx={{ fontWeight: "600" }} label="Reported Feedback" value="0" />
                                <Tab sx={{ fontWeight: "600" }} label="Removed Feedback" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ paddingX: 0, paddingY: 1 }}>
                            <CommonTable value="0"
                                loading={loading}
                                data={reportFeedbackData}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                onDeleteHandler={onDeleteHandler}
                                showAction={true}
                                openModeHandler={openModeHandler}
                            />
                        </TabPanel>
                        <TabPanel value="1" sx={{ paddingX: 0, paddingY: 1 }}>
                            <CommonTable value="1"
                                loading={loading}
                                data={removedFeedbackData}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                showAction={false}
                                rejectionTable = {true}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>

                {/* {isModal && (
                    <Modal
                        open={isModal}
                        handleClose={() => setIsModal(false)}
                        title={"View Reported Feedback"}
                        loading={loading}
                        content={
                            selectedFeedback && (
                                <Box>
                                    {selectedFeedback?.reportData?.map((report, index) => (
                                        <UserContainer key={index}>
                                            <Avatar src={report.image} />
                                            <UserDetails>
                                                <Typography variant="subtitle1"> <span style={{fontWeight: 700}}>Name : </span> {report.name || "-"}</Typography>
                                                <Typography variant="body2"><span style={{fontWeight: 700}}>Reason for Report : </span> {report.description}</Typography>
                                            </UserDetails>
                                        </UserContainer>
                                    ))}
                                </Box>
                            )
                        }
                    />
                    
                )} */}
                <ViewModal 
                open={isModal}
                title={"View Reported Feedback"}
                content={
                    selectedFeedback && (
                        <Box>
                            {selectedFeedback?.reportData?.map((report, index) => (
                                <UserContainer key={index}>
                                    <Avatar src={report.image} />
                                    <UserDetails>
                                        <Typography variant="subtitle1"> <span style={{fontWeight: 700}}>Name : </span> {report.name || "-"}</Typography>
                                        <Typography variant="body2"><span style={{fontWeight: 700}}>Reason for Report : </span> {report.description}</Typography>
                                    </UserDetails>
                                </UserContainer>
                            ))}
                        </Box>
                    )
                }
                buttonText={"Close"}
                handleClose={()=>setIsModal(false)}
                />

                

                {/* {removeModal && (
                    <ConfirmationModal
                        open={removeModal}
                        handleClose={() => setRemoveModal(false)}
                        onDeleteHandler={() => deleteHandler(id)}
                        headTitle={"Are you sure you want to Remove this Reported Feedback?"}
                        loading={loading}
                        maxWidth="md"
                    />
                )} */}
                  <Modal
                    open={removeModal}
                    handleClose={onCloseHandler}
                    handleReject={onCloseHandler}
                    title={"Remove Feedback"}
                    handleApprove={() => deleteHandler(id)}
                    loading={loading}
                    content={
                        <>
                        <Grid container spacing={1} xs={12} px={1} mb={3}>
                            <Grid item xs={12}>
                            <InputLabel
                                sx={{ color: theme?.palette?.bgBlack?.main, fontSize: "18px", fontWeight: 500 }}
                                shrink
                                htmlFor="reason"
                            >
                                Reason for feedback removal
                            </InputLabel>
                            <TextField
                                id="reason"
                                name="reason"
                                onChange={(e) => { setRejectionReason(e.target.value) ; setErrors({})}}
                                error={Boolean(errors.rejectionReason)}
                                helperText={errors.rejectionReason}
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth
                                className={classes.modal_text_field}
                            />
                            </Grid>
                        </Grid>
                        </>
                    }
                    savetext={"Submit"}
                    cancelText={"Close"}
                    />
                        </Box>
                    </>
    );
};

export default ReportedFeedback;
