import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  TableRow,
  Pagination,
  InputLabel,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CommonTextField from "../../common/commonTextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader";
import ErrorHandler from "../../helper/errorHandler";
import Modal from "../../common/Modal";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import ConfirmationModal from "../../common/ConfirmationModal";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import NoDataFound from "../../common/noDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewModal from "../../common/ViewModal";
import { debounce } from "../../helper/utils";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-cropper";
import LoadingButton from "../../common/LoadingButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  input: {
    display: "none",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none !important",
  },
  video: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  cardContent: {
    padding: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingBottom: "0 !important",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  nameText: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  dropZone: {
    padding: theme.spacing(2),
    border: `2px dashed ${theme.palette.primary.main}`,
    textAlign: "center",
    cursor: "pointer",
  },
  videoPreview: {
    width: "100%",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  inputFile: {
    "&::before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0) !important",
    },
    "&::after": {
      borderBottom: "2px solid rgba(0, 0, 0, 0)   !important",
    },
  },
  inputLabel: {
    color: `${theme?.palette?.bgBlack?.main} !important`,
    marginRight: "3px !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    padding: "3px !important",
  },
}));

function PromotionVideos() {
  const theme = useTheme();
  const classes = useStyles();

  const itemsPerPage = 8;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoData, setVideoData] = useState({
    videoLink: "",
    name: "",
    description: "",
    thumbnail: "",
  });
  const [errors, setErrros] = useState({
    videoLink: "",
    name: "",
    description: "",
    thumbnail: "",
  });
  const [isLarge, setIsLarge] = useState("");
  const [isWrong, setIsWrong] = useState("");
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [videoLoader, setVideoLoader] = useState(false);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("");
  const [croppedLogo, setCroppedLogo] = useState(null);
  const [disableDropzone, setDisableDropzone] = useState(false);

  const handleVideoClick = (videoId) => {
    const updatedData = data.map((item) => {
      if (item._id == videoId) {
        return { ...item, showControls: !item.showControls };
      }
      return item;
    });
    setData(updatedData);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    setVideoData({ videoLink: "", name: "", description: "", thumbnail: "" });
    setErrros({
      video: "",
      videoLink: "",
      name: "",
      thumbnail: "",
    });
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setVideoLoader(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setVideoData((prev) => ({ ...prev, videoLink: res?.data?.image }));
        setVideoLoader(false);
        setErrros({ ...errors, videoLink: "" });
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setVideoLoader(false));
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setIsWrong(`Only videos are allowed`);
        } else if (error.code === "file-too-large") {
          setIsLarge(`File size should be less than 50 MB`);
        }
      });
    });
  };
  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();
    setShowCropper(false);
    setUploadedLogo(null);
    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisableDropzone(true);
    setThumbnailLoader(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setVideoData({ ...videoData, thumbnail: res?.data?.image });
        setErrros({ ...errors, thumbnail: "" });
        setShowLogoPicker(false);
        setDisableDropzone(false);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setThumbnailLoader(false));
  };

  const handleThumbnailDrop = (acceptedFiles) => {
    setShowLogoPicker(true);
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name);
  };
  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };
  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );
  };
  // const handleThumbnailDrop = (acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   const formData = new FormData();

  //   formData.append("image", file);
  //   const headers = {
  //     isAuth: true,
  //     AdditionalParams: {},
  //     isJsonRequest: false,
  //     isMultiPart: true,
  //   };
  //   setThumbnailLoader(true);
  //   ApiPost("upload/image", formData, headers)
  //     .then((res) => {
  //       setVideoData({ ...videoData, thumbnail: res?.data?.image });
  //       setErrros({ ...errors, thumbnail: "" });
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //     })
  //     .finally(() => setThumbnailLoader(false));
  // };
  const handleThumbnailDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setErrros({ ...errors, thumbnail: "Only images are allowed" });
        } else if (error.code === "file-too-large") {
          setErrros({
            ...errors,
            thumbnail: "File size should be less than 5 MB",
          });
        }
      });
    });
  };
  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
    isDragActive: isThumbnailDragActive,
  } = useDropzone({
    accept: "image/*",
    onDrop: handleThumbnailDrop,
    onDropRejected: handleThumbnailDropRejected,
    maxSize: 5242880, // 5 MB limit
    disabled: thumbnailLoader,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/avi": [".avi"],
      "video/x-matroska": [".mkv"],
    },
    onDrop,
    onDropRejected,
    maxSize: 524288000, // 500 MB limit
    disabled: videoLoader,
  });

  const getPromotionVideos = () => {
    setLoading(true);
    let url = `admin/getpromotionVideo?limit=${itemsPerPage}&page=${page}`;
    if (searchValue) {
      url = `admin/getpromotionVideo?limit=${itemsPerPage}&page=${page}&search=${searchValue}`;
    }
    ApiGet(url)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            setData(res?.data.response);
            setTotalCount(res?.data?.count);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => setLoading(false));
  };

  const validation = () => {
    let flag = false;
    const obj = {};
    if (!videoData.name) {
      obj.name = "Please enter the video name !";
      flag = true;
    }
    if (!videoData.videoLink) {
      obj.videoLink = "Please select the video !";
      flag = true;
    }
    if (!videoData.thumbnail) {
      obj.thumbnail = "Please select the thumbnail !";
      flag = true;
    }
    setErrros(obj);
    return flag;
  };

  const handleSubmit = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const data = {
      title: videoData.name,
      videoUrl: videoData.videoLink,
      thumbnail: videoData.thumbnail,
    };
    if (videoData.description) {
      data.description = videoData.description;
    }
    ApiPost(`admin/promotionVideo`, data)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          toast.success(res.message);
          setVideoData({ videoLink: "", name: "", description: "" });
          setOpen(false);
          getPromotionVideos();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const data = {
      _id: id,
      title: videoData.name,
      videoUrl: videoData.videoLink,
      thumbnail: videoData.thumbnail,
    };

    if (videoData.description) {
      data.description = videoData.description;
    }
    const url = `admin/updatepromotionVideo`;
    ApiPost(url, data)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          toast.success(res.message);
          setVideoData({ videoLink: "", name: "", description: "" });
          setOpen(false);
          setIsEdit(false);
          getPromotionVideos();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
        setIsEdit(false);
      });
  };
  const handleDelete = async () => {
    setLoading(true);
    ApiDelete(`admin/deletepromotionVideo/${id}`)
      .then(async (res) => {
        let error = ErrorHandler(res);
        if (error) {
          toast.success(res?.data?.message || "Video deleted successfully");
          setConfirmationModal(false);
          getPromotionVideos();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const EditHandler = (item) => {
    setIsEdit(true);
    setId(item._id);
    setVideoData({
      videoLink: item.videoUrl || "",
      name: item.title || "",
      description: item.description || "",
      thumbnail: item.thumbnail || "",
    });
  };
  const DeleteHandler = (_id) => {
    setConfirmationModal(true);
    setId(_id);
  };
  const pageChange = (e, newPage) => {
    setPage(newPage);
  };

  const viewModalClick = (item) => {
    setViewModal(true);
    setVideoData({
      ...videoData,
      description: item.description,
    });
  };
  const viewModalCloseHandler = () => {
    setViewModal(false);
    setVideoData({
      name: "",
      videoLink: "",
      description: "",
    });
  };

  const onSearchHandler = (value) => {
    setPage(1);
    setSearchValue(value);
  };

  const debounceSearch = debounce(onSearchHandler, 500);

  useEffect(() => {
    getPromotionVideos();
  }, [page, searchValue]);

  useEffect(() => {
    console.log("setDisable", disableDropzone);
  }, [disableDropzone]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          {/* <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Promotion Videos
          </Typography> */}
          <Box display={"flex"} gap={2}>
            <CommonTextField
              fontWeight={600}
              placeholder={"Search Promotion Videos..."}
              type="text"
              name="search"
              onChange={(e) => debounceSearch(e.target.value)}
              width={250}
              inputProps={{ className: classes.placeholder }}
            />
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add promotion video
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "53%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <StyledTableCell colSpan={8}>
                <Loader />
              </StyledTableCell>
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                    <Card className={classes.card}>
                      <video
                        className={classes.video}
                        id={`video-${index}`}
                        onClick={() => handleVideoClick(item._id)}
                        controls={item.showControls || false}
                      >
                        <source src={item.videoUrl} type="video/mp4" />
                        <source src={item.videoUrl} type="video/avi" />
                        <source src={item.videoUrl} type="video/x-matroska" />
                        Your browser does not support the video tag.
                      </video>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          variant="body1"
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span className={classes.nameText}>Name:</span>
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span className={classes.nameText}>Description:</span>
                          {item.description ?? "Not found !"}
                        </Typography>
                      </CardContent>

                      <Box display={"flex"} justifyContent={"flex-end"}>
                        <CardActions className={classes.cardActions}>
                          {item?.description?.length > 0 && (
                            <IconButton
                              color="primary"
                              onClick={() => {
                                viewModalClick(item);
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          )}

                          <IconButton
                            onClick={() => EditHandler(item)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => DeleteHandler(item._id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CardActions>
                      </Box>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid container m={5}>
                  <NoDataFound elevation={0} />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Modal
          open={open || isEdit}
          handleClose={onCloseHandler}
          title={isEdit ? "Edit Video" : "Add Video"}
          savetext={isEdit ? "Update" : "Add"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={isEdit ? handleEdit : handleSubmit}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1}>
                <Grid
                  item
                  xs={12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />

                  <>
                    <Typography sx={{ fontSize: "12px", color: "red", mb: 1 }}>
                      Note :- Please select .mp4, .avi, or other video formats
                    </Typography>
                    <Card
                      style={{
                        padding: "20px",
                        border: `1px dashed ${theme.palette.primary.main}`,
                        textAlign: "center",
                        boxShadow: "none",
                      }}
                    >
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div style={{ color: theme.palette.primary.main }}>
                          <CloudUploadIcon fontSize="large" />
                          {videoLoader ? (
                            <p>Video is uploading...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                        </div>
                      )}
                    </Card>
                    {/* <Typography sx={{ fontSize: "12px", color: "red" }}>
                        * Please select .mp4, .avi, or other video formats
                      </Typography> */}
                    {isWrong && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isWrong}
                      </Typography>
                    )}
                    {isLarge && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isLarge}
                      </Typography>
                    )}
                    {errors.videoLink && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {errors.videoLink}
                      </Typography>
                    )}
                  </>
                </Grid>
                {videoData.videoLink && (
                  <>
                    <Grid item xs={12}>
                      <Card
                        style={{
                          position: "relative",
                          maxWidth: "300px",
                          maxHeight: "300px",
                        }}
                      >
                        <video controls style={{ width: "100%" }}>
                          <source src={videoData.videoLink} type="video/mp4" />
                          <source src={videoData.videoLink} type="video/avi" />
                          <source
                            src={videoData.videoLink}
                            type="video/x-matroska"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <IconButton
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 9,
                            zIndex: 1,
                            height: "5px",
                            width: "5px",
                          }}
                          onClick={() => {
                            setVideoData({ ...videoData, videoLink: "" });
                          }}
                        >
                          <CloseIcon
                            sx={{
                              background: "white",
                              color: "red",
                              borderRadius: "50%",
                            }}
                          />
                        </IconButton>
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Video Title"}
                    placeholder={"Enter Video Name"}
                    type="text"
                    name="name"
                    value={videoData.name}
                    onChange={(e) => {
                      setVideoData({ ...videoData, name: e.target.value });
                      setErrros({ ...errors, name: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {errors.name && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.name}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} pb={2}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Video Description"}
                    placeholder={"Enter Video Description"}
                    type="text"
                    name="description"
                    value={videoData.description}
                    multiline={true}
                    onChange={(e) => {
                      setVideoData({
                        ...videoData,
                        description: e.target.value,
                      });
                      setErrros({ ...errors, description: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                </Grid>
                <Grid
                  item
                  xs={videoData.thumbnail ? 9 : 12}
                  {...getThumbnailRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getThumbnailInputProps()} />
                  <>
                    <Card
                      style={{
                        padding: "20px",
                        border: `1px dashed ${theme.palette.primary.main}`,
                        textAlign: "center",
                        boxShadow: "none",
                      }}
                    >
                      {isThumbnailDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div style={{ color: theme.palette.primary.main }}>
                          <CloudUploadIcon fontSize="large" />
                          {thumbnailLoader ? (
                            <p>Image is uploading...</p>
                          ) : (
                            <p>
                              Drag 'n' drop a thumbnail image here, or click to
                              select a file (JPG, PNG)
                            </p>
                          )}
                        </div>
                      )}
                    </Card>
                    {errors.thumbnail && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {errors.thumbnail}
                      </Typography>
                    )}
                  </>
                </Grid>
                {showLogoPicker && (
                  <CustomDialog
                    isOpen={showLogoPicker}
                    handleClose={() => {
                      setShowLogoPicker(false);
                      setShowCropper(false);
                      setUploadedLogo(null);
                    }}
                  >
                    <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                    <DialogContent
                      sx={{
                        width: {
                          lg: "700px",
                          md: "700px",
                          sm: "600px",
                          xs: "400px",
                        },
                      }}
                      dividers
                    >
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <div style={{ width: "100%", position: "relative" }}>
                            <Cropper
                              style={{ height: 300, width: "100%" }}
                              src={uploadedLogo}
                              viewMode={0}
                              background={false}
                              responsive={false}
                              minCropBoxHeight={20}
                              minCropBoxWidth={10}
                              dragMode="move"
                              autoCropArea={1}
                              checkOrientation={false}
                              cropBoxResizable={true}
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={false}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"end"} gap={1}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setShowCropper(false);
                                setUploadedLogo(null);
                                setInitialLogo(null);
                                setShowLogoPicker(false);
                              }}
                              color="error"
                              sx={{ borderRadius: 5 }}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              color="primary"
                              variant="contained"
                              sx={{ borderRadius: 5 }}
                              disableElevation
                              onClickHandler={() => {
                                getCropData();
                              }}
                              loading={disableDropzone}
                              text="Crop"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </CustomDialog>
                )}
                {videoData.thumbnail && (
                  <>
                    <Grid item xs={3}>

                      <Box
                        sx={{
                          position: "relative",
                          background: `url(${videoData.thumbnail})`,
                          height: "130px",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          overflow: "hidden",
                          "&:hover .hoverButtons": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          className="hoverButtons"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Box
                            onClick={() =>
                              window.open(videoData.thumbnail, "_blank")
                            }
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            bgcolor={theme.palette.bgWhite.main}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "42%",
                              right: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: theme.palette.bgBlack.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              setVideoData({
                                ...videoData,
                                thumbnail: "",
                              });
                            }}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: theme.palette.error.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          }
        />
        {data?.length > 0 && totalCount > itemsPerPage && (
          <Box
            mt={"2rem"}
            width={"100%"}
            display={"block"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Pagination
              count={Math.ceil(totalCount / itemsPerPage)}
              onChange={pageChange}
              page={page}
            />
          </Box>
        )}
        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={handleDelete}
          headTitle={"Are you sure you want to delete this Video ?"}
          load
          ing={loading}
        />
        <ViewModal
          open={viewModal}
          buttonText={"Close"}
          content={
            <Box maxWidth={"100%"} style={{ overflowX: "hidden" }}>
              <Typography>{videoData.description}</Typography>
            </Box>
          }
          handleClose={viewModalCloseHandler}
          title={"View Description"}
        />
      </Box>
    </>
  );
}

export default PromotionVideos;
