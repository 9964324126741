import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Typography,
  InputAdornment,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import Loader from "../../common/Loader";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ApiDelete, ApiGet, ApiPost } from "../../helper/API/ApiData";
import { useDropzone } from "react-dropzone";
import Modal from "../../common/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import CommonTextField from "../../common/commonTextField";
import ConfirmationModal from "../../common/ConfirmationModal";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-cropper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "cropperjs/dist/cropper.css";
import LoadingButton from "../../common/LoadingButton";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  input: {
    display: "none",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none !important",
  },
  cardContent: {
    paddingRight: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    // padding: theme.spacing(1),
    paddingTop: "0 !important",
  },
  nameText: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  dropZone: {
    padding: theme.spacing(2),
    border: `2px dashed ${theme.palette.primary.main}`,
    textAlign: "center",
    cursor: "pointer",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const Accuracy = () => {
  // ------------ Hooks ------------
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  // ------------ state ------------
  const [loading, setLoading] = useState(false);
  const [accuracyData, setAccuracyData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [errors, setErrors] = useState({
    stockName: "",
    image: "",
    days: "",
    accuracy: "",
  });
  const [page, setPage] = useState(1);
  const [addAccuracyData, setAddAccuracyData] = useState({
    stockName: "",
    image: "",
    days: "",
    accuracy: "",
  });
  const [accuracyHeader, setAccuracyHeader] = useState("");
  const [headerId, setHeaderId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [disableDropzone, setDisableDropzone] = useState(false)
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("")
  const [croppedLogo, setCroppedLogo] = useState(null);

  // const [overAllId , setOverAllId] = useState(null)

  const itemsPerPage = 8;

  const onPageChange = (e, newPage) => {
    setPage(newPage);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setAddAccuracyData({
      stockName: "",
      image: "",
      days: "",
      accuracy: "",
    });
    setErrors({});
    setIsEdit(false)
  };

  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true)
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name)
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };

  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();
    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisableDropzone(true)
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        let checkError = ErrorHandler(res);
        if (checkError) {
          setAddAccuracyData({ ...addAccuracyData, image: res.data.image });
          setShowLogoPicker(false);
          setShowCropper(false);
          setUploadedLogo(null);
        }
      })
      .catch((err) => {
        toast.error(err);
      }).finally(() => setDisableDropzone(false));
  };

  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );

  };


  const onDropRejected = (fileRejections) => {
    let obj = {};
    fileRejections.forEach((fileRejection) => {
      const { errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          obj.image = "Only images are allowed";
        } else if (error.code === "file-too-large") {
          obj.image = "File size should be less than 5 MB";
        }
      });
    });
    setErrors(obj);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disableDropzone
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddAccuracyData({
      ...addAccuracyData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const checkValidation = () => {
    let flag = false;
    const obj = {};
    if (addAccuracyData.accuracy === "") {
      obj.accuracy = "Please enter the accuracy!";
      flag = true;
    }
    if (addAccuracyData.days === "") {
      obj.days = "Please enter the days!";
      flag = true;
    }
    if (addAccuracyData.image === "") {
      obj.image = "Please select the image!";
      flag = true;
    }
    if (addAccuracyData.stockName === "") {
      obj.stockName = "Please enter the stock name!";
      flag = true;
    }
    setErrors(obj);
    return flag;
  };

  const getAccuracyData = () => {
    setLoading(true);
    ApiGet(`admin/getAccuracy?limit=${itemsPerPage}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          let isError = ErrorHandler(res);
          if (isError) {
            setAccuracyData(res.data.pastData);
            setAccuracyHeader(res.data.accuracyHeader);
            setHeaderId(res.data._id);
          }
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => setLoading(false));
  };
  const onDeleteClick = (data) => {
    setConfirmationModal(true);
    setId(data._id);
  };

  const addAccuracyHandler = () => {
    if (checkValidation()) {
      return;
    }
    setLoading(true);
    const payload = {
      accuracyHeader: accuracyHeader,
      pastData: [
        {
          image: addAccuracyData.image,
          priceUpBy: Number(addAccuracyData.accuracy),
          name: addAccuracyData.stockName,
          days: addAccuracyData.days,
        },
      ],
    };
    ApiPost(`admin/addAccuracy`, payload)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          getAccuracyData();
          toast.success(res.message)
          clearState();
        }
      })
      .catch((err) => {
        toast.error(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearState = () => {
    setOpen(false);
    setAddAccuracyData({
      stockName: "",
      image: "",
      days: "",
      accuracy: "",
    });
    setErrors({});
    setIsEdit(false)
    setConfirmationModal(false);
  };

  const handleEdit = (info) => {
    setIsEdit(true);
    setOpen(true);
    setAddAccuracyData({
      stockName: info.name,
      accuracy: info.priceUpBy,
      image: info.image,
      days: info.days,
    });
    setId(info._id);
  };

  const editDataHandler = () => {
    if (checkValidation()) {
      return;
    }
    setLoading(true);
    const payload = {
      _id: id,
      image: addAccuracyData.image,
      priceUpBy: addAccuracyData.accuracy,
      name: addAccuracyData.stockName,
      days: addAccuracyData.days,
    };
    ApiPost(`admin/updatePastData`, payload)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          toast.success(res.message);
          clearState();
          getAccuracyData();
        }
      })
      .catch((err) => {
        console.log("err ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    ApiDelete(`admin/deleteAccuracy/${id}`)
      .then((res) => {
        if (res.status == 200) {
          let checkError = ErrorHandler(res.data);
          if (checkError) {
            toast.success(res.data.message);
            getAccuracyData();
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
        clearState();
      });
  };


  const changeAccuracyHeader = () => {
    setButtonLoader(true);
    const payload = {
      _id: headerId,
      accuracyHeader: accuracyHeader,
    };
    ApiPost(`admin/updateAccuracyHeader`, payload)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          getAccuracyData();
          toast.success(res.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };
  const onChangeHandlerAccuracy = (e) => {
    const value = e.target.value;
    if (value === '') {
      setAccuracyHeader('');
      return;
    }
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue <= 100) {
      setAccuracyHeader(value);
    }
  }

  useEffect(() => {
    getAccuracyData();
  }, [page]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Past Accuracy
          </Typography>
          <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
            <Box display="flex" alignItems="center">
              <CommonTextField
                fontWeight={400}
                text={"Past Accuracy (%)"}
                placeholder={"Enter our Past Accuracy"}
                type="text"
                name="title"
                value={accuracyHeader}
                onChange={(e) => onChangeHandlerAccuracy(e)}
                inputProps={{ className: classes.placeholder }}
                endAdornment={
                  <InputAdornment position="end">
                    %
                  </InputAdornment>
                }

              />
              {errors.title && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.title}
                </Typography>
              )}
              <Button
                variant="contained"
                sx={{ marginLeft: "8px" ,mt:3.5 }}
                onClick={changeAccuracyHeader}
                disabled={buttonLoader}
              >
                Submit
              </Button>
            </Box>
            {/* </Grid> */}

            <Button
              variant="contained"
              sx={{ marginLeft: "8px", height: "100%" , mt:3.5 }}
              onClick={() => setOpen(true)}
            >
              Add Accuracy
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "53%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              {accuracyData?.length > 0 ? (
                accuracyData?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} mt={1}>
                    <Card>
                      <Box
                        sx={{
                          width: '100%',
                          height: 'auto',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={item.image}
                          alt="Full Image"
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: 'auto',
                            minHeight: '140px',
                            maxHeight: '140px'
                          }}
                        />
                      </Box>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="body1"
                          component="div"
                          className={classes.cardContent}
                        >
                          <span className={classes.nameText}>Accuracy:</span>
                          {`${item?.priceUpBy}%`}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body1"
                          component="div"
                          className={classes.cardContent}
                        >
                          <span className={classes.nameText}>Stock Name:</span>
                          {item.name}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body1"
                          component="div"
                          className={classes.cardContent}
                        >
                          <span className={classes.nameText}>Days:</span>
                          {item.days}
                        </Typography>
                      </CardContent>
                      <Box display={"flex"} justifyContent={"flex-end"}>
                        <CardActions>
                          <IconButton
                            color="primary"
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error">
                            <DeleteIcon onClick={() => onDeleteClick(item)} />
                          </IconButton>
                        </CardActions>
                      </Box>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid container m={5}>
                  <NoDataFound elevation={0} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>

      <ConfirmationModal
        open={confirmationModal}
        handleClose={() => setConfirmationModal(false)}
        onDeleteHandler={handleDelete}
        headTitle={"Are you sure you want to delete this stock accuracy ?"}
        loading={loading}
      />

      <Modal
        open={open}
        handleClose={onCloseHandler}
        title={isEdit ? "Edit Accuracy" : "Add Accuracy"}
        savetext={isEdit ? "Update" : "Save"}
        cancelText={"Cancel"}
        handleReject={onCloseHandler}
        handleApprove={isEdit ? editDataHandler : addAccuracyHandler}
        loading={loading}
        content={
          <>
            <Grid container spacing={2} px={1}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  Note :- Please select .png .jpg and .jpeg images
                </Typography>
              </Grid>
              <Grid
                item
                xs={addAccuracyData.image ? 9 : 12}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                <input {...getInputProps()} />
                <>
                  <Card
                    style={{
                      padding: "20px",
                      border: `1px dashed ${theme.palette.primary.main}`,
                      textAlign: "center",
                      boxShadow: "none",
                    }}
                  >
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <div style={{ color: theme.palette.primary.main }}>
                        <CloudUploadIcon fontSize="large" />
                        {
                          disableDropzone ? (<p>Image is uploading...</p>) : (<p> Drag 'n' drop some files here, or click to select
                            files</p>)
                        }
                      </div>
                    )}
                  </Card>
                  {errors.image && (
                    <Typography color="error" fontSize={"12px"} >
                      {errors.image}
                    </Typography>
                  )}
                </>
              </Grid>

              {showLogoPicker &&
                <CustomDialog
                  isOpen={showLogoPicker}
                  handleClose={() => {
                    setShowLogoPicker(false);
                    setShowCropper(false);
                    setUploadedLogo(null);
                  }}

                >
                  <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                  <DialogContent sx={{ width: { lg: "700px", md: "700px", sm: "600px", xs: "400px" }, }} dividers >
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ width: "100%", position: 'relative', }}>
                          <Cropper
                            style={{ height: 300, width: "100%" }}
                            src={uploadedLogo}
                            viewMode={0}
                            background={false}
                            responsive={false}
                            minCropBoxHeight={20}
                            minCropBoxWidth={10}
                            dragMode="move"
                            autoCropArea={1}
                            checkOrientation={false}
                            cropBoxResizable={true}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={false}
                          />

                          {/* <IconButton
                          component="span"
                          style={{
                            justifyContent: "center", alignSelf: "center", position: "absolute", top: 0, right: 0,
                          }}
                          onClick={() => {
                            setShowCropper(false);
                            setUploadedLogo(null);
                            setInitialLogo(null);
                            setShowLogoPicker(false)
                          }}
                        >
                          <DeleteIcon style={{ color: theme.palette.bgWhite.main, marginTop: 10 }} />
                        </IconButton> */}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"end"} gap={1}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setShowCropper(false);
                              setUploadedLogo(null);
                              setInitialLogo(null);
                              setShowLogoPicker(false)
                            }}
                            color="error"
                            sx={{ borderRadius: 5 }}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            color="primary"
                            variant="contained"
                            sx={{ borderRadius: 5 }}
                            disableElevation
                            onClickHandler={() => { getCropData(); }}
                            text="Crop"
                            loading={disableDropzone}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </CustomDialog>
              }
              {addAccuracyData.image && (
                <>
                  <Grid item xs={3}>
                    {/* <Card style={{ position: "relative" }}>
                      <img
                        src={addAccuracyData.image}
                        alt="Uploaded"
                        style={{
                          width: "100%",
                        }}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 8,
                          zIndex: 1,
                        }}
                        onClick={() => {
                          setAddAccuracyData({ ...addAccuracyData, image: "" });
                        }}
                      >
                        <CloseIcon
                          sx={{
                            background: "white",
                            color: "red",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Card> */}
                    <Box sx={{
                      position: "relative",
                      background: `url(${addAccuracyData.image})`,
                      height: "130px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: 'center',
                      borderRadius: "5px",
                      overflow: "hidden",
                      "&:hover .hoverButtons": {
                        opacity: 1,
                      },
                    }}>
                      <Box
                        className="hoverButtons"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          opacity: 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <Box  onClick={() => window.open(addAccuracyData.image, "_blank")} height={"25px"} width={"25px"} borderRadius={"50%"} bgcolor={theme.palette.bgWhite.main} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "42%", right: "42%", cursor: 'pointer' }}>
                          <VisibilityIcon sx={{ color: theme.palette.bgBlack.main, fontSize: "20px" }} />
                        </Box>
                        <Box
                         onClick={() => {
                          setAddAccuracyData({ ...addAccuracyData, image: "" });
                        }} height={"25px"} width={"25px"} borderRadius={"50%"} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "10px", right: "10px", cursor: 'pointer' }}>
                          <DeleteIcon sx={{ color: theme.palette.error.main, fontSize: "20px" }} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <CommonTextField
                  fontWeight={400}
                  text={"Accuracy"}
                  placeholder={"Enter Accuracy"}
                  type="number"
                  name="accuracy"
                  value={addAccuracyData.accuracy}
                  onChange={onChangeHandler}
                  inputProps={{ className: classes.placeholder }}
                />
                {errors.accuracy && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.accuracy}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <CommonTextField
                  fontWeight={400}
                  text={"Stock Name"}
                  placeholder={"Enter Stock Name"}
                  type="text"
                  name="stockName"
                  value={addAccuracyData.stockName}
                  onChange={onChangeHandler}
                  inputProps={{ className: classes.placeholder }}
                />
                {errors.stockName && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.stockName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} mb={2}>
                <CommonTextField
                  fontWeight={400}
                  text={"Days"}
                  placeholder={"Enter Days"}
                  type="number"
                  name="days"
                  value={addAccuracyData.days}
                  onChange={onChangeHandler}
                  inputProps={{ className: classes.placeholder }}
                />
                {errors.days && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.days}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default Accuracy;
