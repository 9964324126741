import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box, Table, TableBody, TableContainer, Tab, TableHead, TableRow,
  Paper,
  Typography,
  TablePagination,
  TextField,
  InputLabel,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import Modal from "../../common/Modal";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useDispatch } from "react-redux";
import {
  fetchAllFeedback,
  handleApproveFeedback,
} from "../../redux/feedback/feedbackActions";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/Loader";
import LoadingButton from "../../common/LoadingButton";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import ConfirmationModal from "../../common/ConfirmationModal";
import moment from "moment";
import ViewModal from "../../common/ViewModal";
import ReadMore from "../../common/ReadMore";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  tab_main: {
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modal_text_field: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  truncatedText: {
    maxWidth: "200px",
    flex: 1,
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  image : {
    maxHeight : '75px',
    maxWidth : '75px',
    borderRadius : '3px !important'
  }
}));

const Feedback = () => {
  const theme = useTheme()
  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("0");
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelContent, setModalContent] = useState({
    image: null,
    content: "",
    description: "",
  });
  const [selectId, setSelectId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectDescription, setRejectDescription] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [viewModal , setViewModal] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue, name) => {
    setValue(newValue);
    setPage(0);
    setRowsPerPage(10)
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleClear = () => {
    setRejectModal(false);
    setApproveModal(false);
    setIsModal(false);
    setLoading(false);
    setRejectDescription("");
    setErrors({});
  }

  const fetchUserFeedback = () => {
    setLoading(true);
    dispatch(fetchAllFeedback(value, page + 1, rowsPerPage))
      .then((res) => {
        const error = ErrorHandler(res)
        if (error) {
          setTotalCount(res.data.totalCount);
          setData(res.data.getFeedback);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      }).finally(() => setLoading(false));
  };

  const openModeHandler = (item) => {
    setModalContent({
      ...modelContent,
      image: item?.image,
      content: item?.content,
      description: item?.description,
    });
    setIsModal(true);
    setSelectId(item._id);
  };

  const validation = () => {
    let flag = false;
    let obj = {};
    if (rejectDescription.trim() === "") {
      obj.description = "Please enter reason for reject!";
      flag = true;
    }

    setErrors(obj);
    return flag;
  };

  const handleApproveReject = (status, id) => {
    if (status === 2 && validation()) {
      return;
    }
    const body = { id: id, status: status };
    if (status === 2) {
      body.description = rejectDescription;
    }
    setLoading(true);
    dispatch(handleApproveFeedback(body))
      .then((res) => {
        const error = ErrorHandler(res)
        if (error) {
          toast.success(res.message || "Success");
          handleClear();
          fetchUserFeedback();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      }).finally(() => setLoading(false));
  };

  const viewModalHandler = (item)=>{
    setModalContent({
      ...modelContent,
      image: item?.image,
      content: item?.content,
      description: item?.description,
    });
    setViewModal(true)
    setSelectId(item._id);
  }

  const viewModalCloseHandler = ()=>{
    setModalContent({
      image: null,
      content: "",
      description: "",
    })
    setViewModal(false)
    setSelectId(null)
  }

  useEffect(() => {
    fetchUserFeedback();
  }, [page, rowsPerPage, value]);
  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography variant="h6" sx={{ fontWeight: "600", color: theme.palette.primary.main }}>
            Feedbacks
          </Typography>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className={classes.tab_main}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab sx={{ fontWeight: "600" }} label="Pending" value="0" />
                <Tab sx={{ fontWeight: "600" }} label="Approved" value="1" />
                <Tab sx={{ fontWeight: "600" }} label="Rejected" value="2" />
              </TabList>
            </Box>
            <TabPanel value="0" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell align="center">Image</StyledTableCell>
                      <StyledTableCell align="center">User Name</StyledTableCell>
                      <StyledTableCell align="center">Content</StyledTableCell>
                      <StyledTableCell align="center">Date</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={10}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data?.length > 0 ? data?.map((item, index) => (
                          <StyledTableRow key={item?._id}>
                            {console.log("item" , item)}
                            <StyledTableCell align="center">
                              {(page * rowsPerPage) + index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>
                            {
                                item?.image ? ( <img src={item?.image} className={classes.image}/>) :(<Typography>-</Typography>)
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>
                              {item?.userDetail?.name ?? "-"}
                            </StyledTableCell>
                            <StyledTableCell align="left" className={classes.truncatedText}>
                              {
                                item?.content ? (
                                  <ReadMore
                               content= {item?.content}
                               maxLength = {80}/>
                                ) : (<Typography>-</Typography>)
                              }
                               
                            </StyledTableCell>
                            <StyledTableCell align="center" >
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>
                                  {item?.createdAt && moment(item?.createdAt).format('DD/MM/YYYY')}
                                </Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center"  >
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>  {item?.createdAt && moment(item.createdAt).format('hh:mm A')}</Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                <RemoveRedEyeIcon
                                  onClick={() => viewModalHandler(item)}
                                  sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
                                />
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => { setSelectId(item._id); setRejectModal(true); }}
                                  // autoFocus
                                  disabled={loading}
                                  size="small"
                                >
                                  Reject
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => { setSelectId(item._id); setApproveModal(true); }}
                                  size="small"
                                  endIcon={loading && <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      marginTop: -12,
                                      marginLeft: -12,
                                      color: "#F1F4F8",
                                      strokeWidth: 2,
                                      display: 'inline-block'
                                    }}
                                  />}
                                  color={"success"}
                                  disableElevation
                                >
                                  Approve
                                </Button>
                              </Box>

                            </StyledTableCell>
                          </StyledTableRow>
                        )) : <StyledTableRow>
                          <StyledTableCell colSpan={12}>
                            <NoDataFound elevation={0} />
                          </StyledTableCell>
                        </StyledTableRow >
                        }
                      </>
                    )}
                    {totalCount > 0 && <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value="1" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell align="center">Image</StyledTableCell>
                      <StyledTableCell align="center">User Name</StyledTableCell>
                      <StyledTableCell align="center">Content</StyledTableCell>
                      <StyledTableCell align="center">Date</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={10}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data?.length > 0 ? data?.map((item, index) => (
                          <StyledTableRow key={item._id}>
                            <StyledTableCell align="center">{(page * rowsPerPage) + index + 1}</StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>
                            {
                                item?.image ? ( <img src={item?.image} className={classes.image}/>) :(<Typography>-</Typography>)
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>{item?.userDetail.name}</StyledTableCell>
                            <StyledTableCell align="left" className={classes.truncatedText}>
                            {
                                item?.content ? (
                                  <ReadMore
                               content= {item?.content}
                               maxLength = {80}/>
                                ) : (<Typography>-</Typography>)
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>  {item?.createdAt && moment(item?.createdAt).format('DD/MM/YYYY')}</Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center"  >
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>{item?.createdAt && moment(item.createdAt).format('hh:mm A')}</Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                <RemoveRedEyeIcon
                                  onClick={() =>  viewModalHandler(item)}
                                  sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
                                />
                                <ShortcutIcon
                                  onClick={async () => { setSelectId(item._id); setRejectModal(true); }}
                                  sx={{ color: theme.palette.error.main, cursor: "pointer" }}
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        )) : <StyledTableRow>
                          <StyledTableCell colSpan={12}>
                            <NoDataFound elevation={0} />
                          </StyledTableCell>
                        </StyledTableRow >}
                      </>
                    )}
                    {totalCount > 0 && <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value="2" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell align="center">Image</StyledTableCell>
                      <StyledTableCell align="center">User Name</StyledTableCell>
                      <StyledTableCell align="center">Reason for rejection</StyledTableCell>
                      <StyledTableCell align="center">Date</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data?.length > 0 ? data?.map((item, index) => (
                          <StyledTableRow key={item._id}>
                            <StyledTableCell align="left">{(page * rowsPerPage) + index + 1}</StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>
                              {
                                item?.image ? ( <img src={item?.image} className={classes.image}/>) :(<Typography>-</Typography>)
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center" className={classes.truncatedText}>{item?.userDetail?.name}</StyledTableCell>
                            <StyledTableCell align="left" className={classes.truncatedText}>
                              {item?.description ? (
                                 <ReadMore
                                 content= {item?.description}
                                 maxLength = {80}/>
                              ):(<Typography>-</Typography>)}
                              
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>  {item?.createdAt && moment(item?.createdAt).format('DD/MM/YYYY')}</Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center"  >
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Typography fontSize={'14px'} textAlign={'center'} noWrap>{item?.createdAt && moment(item.createdAt).format('hh:mm A')}</Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                                <RemoveRedEyeIcon
                                  onClick={() =>  viewModalHandler(item)}
                                  sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
                                />
                              </Box>

                            </StyledTableCell>
                          </StyledTableRow>
                        )) : <StyledTableRow>
                          <StyledTableCell colSpan={12}>
                            <NoDataFound elevation={0} />
                          </StyledTableCell>
                        </StyledTableRow >}
                      </>
                    )}

                    {totalCount > 0 && <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>

        {/* {isModal ? (
          <Modal
            open={isModal}
            handleClose={() => setIsModal(false)}
            // handleReject={handleRejectButton}
            title={"View Feedback"}
            // handleApprove={handleApprove}
            loading={loading}
            // maxWidth={'md'}
            content={
              <>
                {modelContent.image && (
                  <Box display="flex" justifyContent="center" my={2}>
                    <img src={modelContent.image} alt="feedback" style={{ height: "450px", width: "450px" }} />
                  </Box>
                )}
                <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography fontWeight={700} mx={2} >Feedback Details :</Typography>
                    <Typography >{modelContent.content}</Typography>
                  </Box>
                  {value === "2" && (
                    <Box mt={2} display={'flex'} flexDirection={'row'}>
                      <Typography fontWeight={700} mx={2}>Rejection Reason:</Typography>
                      <Typography >{modelContent.description}</Typography>
                    </Box>
                  )}
                </Box>
              </>
            }
          />
        ) : null} */}
       <ViewModal
       open={viewModal}
       title={"View Feedback"}
       buttonText={"Close"}
       content={
        <>
        {modelContent.image && (
          <Box display="flex" justifyContent="center" my={2}>
            <img src={modelContent.image} alt="feedback" style={{ height: "450px", width: "450px" }} />
          </Box>
        )}
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography fontWeight={700} mx={2} >Feedback Details :</Typography>
            <Typography >{modelContent.content}</Typography>
          </Box>
          {value === "2" && (
            <Box mt={2} display={'flex'} flexDirection={'row'}>
              <Typography fontWeight={700} mx={2}>Rejection Reason:</Typography>
              <Typography >{modelContent.description}</Typography>
            </Box>
          )}
        </Box>
      </>
       }
       handleClose={viewModalCloseHandler}
       />
      </Box>
      {rejectModal ? (
        <Modal
          open={rejectModal}
          handleClose={handleClear}
          handleReject={handleClear}
          title={"Feedback rejection"}
          handleApprove={() => handleApproveReject(2, selectId)}
          loading={loading}
          content={
            <>
              <Grid container spacing={1} xs={12} px={1} mb={3}>
                <Grid item xs={12}>
                  <InputLabel
                    sx={{ color: theme?.palette?.bgBlack?.main, fontSize: "18px", fontWeight: 500 }}
                    shrink
                    htmlFor="reason"
                  >
                    Reason for Reject
                  </InputLabel>
                  <TextField
                    id="reason"
                    name="reason"
                    onChange={(e) => {
                      setRejectDescription(e.target.value)
                      setErrors({})
                    }}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    className={classes.modal_text_field}
                  />
                </Grid>
              </Grid>
            </>
          }
          savetext={"Submit"}
          cancelText={"Close"}
        />
      ) : null}

      {approveModal ? (
        <ConfirmationModal
          open={approveModal}
          handleClose={() => setApproveModal(false)}
          headTitle={"Are you sure you want to Approve this Feedback?"}
          onDeleteHandler={() => handleApproveReject(1, selectId)}
          loading={loading}
          maxWidth="md"
        />
      ) : null}
    </>
  );
};

export default Feedback;
