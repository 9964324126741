import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { ApiPost } from "./helper/API/ApiData";
import ErrorHandler from "./helper/errorHandler";


const updateAdminDeviceToken = async (token) => {
    const body = {
        FTPToken: token
    }
    try {
        const res = await ApiPost(`admin/updateAdminDeviceToken`, body);
        const error = ErrorHandler(res);
        if (error) {
            console.log('Error updating token:', error);
        }
    } catch (error) {
        console.log('Error updating token:', error);
    }
};

const firebaseConfig = {
    apiKey: "AIzaSyCwKQUWaQpj5Pi6cNIvTqNkUH1U77KGenI",
    authDomain: "shahs-investments.firebaseapp.com",
    projectId: "shahs-investments",
    storageBucket: "shahs-investments.appspot.com",
    messagingSenderId: "916649762442",
    appId: "1:916649762442:web:65e65ed4b7d88e8d285773",
    measurementId: "G-1LF8LQP2R4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// export const genrateToken = async (user) => {
//     const permission = await Notification.requestPermission()
//     console.log('permission', permission)
//     if (permission === 'granted') {
//         const token = await getToken(messaging, {
//             vapidKey: "BJi-KBcv8Sw7yaxHVwBnMK29CLnPf4OLE0ZCfrXq-cH53A9LngBZ9c3n0SMPedXP4hIs2q-8HQoMaSgU4VPVsk0"
//         })
        
//         await updateAdminDeviceToken(permission, token)
//     }
// }

export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log('Notification permission:', permission);
        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey: "BJi-KBcv8Sw7yaxHVwBnMK29CLnPf4OLE0ZCfrXq-cH53A9LngBZ9c3n0SMPedXP4hIs2q-8HQoMaSgU4VPVsk0"
            });
            if (token) {
                await updateAdminDeviceToken(token);
                return token;
            } else {
                console.log('Failed to generate FCM token');
            }
        } else {
            console.log('Notification permission not granted');
        }
    } catch (error) {
        console.log('Error generating FCM token:', error);
    }
};