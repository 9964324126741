import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LoadingButton from "./LoadingButton";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme) => ({
  outlined__button: {
    color: theme.palette.primary.main,
    border: theme.palette.primary.main,
  },
  contained__button: {
    background: theme.palette.primary.main,
  },
}));

function ConfirmationModal({
  open,
  handleClose,
  onDeleteHandler,
  headTitle,
  contentText,
  loading,
}) {
  const classes = useStyles();
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 2,
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              right: 10,
              zIndex: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon
              color="error"
              sx={{
                background: "white",
                borderRadius: "50%",
              }}
            />
          </IconButton>
          <Box
            display={"flex"}
            flexDirection={"column"}
          >
            <Box display={'flex'} flexDirection={'column'} px={2} mt={4}>
              <Typography id="modal-modal-title" variant="h5" textAlign={'center'} fontWeight={700}>
                Are You Sure?
              </Typography>
              <Typography id="modal-modal-title" variant="h6" textAlign={'center'}>
                {headTitle}
              </Typography>
            </Box>
            <Typography id="modal-modal-description">
              {contentText}
            </Typography>
            <Box display={"flex"} justifyContent={'center'} gap={3} my={2}>
              <Button variant="outlined" onClick={handleClose} color="error" sx={{ px: 5 }}>
                No
              </Button>
              <LoadingButton
                variant={"contained"}
                onClickHandler={onDeleteHandler}
                loading={loading}
                text="Yes"
                sx={{ px: 7, boxShadow: 'none' }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
