import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Tab,
  Tabs,
  DialogContent,
  DialogTitle,
  debounce,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import Loader from "../../common/Loader";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ApiDelete, ApiGet, ApiPost } from "../../helper/API/ApiData";
import { useDropzone } from "react-dropzone";
import Modal from "../../common/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CommonTextField from "../../common/commonTextField";
import ConfirmationModal from "../../common/ConfirmationModal";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import Pagination from "@mui/material/Pagination";
import TableView from "./TableView";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-cropper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../../common/LoadingButton";
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const TabPanel = (props) => {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const MutualFunds = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [isEmpty, setIsEmpty] = useState("");
  const [isLarge, setIsLarge] = useState("");
  const [isWrong, setIsWrong] = useState("");
  const [data, setData] = useState({
    name : "",
    description : "",
    link : "",
    image : "",
    fundHouse : "",
    schemeType : "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [error, setError] = useState({
    name : "",
    description : "",
    link : "",
    image : "",
    fundHouse : "",
    schemeType : "",
  });
  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); 
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("");
  const [croppedLogo, setCroppedLogo] = useState(null);
  const [totalFunds, setTotalFunds] = useState(0); 
  const [mutualFundData , setMutualFundData] = useState()

  const itemsPerPage = 8;

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const fetchMutulaFunds = async (searchValue) => {
    setLoading(true);
    let url = 'https://api.mfapi.in/mf'
    if(searchValue){
      url = `https://api.mfapi.in/mf/search?q=${searchValue}`
    }
    try {
      const res = await axios.get(url);
      let checkError = ErrorHandler(res);
      console.log("chek error" , checkError , res)
      if (checkError) {
        console.log("if")
        setMutualFundData(res.data);
        setTotalFunds(res.data.length);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    setData({
      name : "",
      description : "",
      link : "",
      image : "",
      fundHouse : "",
      schemeType : "",
      
    });
    setError({});
  };

  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true);
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name);
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };

  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();

    setIsWrong("");
    setIsEmpty("");
    setIsLarge("");
    setShowCropper(false);
    setUploadedLogo(null);

    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisableDropzone(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setData((prev) => ({ ...prev , image : res.data.image }));
        setDisableDropzone(false);
        setError({
          ...error,
          image: "",
        });
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setShowLogoPicker(false));
  };

  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setIsWrong(`Only images are allowed`);
        } else if (error.code === "file-too-large") {
          setIsLarge(`File size should be less than 5 MB`);
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disableDropzone,
  });

  const validation = () => {
    let flag = false;
    let obj = {};
    if (!data.name) {
      obj.name = "Enter the mutual fund name !";
      flag = true;
    }
    if(!data.link.startsWith('http')){
      obj.link = "Please enter the correct link !"
      flag = true;
    }
    if(data.link.length === 0 && !data.link.startsWith('http')){
      obj.link = "Please enter the link !"
      flag = true;

    }
   
    setError(obj);
    return flag;
  };

  const getMutualFundData = () => {
    setLoading(true);
    ApiGet(`admin/getMutualFunds?limit=${itemsPerPage}&page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          let isError = ErrorHandler(res);
          if (isError) {
            setNewsData(res.data.response);
            setTotalDataCount(res.data.blogCount);
          }
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => setLoading(false));
  };

  const closeModals = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const onAddHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      name: data.name,
      link: data.link,
    };
    if(data.image){
      payload.image = data.image
    }
    if(data.description){
      payload.description = data.description
    }
    if(data.fundHouse){
      payload.fund_house = data.fundHouse
    }
    if(data.schemeType){
      payload.scheme_type = data.schemeType
    }

    ApiPost(`admin/mutualFunds`, payload)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            toast.success(res.message || "Success");
            getMutualFundData();
            setData({  name : "",
              description : "",
              link : "",
              image : "",
              fundHouse : "",
              schemeType : "", });
            closeModals();
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onEditHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      _id: id,
      name: data.name,
      link : data.link
    };
    if(data.image){
      payload.image = data.image
    }
    if(data.description){
      payload.description = data.description
    }
    if(data.fundHouse){
      payload.fund_house = data.fundHouse
    }
    if(data.schemeType){
      payload.scheme_type = data.schemeType
    }
    ApiPost(`admin/updateMutualFunds`, payload)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            toast.success(res.message);
            setData({ image : "", link: "", title: "", description: "" });
            getMutualFundData();
            closeModals();
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };

  const handleEdit = (data) => {
    console.log("data to edit ",data)
    setIsEdit(true);
    setOpen(true);
    setId(data._id);
    setData({
      image: data.image || "",
      name: data.name,
      description: data.description,
      link: data.link || "",
      fundHouse :data.fund_house || "",
      schemeType: data.scheme_type || ""
    });
  };

  const checkApiCall = () => {
    let checkPage = Math.ceil((totalDataCount - 1) / itemsPerPage);
    if (checkPage !== page && page !== 1) {
      setPage(checkPage);
    } else {
      getMutualFundData();
    }
  };

  const onDeleteHandler = () => {
    ApiDelete(`admin//deleteMutualFunds/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          let isError = ErrorHandler(res.data);
          if (isError) {
            toast.success(res.data.message);
            setConfirmationModal(false);
            checkApiCall();
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (searchValue)=>{
     fetchMutulaFunds(searchValue)
  }
  const debounceSearch = debounce(handleSearch , 500)

  const fetchDataFromMarket = async(data)=>{
    try{
      const res = await axios.get(`https://api.mfapi.in/mf/${data.schemeCode}/latest`)
      let checkError = ErrorHandler(res);
      if(checkError){
        const info = res.data.meta
        setData({
          name : info.scheme_name,
          fundHouse : info.fund_house,
          schemeType : info.scheme_type
        })
        setOpen(true)
      }
    }catch(err){
      toast.error(err.message)
    }
  }

  const addMutualFundFromTheMarket = (data)=>{
      fetchDataFromMarket(data);
  }

  useEffect(() => {
    if (tabIndex === 0) {
      getMutualFundData();
    }else if(tabIndex == '1'){
      fetchMutulaFunds();
    }
  }, [tabIndex, page ]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Box
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: theme.palette.primary.main }}
            >
              Best Equity Mutual Funds |Mutual Fund Ranking
            </Typography>
            <Box display={'flex'} gap={2}>
              {
                tabIndex == '1' && 
                <CommonTextField
              fontWeight={600}
              placeholder={"Search Mutual Funds..."}
              type="text"
              name="search"
              onChange={(e) => debounceSearch(e.target.value)}
              width={250}
              inputProps={{ className: classes.placeholder }}
            />
              }
            
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add Mutual Funds
            </Button>

            </Box>
          </Box>
        </Box>

        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
            <Tab label="Mutual Funds" />
            <Tab label="Market Mutual Funds" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={3} px={1} mt={1}>
              {loading ? (
                <Box
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "53%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  {newsData?.length > 0 ? (
                    newsData?.map((item) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                        <Card sx={{ maxWidth: 345 }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.image || '/no-image.svg'}
                              alt="Full Image"
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "auto",
                                minHeight: "140px",
                                maxHeight: "140px",
                              }}
                            />
                          </Box>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className={classes.cardContent}
                            >
                              {item?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className={classes.cardContent}
                            >
                              {item?.description ?? "No description found !!"}
                            </Typography>
                          </CardContent>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <CardActions>
                              <Button
                                onClick={() =>
                                  navigate(`/mutualFunds/view/${item._id}`)
                                }
                              >
                                View More
                              </Button>
                            </CardActions>

                            <CardActions className={classes.cardActions}>
                              <IconButton color="primary">
                                <EditIcon onClick={() => handleEdit(item)} />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setId(item._id);
                                  setConfirmationModal(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          </Box>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid container m={5}>
                      <NoDataFound elevation={0} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            {newsData?.length > 0 && totalDataCount > itemsPerPage && (
              <Box
                mt={"2rem"}
                width={"100%"}
                display={"block"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Pagination
                  count={Math.ceil(totalDataCount / itemsPerPage)}
                  onChange={onChangeHandler}
                  page={page}
                />
              </Box>
            )}
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <TableView setData={setData} setOpen={setOpen} mutualFundData={mutualFundData} totalFunds={totalFunds} loading={loading} setLoading={setLoading} addMutualFundFromTheMarket={addMutualFundFromTheMarket}/>
          </TabPanel>
        </Box>
        <Modal
          open={open || isEdit}
          handleClose={onCloseHandler}
          title={isEdit ? "Edit Mutual Funds" : "Add Mutual Funds"}
          savetext={isEdit ? "Update" : "Save"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={isEdit ? onEditHandler : onAddHandler}
          loading={loading}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "12px", color: "red"}}>
                    Note :- Please select .png .jpg and .jpeg images
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={data.image ? 9 : 12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  <>
                    <Card
                      style={{
                        padding: "20px",
                        border: `1px dashed ${theme.palette.primary.main}`,
                        textAlign: "center",
                        boxShadow: "none",
                      }}
                    >
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div style={{ color: theme.palette.primary.main }}>
                          <CloudUploadIcon fontSize="large" />
                          {disableDropzone ? (
                            <p>Image is uploading...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                        </div>
                      )}
                    </Card>
                    {isEmpty && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isEmpty}
                      </Typography>
                    )}
                    {isWrong && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isWrong}
                      </Typography>
                    )}
                    {isLarge && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isLarge}
                      </Typography>
                    )}
                    {error.image && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {error.image}
                      </Typography>
                    )}
                  </>
                </Grid>
                {showLogoPicker && (
                  <CustomDialog
                    isOpen={showLogoPicker}
                    handleClose={() => {
                      setShowLogoPicker(false);
                      setShowCropper(false);
                      setUploadedLogo(null);
                    }}
                  >
                    <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                    <DialogContent
                      sx={{
                        width: {
                          lg: "700px",
                          md: "700px",
                          sm: "600px",
                          xs: "400px",
                        },
                      }}
                      dividers
                    >
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <div style={{ width: "100%", position: "relative" }}>
                            <Cropper
                              style={{ height: 300, width: "100%" }}
                              src={uploadedLogo}
                              viewMode={0}
                              background={false}
                              responsive={false}
                              minCropBoxHeight={20}
                              minCropBoxWidth={10}
                              dragMode="move"
                              autoCropArea={1}
                              checkOrientation={false}
                              cropBoxResizable={true}
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={false}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"end"} gap={1}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setShowCropper(false);
                                setUploadedLogo(null);
                                setInitialLogo(null);
                                setShowLogoPicker(false);
                              }}
                              color="error"
                              sx={{ borderRadius: 5 }}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              color="primary"
                              variant="contained"
                              sx={{ borderRadius: 5 }}
                              disableElevation
                              onClickHandler={() => {
                                getCropData();
                              }}
                              loading={disableDropzone}
                              text="Crop"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </CustomDialog>
                )}
                {data.image && (
                  <>
                    <Grid item xs={3} borderRadius={"5px"}>
                      <Box
                        sx={{
                          position: "relative",
                          background: `url(${data.image})`,
                          height: "130px",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          overflow: "hidden",
                          "&:hover .hoverButtons": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          className="hoverButtons"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Box
                            onClick={() =>{window.open(data.image, "_blank")}}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            bgcolor={theme.palette.bgWhite.main}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "42%",
                              right: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: theme.palette.bgBlack.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              setData({
                                ...data,
                                image: ""
                              });
                            }}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: theme.palette.error.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Mutual Fund Name"}
                    placeholder={"Enter the mutual name"}
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      setError({
                        ...error,
                        name: "",
                      });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />

                  {error.name && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Mutual Funds Link"}
                    placeholder={"Enter the mutual funds link"}
                    type="text"
                    name="link"
                    value={data.link}
                    onChange={(e) => {
                      setData({ ...data, link: e.target.value });
                      setError({ ...error, link: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.link && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.link}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} >
                  <CommonTextField
                    fontWeight={400}
                    text={"Fund House"}
                    placeholder={"Enter fund house name"}
                    type="text"
                    name="fundHouse"
                    value={data.fundHouse}
                    onChange={(e) => {
                      setData({ ...data, fundHouse: e.target.value });
                      setError({ ...error, fundHouse: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.fundHouse && (
                    <Typography color="error" fontSize={"12px"} mt={2}>
                      {error.fundHouse}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} >
                  <CommonTextField
                    fontWeight={400}
                    text={"Scheme Type"}
                    placeholder={"Enter scheme type"}
                    type="text"
                    name="schemeType"
                    value={data.schemeType}
                  
                    onChange={(e) => {
                      setData({ ...data, schemeType: e.target.value });
                      setError({ ...error, schemeType: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.schemeType && (
                    <Typography color="error" fontSize={"12px"} mt={2}>
                      {error.schemeType}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} mb={2}>
                <CommonTextField
                    fontWeight={400}
                    text={"Description"}
                    placeholder={"Enter the mutual description"}
                    type="text"
                    name="description"
                    value={data.description}
                    multiline
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      setError({
                        ...error,
                        description: "",
                      });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />

                  {error.description && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.description}
                    </Typography>
                  )}
                </Grid>

            </Grid>
            </>
          }
        />

        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={onDeleteHandler}
          headTitle={"Are you sure you want to delete this mutualFunds?"}
        />
      </Box>
    </>
  );
};

export default MutualFunds;
