import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Tab,
  Tabs,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import Loader from "../../common/Loader";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ApiDelete, ApiGet, ApiPost } from "../../helper/API/ApiData";
import { useDropzone } from "react-dropzone";
import Modal from "../../common/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import CommonTextField from "../../common/commonTextField";
import ConfirmationModal from "../../common/ConfirmationModal";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import Pagination from "@mui/material/Pagination";
import TableView from "./TableView";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-cropper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../../common/LoadingButton";
import { newsFilterTopicsEnum } from "../../helper/enum";
import SelectDropDown from "../../common/commonSelectDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import MultiSelectDropdown from "../../common/CommonMultiSelectDropDown";
import CommonMultiSelectDropDown from "../../common/CommonMultiSelectDropDown";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const TabPanel = (props) => {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};


const News = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [isEmpty, setIsEmpty] = useState("");
  const [isLarge, setIsLarge] = useState("");
  const [isWrong, setIsWrong] = useState("");
  const [data, setData] = useState({
    image: "",
    imgLink: "",
    title: "",
    description: "",
    newsLink: "",
    category: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [error, setError] = useState({
    image: null,
    imgLink: "",
    title: "",
    description: "",
    newsLink: "",
    category: "",
  });
  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State to manage tab selection
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("");
  const [croppedLogo, setCroppedLogo] = useState(null);
  const [topic, setTopic] = useState("");
  const [limit, setLimit] = useState("50");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [values, setValues] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [prefiiledValues, setPrefilledValues] = useState([]);

  const itemsPerPage = 8;

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    setData({
      image: null,
      imgLink: "",
      title: "",
      description: "",
      newsLink: "",
      category: "",
    });
    setError({});
    setValues([]);
    setPrefilledValues([])
  };

  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true);
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name);
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };

  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();

    setIsWrong("");
    setIsEmpty("");
    setIsLarge("");
    setShowCropper(false);
    setUploadedLogo(null);

    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisableDropzone(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setData((prev) => ({ ...prev, imgLink: res?.data?.image }));
        setDisableDropzone(false);
        setError({
          ...error,
          image: "",
        });
        const reader = new FileReader();
        reader.onload = () => {
          setData((prev) => ({ ...prev, image: reader.result }));
        };
        reader.readAsDataURL(file);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setShowLogoPicker(false));
  };

  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setIsWrong(`Only images are allowed`);
        } else if (error.code === "file-too-large") {
          setIsLarge(`File size should be less than 5 MB`);
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disableDropzone,
  });

  const validation = () => {
    let flag = false;
    let obj = {};
    if (data.title === "") {
      obj.title = "Please enter the title!";
      flag = true;
    }
    if (data.description === "") {
      obj.description = "Please enter the description!";
      flag = true;
    }
    if (!data.imgLink) {
      obj.image = "Please select the image!";
      flag = true;
    }
    // if (data.newsLink === "") {
    //   obj.newsLink = "Please enter the news link!";
    //   flag = true;
    // }
    if (values.length === 0) {
      obj.category = "Please select the category!";
      flag = true;
    }
    setError(obj);
    return flag;
  };

  const getNewsData = () => {
    setLoading(true);
    let url = `admin/getblog?limit=${itemsPerPage}&page=${page}`;
    if (topic) {
      const filterObj = filterData.find((item) => item.sector == topic);
      url = `admin/getblog?limit=${itemsPerPage}&page=${page}&blogSector=${filterObj._id}`;
    }
    ApiGet(url)
      .then((res) => {
        if (res.status === 200) {
          let isError = ErrorHandler(res);
          if (isError) {
            setNewsData(res.data.response);
            setTotalDataCount(res.data.blogCount);
          }
        }
      })
      .catch((err) => {
        console.log("error ", err);
      })
      .finally(() => setLoading(false));
  };

  const closeModals = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const onAddHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);

    const payload = {
      title: data.title,
      description: data.description,
      image: data.imgLink,
      // blogLink: data.newsLink,
      blogSector: values.map((item) => item._id),
    };
    if(data.newsLink){
      payload.blogLink = data.newsLink
    }
    console.log("body", payload);

    ApiPost(`admin/addblog`, payload)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            toast.success(res.message || "Success");
            setData({ image: null, imgLink: "", title: "", description: "" });
            getNewsData();
            closeModals();
            setValues([]);
            setPrefilledValues([])
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onEditHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      _id: id,
      title: data.title,
      description: data.description,
      image: data.imgLink,
      // blogLink: data.newsLink,
      blogSector: values.map((item) => item._id),
    };
    if(data.newsLink){
      payload.blogLink = data.newsLink
    }
    ApiPost(`admin/updateBlog`, payload)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            toast.success(res.message);
            setData({ image: null, imgLink: "", title: "", description: "" });
            getNewsData();
            closeModals();
            setPrefilledValues([])
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setId(data._id);
    setData({
      image: data.image || "",
      imgLink: data.image || "",
      title: data.title,
      description: data.description,
      newsLink: data.blogLink || "",
    });
    setPrefilledValues([...data.blogSectorDetails])
  };

  const checkApiCall = () => {
    let checkPage = Math.ceil((totalDataCount - 1) / itemsPerPage);
    if (checkPage !== page && page !== 1) {
      setPage(checkPage);
    } else {
      getNewsData();
    }
  };

  const onDeleteHandler = () => {
    ApiDelete(`admin/deleteBlog/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          let isError = ErrorHandler(res.data);
          if (isError) {
            toast.success(res.data.message);
            setConfirmationModal(false);
            checkApiCall();
          }
        }
      })
      .catch((err) => {
        toast.error(err.message)
      });
  };

  const onChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const handleTopicChange = (e, val) => {
    setTopic(e.target.value);
  };
  const fetchMarketNews = () => {
    setLoading(true);
    const body = {
      limit: limit,
    };
    if (topic) {
      body.topics = topic;
    }
    ApiPost(`admin/marketNews`, body)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          setNewsData(res?.data?.feed);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSectorList = () => {
    ApiGet("admin/getNewsSector")
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          setSelectedCategories(res.data.response);
          setFilterData(res.data.response);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const openAddNewsModal = () => {
    setOpen(true);
  };
  const getBlogSector = (item) => {
    let arr = item?.blogSectorDetails?.map((item) => item.sector);
    const str = arr?.join(",");
    return str;
  };
  useEffect(() => {
    if (tabIndex === 0) {
      getNewsData();
    } else if (tabIndex == 1) {
      fetchMarketNews();
    }
  }, [tabIndex, page, topic]);
  useEffect(() => {
    getSectorList();
  }, []);
  useEffect(() => {
    if (prefiiledValues && prefiiledValues.length >= 0) {
      console.log("under")
      const matchedOption = selectedCategories.filter((option) =>
        prefiiledValues.some((item) => item._id === option._id)
      );
      setValues(matchedOption);
    }
  }, [prefiiledValues]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: theme.palette.primary.main }}
            >
              News That Moves
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                alignItems: "center",
              }}
            >
              <>
                <SelectDropDown
                  placeholder={"Select news sector"}
                  values={filterData.map((item) => item.sector)}
                  onChange={handleTopicChange}
                  name="topic"
                  value={topic}
                  labelSize={"15px"}
                  width={"250px"}
                  labelPadding={"0px"}
                  sx={{ marginTop: "4px" }}
                  menuHeight ={500}
                />

                {topic && (
                  <CancelIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => setTopic(null)}
                  />
                )}
              </>

              <Button variant="contained" onClick={() => openAddNewsModal()}>
                Add News
              </Button>
            </Box>
          </Grid>
        </Box>

        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
            <Tab label="News" />
            <Tab label="Market News" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={3} px={1} mt={1}>
              {loading ? (
                <Box
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "53%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  {newsData?.length > 0 ? (
                    newsData?.map((item) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                        <Card sx={{ maxWidth: 345 }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.image}
                              alt="Full Image"
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "auto",
                                minHeight: "140px",
                                maxHeight: "140px",
                              }}
                            />
                          </Box>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className={classes.cardContent}
                            >
                              {item?.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className={classes.cardContent}
                            >
                              <strong>Sector:</strong>{" "}
                              {item ? getBlogSector(item) : "--"}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className={classes.cardContent}
                            >
                              <strong>Description:</strong>{" "}
                              {item?.description ?? "--"}
                            </Typography>
                          </CardContent>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <CardActions>
                              <Button
                                onClick={() =>
                                  navigate(`/news/view/${item._id}`)
                                }
                              >
                                View More
                              </Button>
                            </CardActions>

                            <CardActions className={classes.cardActions}>
                              <IconButton color="primary">
                                <EditIcon onClick={() => handleEdit(item)} />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setId(item._id);
                                  setConfirmationModal(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          </Box>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid container m={5}>
                      <NoDataFound elevation={0} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {newsData?.length > 0 && totalDataCount > itemsPerPage && (
              <Box
                mt={"2rem"}
                width={"100%"}
                display={"block"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Pagination
                  count={Math.ceil(totalDataCount / itemsPerPage)}
                  onChange={onChangeHandler}
                  page={page}
                />
              </Box>
            )}
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <TableView
              setData={setData}
              setOpen={setOpen}
              newsData={newsData}
              loading={loading}
              setSelectedCategories={setSelectedCategories}
            />
          </TabPanel>
        </Box>
        <Modal
          open={open || isEdit}
          handleClose={onCloseHandler}
          title={isEdit ? "Edit News" : "Add News"}
          savetext={isEdit ? "Update" : "Save"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={isEdit ? onEditHandler : onAddHandler}
          loading={loading}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "12px", color: "red", mb: 1 }}>
                    Note :- Please select .png .jpg and .jpeg images
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={data.image ? 9 : 12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  <>
                    <Card
                      style={{
                        padding: "20px",
                        border: `1px dashed ${theme.palette.primary.main}`,
                        textAlign: "center",
                        boxShadow: "none",
                      }}
                    >
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div style={{ color: theme.palette.primary.main }}>
                          <CloudUploadIcon fontSize="large" />
                          {disableDropzone ? (
                            <p>Image is uploading...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                        </div>
                      )}
                    </Card>
                    {/* <Typography sx={{ fontSize: "12px", color: "red" }}>
                        * Please select .mp4, .avi, or other video formats
                      </Typography> */}
                    {isEmpty && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isEmpty}
                      </Typography>
                    )}
                    {isWrong && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isWrong}
                      </Typography>
                    )}
                    {isLarge && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isLarge}
                      </Typography>
                    )}
                    {error.image && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {error.image}
                      </Typography>
                    )}
                  </>
                </Grid>
                {showLogoPicker && (
                  <CustomDialog
                    isOpen={showLogoPicker}
                    handleClose={() => {
                      setShowLogoPicker(false);
                      setShowCropper(false);
                      setUploadedLogo(null);
                    }}
                  >
                    <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                    <DialogContent
                      sx={{
                        width: {
                          lg: "700px",
                          md: "700px",
                          sm: "600px",
                          xs: "400px",
                        },
                      }}
                      dividers
                    >
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <div style={{ width: "100%", position: "relative" }}>
                            <Cropper
                              style={{ height: 300, width: "100%" }}
                              src={uploadedLogo}
                              viewMode={0}
                              background={false}
                              responsive={false}
                              minCropBoxHeight={20}
                              minCropBoxWidth={10}
                              dragMode="move"
                              autoCropArea={1}
                              checkOrientation={false}
                              cropBoxResizable={true}
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={false}
                            />

                            {/* <IconButton
                          component="span"
                          style={{
                            justifyContent: "center", alignSelf: "center", position: "absolute", top: 0, right: 0,
                          }}
                          onClick={() => {
                            setShowCropper(false);
                            setUploadedLogo(null);
                            setInitialLogo(null);
                            setShowLogoPicker(false)
                          }}
                        >
                          <DeleteIcon style={{ color: theme.palette.bgWhite.main, marginTop: 10 }} />
                        </IconButton> */}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"end"} gap={1}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setShowCropper(false);
                                setUploadedLogo(null);
                                setInitialLogo(null);
                                setShowLogoPicker(false);
                              }}
                              color="error"
                              sx={{ borderRadius: 5 }}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              color="primary"
                              variant="contained"
                              sx={{ borderRadius: 5 }}
                              disableElevation
                              onClickHandler={() => {
                                getCropData();
                              }}
                              loading={disableDropzone}
                              text="Crop"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </CustomDialog>
                )}
                {data.image && (
                  <>
                    <Grid item xs={3} borderRadius={"5px"}>

                      <Box
                        sx={{
                          position: "relative",
                          background: `url(${data.image})`,
                          height: "130px",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          overflow: "hidden",
                          "&:hover .hoverButtons": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          className="hoverButtons"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Box
                            onClick={() => window.open(data.imgLink, "_blank")}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            bgcolor={theme.palette.bgWhite.main}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "42%",
                              right: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: theme.palette.bgBlack.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              setData({
                                ...data,
                                image: null,
                                imgLink: "",
                              });
                            }}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: theme.palette.error.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={" News Headline"}
                    placeholder={"Enter Headline"}
                    type="text"
                    name="title"
                    value={data.title}
                    onChange={(e) => {
                      setData({ ...data, title: e.target.value });
                      setError({
                        ...error,
                        title: "",
                      });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {/* {error && (
                                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                                        {error}
                                    </Typography>
                                )} */}

                  {error.title && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.title}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={selectedCategories}
                    getOptionLabel={(option) => option.sector}
                    sx={{
                      color: "red",
                      "& .MuiFormControl-root .MuiInputLabel-root": {
                        fontSize: "15px",
                        marginTop: "2px",
                      },
                      "& .MuiFormControl-root .MuiOutlinedInput-root": {
                        paddingTop: "11px",
                        paddingBottom: "11px",
                        paddingLeft: "6px",
                      },
                      "& .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input":
                      {
                        height: "14px !important",
                      },
                    }}
                    value={values}
                    onChange={(event, newValue) => {
                      setValues(newValue);
                      setError({ ...error, category: "" });
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Select news sectors" />
                    )}
                  />
                  {error.category && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.category}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={"News Link"}
                    placeholder={"Enter the news link"}
                    type="text"
                    name="newsLink"
                    value={data.newsLink}
                    onChange={(e) => {
                      setData({ ...data, newsLink: e.target.value });
                      setError({ ...error, newsLink: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.newsLink && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.newsLink}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} mb={2}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Description"}
                    placeholder={"Enter Description"}
                    type="text"
                    name="description"
                    value={data.description}
                    multiline
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      setError({ ...error, description: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.description && (
                    <Typography color="error" fontSize={"12px"} mt={2}>
                      {error.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          }
        />

        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={onDeleteHandler}
          headTitle={"Are you sure you want to delete this news?"}
        />
      </Box>
    </>
  );
};

export default News;
