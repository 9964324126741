import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TablePagination,
  TextField,
  Divider,
  Grid,
} from "@mui/material";

import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import ConfirmationModal from "../../common/ConfirmationModal";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import Modal from "../../common/Modal";
import ReadMore from "../../common/ReadMore";
import { useTabContext } from "@mui/lab";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  placeholder: {
    '&::placeholder': {
      fontWeight: 600
    },
  },
  
}));


function TabTable() {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const [tabName, setTabName] = useState("");
  const [data , setData] = useState({
    sector : '',
    description : '',
  })
  const [masterList, setMasterList] = useState([]);
  const [index, setIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({
    description :'',
    sector : ''
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);


  const handleClear = () => {
    setData({
      description : '',
      sector:''
    })
    setErrors({
      description : '',
      sector : ''
    })
    setOpen(false)
    setIsEdit(false)
    setConfirmationModal(false)
  };

  const tabEditHandler = (data) => {
    setIsEdit(true);
    setData({
      description : data.description,
      sector : data.sector
    })
    setIndex(data?._id);
  };

  const validation = () => {
    let obj = {};
    let flag = false
    if(!data.sector){
      obj.sector = "Please enter the sector !"
      flag = true;
    }
    setErrors(obj);
    console.log("error flag" , flag , data)
    return flag;
  };

  const onDeleteButtonClick = (data) => {
    setConfirmationModal(true);
    setIndex(data._id);
  };
  const deleteStockType = () => {
    setLoading(true);
    ApiDelete(`admin/deleteNewsSector/${index}`)
      .then((res) => {
        const error = ErrorHandler(res)
        if (error) {
          toast.success(res.data.message);
          handleClear();
          fetchNewsMaster();
        }
      })
      .catch((err) => { toast.error(err?.message); })
      .finally(() => { setLoading(false); });
  };

  const fetchNewsMaster = () => {
    setLoading(true);
    ApiGet(`admin/getNewsSector?page=${page + 1}&limit=${rowsPerPage}`)
      .then((res) => {
        console.log("res" , res)
        const error = ErrorHandler(res)
        if (error) {
          setMasterList(res.data.response)
          setCount(res.data.count)
          handleClear();
        }
      })
      .catch((err) => { toast.error(err?.message); })
      .finally(() => { setLoading(false); });
  };

  const onChangeHandler = (e) => {
    const {name , value} = e.target;
    setData({
      ...data,
      [name] : value
    })
    setErrors({
      ...errors,
      [name] : ''
    })
  };


  
  const addNewsMaster = () => {
    console.log("add news master called"
    )
    if (validation()) {
      return;
    }
    setLoading(true);
    const body = {
      sector: data.sector,
    };
  
    if (data.description) {
      body.description = data.description;
    }
    console.log("body is " , body)
    ApiPost("admin/newsSector", body)
      .then((response) => {
        const error = ErrorHandler(response);
        if (error) {
          toast.success(response.message); 
          handleClear();
          fetchNewsMaster();
        }
      })
      .catch((err) => {
        toast.error(err?.message || "An unknown error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditSubmit = () => {
    if(validation()){
      return ;
    }
      setLoading(true);
      const body = {
        _id : index,
        sector : data.sector
      }
      if(data.description){
        body.description = data.description
      }
      ApiPut("admin/updateNewsSector", body)
        .then((res) => {
          const error = ErrorHandler(res)
          if (error) {
            toast.success(res.data.message);
            handleClear();
            fetchNewsMaster();
          }
        })
        .catch((err) => { toast.error(err?.message); })
        .finally(() => { setLoading(false); setIsEdit(false); });
    
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };


  useEffect(() => {
    fetchNewsMaster()
  }, [page ,rowsPerPage]);
  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            News Sector
          </Typography>
          <Button onClick={() => setOpen(true)} variant="contained">
            Add News Sector
          </Button>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">No.</StyledTableCell>
                <StyledTableCell align="left">Sector</StyledTableCell>
                <StyledTableCell align="left">Description</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={4}>
                    <Loader />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {masterList?.length > 0 ? masterList?.map((data, index) => (
                    <StyledTableRow key={data?._id}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {data?.sector}
                      </StyledTableCell>
                      <StyledTableCell align="left" style={{maxWidth : '500px'}}>
                        <ReadMore content={data.description ?? "--"} maxLength={150}/>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box
                          display={"flex"}
                          gap={1}
                          justifyContent={"center"}
                        >
                          <EditIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => tabEditHandler(data)}
                          />
                          <DeleteIcon
                            sx={{
                              marginLeft: "10px",
                              color: theme.palette.error.main,
                              cursor: "pointer",
                            }}
                            onClick={(e) => onDeleteButtonClick(data)}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  )) : <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <NoDataFound elevation={0} />
                    </StyledTableCell>
                  </StyledTableRow>}
                </>
              )}
            </TableBody>
          </Table>
            {count > 0 && (
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            )}
        </TableContainer>
        
        <Modal
          open={open || isEdit}
          handleClose={handleClear}
          title={isEdit ? "Edit News Sector" : "Add News Sector"}
          savetext={isEdit ? "Update" : "Add"}
          cancelText={"Cancel"}
          handleReject={handleClear}
          handleApprove={isEdit ? handleEditSubmit : addNewsMaster}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1} mb={'12px'}>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Sector Name"}
                    placeholder={"Enter the sector name "}
                    type="text"
                    name="sector"
                    value={data.sector}
                    onChange={(e) => onChangeHandler(e)}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {errors?.sector && (
                    <Typography
                      color="error"
                      fontSize={"12px"}
                      style={{ marginTop: "2px" }}
                    >
                      {errors.sector}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Description"}
                    placeholder={"Enter the description of sector"}
                    type="text"
                    name="description"
                    multiline
                    value={data.description}
                    onChange={(e) => onChangeHandler(e)}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {errors?.description && (
                    <Typography
                      color="error"
                      fontSize={"12px"}
                      style={{ marginTop: "2px" }}
                    >
                      {errors.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          }
        />
        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={deleteStockType}
          headTitle={"Are you sure you want to delete this news sector?"}
          loading={loading}
        />
      </Box>
    </>
  );
}

export default TabTable;
