import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Visibility from "@mui/icons-material/Visibility";

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";

import Loader from "../../common/Loader";
import moment from "moment";
import ErrorHandler from "../../helper/errorHandler";
import NoDataFound from "../../common/noDataFound";
import ReadMore from "../../common/ReadMore";
import { ApiGet } from "../../helper/API/ApiData";
const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));
const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  "&:hover .image-overlay": {
    opacity: 1,
  },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  opacity: 0,
  transition: "opacity 0.2s",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ContactUs() {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [contactUsList, setContactUsList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const renderDate = (data) => {
    const dateString = data;
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const fetchContactUs = () => {
    setLoading(true);
    ApiGet(`admin/getContectUs?page=${page + 1}&limit=${rowsPerPage}`)
      .then((res) => {
        const isError = ErrorHandler(res);
        if (isError) {
          setContactUsList(res.data.data);
          setCount(res.data.total);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchContactUs();
  }, [page, rowsPerPage]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Contact Us
          </Typography>
        </Box>
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No</StyledTableCell>
              <StyledTableCell align="left">User Name</StyledTableCell>
              <StyledTableCell align="left">Image</StyledTableCell>
              <StyledTableCell align="left">Title</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Mobile Number</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <Loader />
                </StyledTableCell>
              </TableRow>
            ) : (
              <>
                {contactUsList?.length > 0 ? (
                  contactUsList?.map((data, index) => (
                    <StyledTableRow key={data?._id}>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {data.image ? (
                          <ImageContainer>
                            <img
                              src={data.image}
                              alt="img"
                              style={{
                                width: "75px",
                                maxHeight: "75px",
                                borderRadius: "3px",
                              }}
                            />
                            <ImageOverlay className="image-overlay">
                              <Visibility
                                style={{ cursor: "pointer", color: "white" }}
                                onClick={() =>
                                  window.open(data.image, "_blank")
                                }
                              />
                            </ImageOverlay>
                          </ImageContainer>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell sx={{ minWidth: "150px" }}>
                        <ReadMore content={data.title} maxLength={35} />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ maxWidth: "300px", minWidth: "300px" }}
                      >
                        {data?.description ? (
                          <ReadMore
                            content={data.description}
                            maxLength={120}
                          />
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.email ? (
                          <>{data.email}</>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.mobileNo ? (
                          <>{data.mobileNo}</>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {renderDate(data.createdAt)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <NoDataFound elevation={0} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
        {count > 0 && (
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        )}
      </TableContainer>
    </>
  );
}

export default ContactUs;
