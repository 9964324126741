import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  Pagination,
  Button,
  IconButton
} from "@mui/material";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";
import { makeStyles, useTheme } from "@mui/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import * as XLSX from "xlsx"; 

import axios from "axios";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  search_input_field: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      width: 250,
      height: 50,
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  datePicker: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "14px",
      color: theme.palette.primary.main, // Default label color
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      lineHeight: "14px",
      height: "43px",
      color: theme.palette.text.primary, // Input font color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main, // Default outline color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Outline color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main, // Outline color when focused
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main, // Label color when focused
    },
  },
  filterContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "flex-end",
  },
}));

const TableView = (props) => {
  const theme = useTheme();
  const fundsPerPage = 10

  const {mutualFundData , totalFunds , loading , setLoading , addMutualFundFromTheMarket} = props;

  const [currentPage, setCurrentPage] = useState(1);
  

  const totalPages = Math.ceil(totalFunds / fundsPerPage);

  // Get current page records
  const indexOfLastRecord = currentPage * fundsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - fundsPerPage;
  const currentRecords = mutualFundData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDownloadExcel = async (schemeCode) => {
    setLoading(true); // Ensure loading state is set before the request
  
    try {
      const { data } = await axios.get(`https://api.mfapi.in/mf/${schemeCode}`);
  
      if (data) {
        // Prepare Excel data
        const excelData = data.data.map((item) => ({
          Date: item.date,
          NAV: item.nav,
        }));
  
        // Include scheme name in the first row
        const excelSheet = [
          { Scheme_Name: data.meta.scheme_name },
          {},
          ...excelData,
        ];
  
        // Create worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(excelSheet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "NAV Data");
  
        // Create a downloadable Excel file
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
  
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${data.meta.scheme_name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn(`No data found for schemeCode: ${schemeCode}`);
      }
    } catch (error) {
      console.error(
        `Failed to fetch data for schemeCode ${schemeCode}: ${error.message}`
      );
    } finally {
      setLoading(false); // Ensure loading state is reset after request
    }
  };
  

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: "15px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Return (%)</StyledTableCell>
              <StyledTableCell>Download</StyledTableCell>
              <StyledTableCell>Add Mutual Fund</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : currentRecords?.length > 0 ? (
              currentRecords.map((mutualFund, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    {indexOfFirstRecord + index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{mutualFund.schemeName}</StyledTableCell>
                  <StyledTableCell>{mutualFund.schemeCode}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick = {(e)=> {e.preventDefault(); handleDownloadExcel(mutualFund.schemeCode)}}>
                    <DownloadForOfflineRoundedIcon
                      sx={{
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                      
                    />
                    </IconButton>
                    
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={()=>{console.log("clicked") ;addMutualFundFromTheMarket(mutualFund)}}>
                    <AddCircleOutlineIcon
                      sx={{
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                      
                    />
                    </IconButton>
                    
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <NoDataFound />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Component */}
      <Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </>
  );
};

export default TableView;
