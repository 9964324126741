import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';

const ReadMore = ({ content, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const isContentLong = content?.length > maxLength;

  const displayContent = isExpanded || !isContentLong
    ? content
    : `${content?.slice(0, maxLength)}...`;

  return (
    <Box>
      <Typography variant="body1">
        {displayContent}
      </Typography>
      {isContentLong && (
        <Button onClick={toggleReadMore}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </Button>
      )}
    </Box>
  );
};



export default ReadMore;
