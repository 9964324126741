import { ApiGet, ApiPut } from "../../helper/API/ApiData";
import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  DELETE_REPORT_FEEDBACK_REQUEST,
  DELETE_REPORT_FEEDBACK_SUCCESS,
  DELETE_REPORT_FEEDBACK_FAILURE
} from './reportConstant'

export const fetchReportRequest = () => ({
  type: FETCH_REPORT_REQUEST,
});

export const fetchReportSuccess = (data) => ({
  type: FETCH_REPORT_SUCCESS,
  payload: data,
});

export const fetchReportFailure = (error) => ({
  type: FETCH_REPORT_FAILURE,
  payload: error,
});

export const deleteReportFeedbackRequest = () => ({
  type: DELETE_REPORT_FEEDBACK_REQUEST,
});

export const deleteReportFeedbackSuccess = (message) => ({
  type: DELETE_REPORT_FEEDBACK_SUCCESS,
  payload: message,
});

export const deleteReportFeedbackFailure = (error) => ({
  type: DELETE_REPORT_FEEDBACK_FAILURE,
  payload: error,
});

export const fetchReports = (page, limit, status) => {
  return async (dispatch) => {
    dispatch(fetchReportRequest());
    try {
      const response = await ApiGet(`admin/getReport?page=${page}&limit=${limit}&status=${status}`);
      if (response.status === 200) {
      }
      else {
        dispatch(fetchReportFailure(response.message));
      }
      return response;
    }
    catch (err) {
      dispatch(fetchReportFailure(err?.message));
      throw err
    }
  }
}

export const deleteReportFeedback = (payload) => {
  return async (dispatch) => {
    dispatch(deleteReportFeedbackRequest());
    try {
      const response = await ApiPut(`admin/feedbackAction`, payload);
      dispatch(deleteReportFeedbackSuccess(response.data.message));
      return response;
    } catch (err) {
      dispatch(deleteReportFeedbackFailure(err?.message));
      throw err;
    }
  };
};