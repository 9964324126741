import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ApiDelete, ApiGet } from "../../helper/API/ApiData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader";
import ConfirmationModal from "../../common/ConfirmationModal";
import NoDataFound from "../../common/noDataFound";
import ErrorHandler from "../../helper/errorHandler";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TablePagination,
  Tab,
  Grid,
} from "@mui/material";
import {
  deleteSubscription,
  fetchSubscritions,
} from "../../redux/subscriptions/subscriptionsActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
}));

const Subscription = () => {
  //Hooks
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //States
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false); //subscription data delete modal
  const [referDayOpen, setReferDayOpen] = useState(false); //referral day data delete modal
  const [selectedId, setSelectedId] = useState(0);
  const [status, setStatus] = useState(0);
  const [value, setValue] = useState("1");
  const [ReferralData, setReferralData] = useState({});

  //handler functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue <= 2) {
      setStatus(newValue - 1);
    }
    setPage(0);
    // setRowsPerPage(10);
  };

  const opneModal = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  const openReferDayModal = (id) => {
    setReferDayOpen(true);
    setSelectedId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setReferDayOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //API Calling Functions
  const getSubscriptionData = () => {
    setLoading(true);
    dispatch(fetchSubscritions(page + 1, rowsPerPage, status))
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res.data.getSubscription) {
            /* setSubscriptionData(res.data.getSubscription);
            setTotalLength(res.data.totalCount); */
            setSubscriptionData(res.data);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteHandler = (id) => {
    setLoading(true);
    dispatch(deleteSubscription(id))
      .then((response) => {
        const error = ErrorHandler(response);
        if (error) {
          toast.success(response.data.message);
          handleClose();
          getSubscriptionData();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReferralData = async () => {
    setLoading(true);
    ApiGet("user/getReferDay")
      .then(async (res) => {
        if (res?.data) {
          setReferralData(res?.data);
        }
      })
      .catch((error) => {
        setReferralData({});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const referDayDeleteHandler = async (id) => {
    setLoading(true);
    ApiDelete(`admin/removeReferDay?id=${id}`)
      .then(async (response) => {
        const error = await ErrorHandler(response);
        if (error) {
          toast.success(response.data.message);
          handleClose();
          getReferralData();
        }
      })
      .catch((err) => {
        toast.error(err?.message || "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //UseEffects
  useEffect(() => {
    getSubscriptionData();
  }, [page, rowsPerPage, status]);

  //To get referral Data when Referral Day tab is active
  useEffect(() => {
    if (value == 3) {
      getReferralData();
    }
  }, [value]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Subscriptions
          </Typography>
          <Box>
            {
              value == 3 ? <Button
                sx={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => navigate("/referral/add")}
                variant="contained"
                disabled={Object.keys(ReferralData).length > 0}
              >
                Add Referral Days
              </Button> :
                <Button
                  sx={{ marginTop: "10px", marginLeft: "10px" }}
                  onClick={() => navigate("/subscription/add")}
                  variant="contained"
                >
                  Add Plan
                </Button>
            }
          </Box>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className={classes.tab_main}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{ fontWeight: "600" }}
                  label="Learning Calls"
                  value="1"
                />

                <Tab
                  sx={{ fontWeight: "600" }}
                  label="Market Calls"
                  value="2"
                />

                <Tab
                  sx={{ fontWeight: "600" }}
                  label="Refferal Days"
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell>Price</StyledTableCell>
                      <StyledTableCell>Duration</StyledTableCell>
                      <StyledTableCell>Discount Percentage</StyledTableCell>
                      <StyledTableCell>Discounted Price </StyledTableCell>
                      <StyledTableCell>Plan For</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {subscriptionData?.getSubscription?.length > 0 ? (
                          subscriptionData?.getSubscription?.map(
                            (data, index) => (
                              <StyledTableRow key={data?._id}>
                                <StyledTableCell align="center">
                                  {page * rowsPerPage + index + 1}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {data?.amount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.months}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.discount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.discountAmount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.status === 0
                                    ? "Learning"
                                    : "Market Calls"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Box
                                    display={"flex"}
                                    gap={1}
                                    justifyContent={"center"}
                                  >
                                    <EditIcon
                                      sx={{
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/subscription/edit/${data?._id}`
                                        )
                                      }
                                    />
                                    <DeleteIcon
                                      sx={{
                                        marginLeft: "10px",
                                        color: theme.palette.error.main,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => opneModal(data._id)}
                                    />
                                  </Box>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={12}>
                              <NoDataFound elevation={0} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
                {subscriptionData.totalCount > 0 && (
                  <TablePagination
                    component="div"
                    count={subscriptionData.totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                  />
                )}
              </TableContainer>
            </TabPanel>

            <TabPanel value="2" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell>Price</StyledTableCell>
                      <StyledTableCell>Duration</StyledTableCell>
                      <StyledTableCell>Discount Percentage</StyledTableCell>
                      <StyledTableCell>Discounted Price</StyledTableCell>
                      <StyledTableCell>Plan For</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {subscriptionData?.getSubscription?.length > 0 ? (
                          subscriptionData?.getSubscription?.map(
                            (data, index) => (
                              <StyledTableRow key={data?._id}>
                                <StyledTableCell align="center">
                                  {page * rowsPerPage + index + 1}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.amount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.months}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.discount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.discountAmount}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data?.status === 0
                                    ? "Learning"
                                    : "Market Calls"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Box
                                    display={"flex"}
                                    gap={1}
                                    justifyContent={"center"}
                                  >
                                    <EditIcon
                                      sx={{
                                        color: theme.palette.primary.main,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/subscription/edit/${data?._id}`
                                        )
                                      }
                                    />
                                    <DeleteIcon
                                      sx={{
                                        marginLeft: "10px",
                                        color: theme.palette.error.main,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => opneModal(data._id)}
                                    />
                                  </Box>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={12}>
                              <NoDataFound elevation={0} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
                {subscriptionData.totalCount > 0 && (
                  <TablePagination
                    component="div"
                    count={subscriptionData.totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                  />
                )}
              </TableContainer>
            </TabPanel>

            <TabPanel value="3" sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell align="center">
                        Referral Days
                      </StyledTableCell>
                      <StyledTableCell align="center">Address</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={8}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {Object.keys(ReferralData).length > 0 ? (
                          <StyledTableRow key={ReferralData?._id}>
                            <StyledTableCell align="center">1</StyledTableCell>
                            <StyledTableCell align="center">
                              {ReferralData?.referDay}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ReferralData?.address}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Box
                                display={"flex"}
                                gap={1}
                                justifyContent={"center"}
                              >
                                <EditIcon
                                  sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/referral/edit/${ReferralData?._id}`
                                    )
                                  }
                                />
                                <DeleteIcon
                                  sx={{
                                    marginLeft: "10px",
                                    color: theme.palette.error.main,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    openReferDayModal(ReferralData._id)
                                  }
                                />
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={12}>
                              <NoDataFound elevation={0} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <ConfirmationModal
        handleClose={handleClose}
        open={open}
        onDeleteHandler={() => onDeleteHandler(selectedId)}
        headTitle={"Are you sure you want to delete this call ?"}
        loading={loading}
      />

      <ConfirmationModal
        handleClose={handleClose}
        open={referDayOpen}
        onDeleteHandler={() => referDayDeleteHandler(selectedId)}
        headTitle={"Are you sure you want to delete this refer day ?"}
        loading={loading}
      />
    </>
  );
};

export default Subscription;
