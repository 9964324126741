import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";
import {
  addSubscription,
  editSubscription,
  fetchSubscriptionEditData,
} from "../../redux/subscriptions/subscriptionsActions";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  outlined_button: {
    textTransform: "none !important",
    fontWeight: "700 !important",
    color: theme.palette.primary.main,
    border: theme.palette.primary.main,
  },
  card_main: {
    backgroundColor: theme.palette.bgWhite.main,
    padding: "15px",
    borderRadius: "10px",
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  input_main: {
    "& .MuiOutlinedInput-root": {
      "& input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  placeholder: {
    '&::placeholder': {
      fontWeight: 600
    },
  },
}));

const Add = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [subscriptionData, setSubscriptionData] = useState({
    price: null,
    discount: null,
    discountedPrice: null,
    duration: "",
    status: "default",
    planName : ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [editLoading, setEditLoading] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "discount") {
      discountPriceHandler(value);
    } else if (name === "price") {
      priceChangeHandler(value);
    } else {
      setSubscriptionData({
        ...subscriptionData,
        [name]: value,
      });
    }
    if (errors[name] !== "") {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const priceChangeHandler = (value) => {
    const calculatePrice = value - (value * subscriptionData.discount) / 100;
    setSubscriptionData({
      ...subscriptionData,
      price: value,
      discountedPrice: Math.round(calculatePrice),
    });
  };
  
  const discountPriceHandler = (discount) => {
    if (discount.length > 2) {
      return;
    }
    const calculatePrice =
      subscriptionData.price - (subscriptionData?.price * discount) / 100;
    setSubscriptionData({
      ...subscriptionData,
      discount: discount,
      discountedPrice: Math.round(calculatePrice),
    });
  };

  const validation = () => {
    const obj = {};
    let flag = false;
    if (subscriptionData.price === null) {
      obj.price = "Please enter the price !";
      flag = true;
    }
    if (subscriptionData.duration === "") {
      obj.duration = "Please enter the duration !";
      flag = true;
    }

    if (subscriptionData.status === "default") {
      obj.status = "Please select the plan !";
      flag = true;
    }

    setErrors(obj);
    return flag;
  };

  const onEditHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      id: id,
      months: subscriptionData.duration,
      amount: subscriptionData.price,
      discountAmount: subscriptionData.discountedPrice,
      discount: subscriptionData.discount ? subscriptionData.discount : 0,
      status: subscriptionData.status,
      referralDays: subscriptionData.referralDays,
    };
    dispatch(editSubscription(payload))
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/subscription");
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const onAddHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const body = {
      months: subscriptionData.duration,
      amount: subscriptionData.price,
      discountAmount: subscriptionData.discountedPrice,
      discount: subscriptionData.discount ? subscriptionData.discount : 0,
      status: subscriptionData.status,
      planName : subscriptionData.planName
    };
    dispatch(addSubscription(body))
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/subscription");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      });
  };

  const fetchSingleSubscriptionData = (id) => {
    setEditLoading(true);
    dispatch(fetchSubscriptionEditData(id))
      .then((res) => {
        if (res.status === 200) {
          const obj = res.data;
          setSubscriptionData({
            price: obj.amount,
            discount: obj.discount,
            discountedPrice: obj.discountAmount,
            duration: obj.months,
            status: obj.status,
            // refferalDays: obj.refferalDays,
            planName : obj.planName
          });
          setEditLoading(false);
        } else {
          toast.error(res.message);
          setEditLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        setEditLoading(false);
      });
  };
  const onCancelHandler = () => {
    setSubscriptionData({
      price: null,
      discount: null,
      discountedPrice: null,
      duration: "",
      status: null,
      planName : ""
    });
    navigate("/subscription");
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "-"  || e.key === "e") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleSubscriptionData(id);
    }
  }, [id]);

  return (
    <Box>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          {id ? "Edit Plan " : "Add Plan"}
        </Typography>
      </Box>
      <Box className={classes.card_main}>
        {editLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Price"}
                placeholder={"Enter Price"}
                name="price"
                value={subscriptionData.price}
                type="number"
                inputProps={{ min: 0, className: classes.placeholder, }}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
              />
              {errors.price && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.price}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Discount Percentage"}
                placeholder={"Enter Percentage"}
                name="discount"
                value={subscriptionData.discount}
                type="number"
                inputProps={{ min: 0, className: classes.placeholder, }}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                component="input"
              />
              {errors.discount && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.discount}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Discounted Price"}
                placeholder={"Enter Price"}
                name="discountedPrice"
                value={subscriptionData.discountedPrice}
                type="number"
                inputProps={{ min: 0, className: classes.placeholder, }}
                onChange={discountPriceHandler}
              />

              {errors.discountedPrice && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.discountedPrice}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Duration"}
                placeholder={"Enter Duration"}
                name="duration"
                value={subscriptionData.duration}
                type="number"
                inputProps={{ min: 0, className: classes.placeholder, }}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
              />
              {errors.duration && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.duration}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box mt={0} display="flex" fontSize="12px" flexDirection={"row"}>
                <InputLabel
                  sx={{
                    fontSize: "15px",
                    marginRight: "2px",
                    padding: "3px",
                  }}
                >
                  Plan For
                </InputLabel>
              </Box>
              <Select
                value={subscriptionData.status}
                name="status"
                onChange={onChangeHandler}
                inputProps={{ "aria-label": "Without label" }}
                size="small"
                fullWidth
                variant="outlined"
                style={{ height: "44px" }}
                renderValue={(val) => {
                  if (val == "default") {
                    return (
                      <Box sx={{ color: "#00000070", fontWeight: 500, fontSize: '15px' }}>Please select plan</Box>
                    );
                  }
                  return val == 1 ? "Market Calls" : "Learning";
                }}
                disabled = {id ? true : false}
              >
                <MenuItem value={1}>Market Calls</MenuItem>
                <MenuItem value={0}>Learning</MenuItem>
              </Select>
              {errors.status && (
                <Typography color="error" fontSize={"12px"}>
                  {errors.status}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Plan Name"}
                placeholder={"Enter plan name"}
                name="planName"
                value={subscriptionData.planName}
                type="text"
                inputProps={{ min: 0, className: classes.placeholder, }}
                onChange={onChangeHandler}
  
              />
             
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={onCancelHandler}
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={id ? onEditHandler : onAddHandler}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Add;
