import React from 'react'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Header = ({ isopen }) => {
    const theme = useTheme()
    return (
        <AppBar position="fixed" open={isopen} sx={{ bgcolor: theme.palette.bgWhite.main }} elevation={0} >
            <Toolbar variant='regular'>
                <Typography variant="h6" noWrap component="div" color={theme.palette.primary.main} ml={isopen ? 0 : 7}>
                    {/* Shah Application CRM */}
                    Stock Inn
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Header
