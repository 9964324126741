import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
} from "@mui/material";
import Loader from "../../common/Loader";
import moment from "moment";
import NoDataFound from "../../common/noDataFound";
import ReadMore from "../../common/ReadMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  search_input_field: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      width: 250,
      height: 50,
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  datePicker: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "14px",
      color: theme.palette.primary.main, // Default label color
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      lineHeight: "14px",
      height: "43px",
      color: theme.palette.text.primary, // Input font color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main, // Default outline color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Outline color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main, // Outline color when focused
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main, // Label color when focused
    },
  },
  filterContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "flex-end",
  },
}));

// const staticData = [
//   // staticData as defined previously
//   {
//     title:
//       "Former Square, Coinbase execs raise $58M for Bridge stablecoin network",
//     url: "https://cointelegraph.com/news/bridge-stablecoin-network-raises-58m",
//     time_published: "20240830T092127",
//     authors: ["Pradipta Mukherjee"],
//     summary:
//       "Bridge seeks to build a global stablecoin payment network to rival traditional systems like Swift and credit cards.",
//     banner_image:
//       "https://images.cointelegraph.com/cdn-cgi/image/format=auto,onerror=redirect,quality=90,width=1200/https://s3.cointelegraph.com/uploads/2024-08/0191a236-02ae-7087-b74e-9d5715388e44",
//     source: "Cointelegraph",
//     category_within_source: "n/a",
//     source_domain: "cointelegraph.com",
//     topics: [
//       {
//         topic: "Finance",
//         relevance_score: "1.0",
//       },
//       {
//         topic: "Blockchain",
//         relevance_score: "0.983605",
//       },
//       {
//         topic: "Financial Markets",
//         relevance_score: "0.538269",
//       },
//     ],
//     overall_sentiment_score: 0.182529,
//     overall_sentiment_label: "Somewhat-Bullish",
//     ticker_sentiment: [
//       {
//         ticker: "PYPL",
//         relevance_score: "0.303175",
//         ticker_sentiment_score: "0.239471",
//         ticker_sentiment_label: "Somewhat-Bullish",
//       },
//       {
//         ticker: "COIN",
//         relevance_score: "0.204936",
//         ticker_sentiment_score: "0.188823",
//         ticker_sentiment_label: "Somewhat-Bullish",
//       },
//       {
//         ticker: "FOREX:USD",
//         relevance_score: "0.396574",
//         ticker_sentiment_score: "-0.033441",
//         ticker_sentiment_label: "Neutral",
//       },
//       {
//         ticker: "CRYPTO:BTC",
//         relevance_score: "0.103331",
//         ticker_sentiment_score: "0.061478",
//         ticker_sentiment_label: "Neutral",
//       },
//       {
//         ticker: "CRYPTO:UST",
//         relevance_score: "0.204936",
//         ticker_sentiment_score: "-0.36078",
//         ticker_sentiment_label: "Bearish",
//       },
//     ],
//   },
//   {
//     title:
//       "All of Trump's Crypto Promises, From Boosting Bitcoin Mining to Firing Gensler",
//     url: "https://decrypt.co/244506/donald-trump-bitcoin-crypto-campaign-promises",
//     time_published: "20240813T205353",
//     authors: ["André Beganski"],
//     summary:
//       "The former president has made crypto a part of his campaign's platform, mirroring initiatives from other U.S. conservatives.",
//     banner_image:
//       "https://cdn.decrypt.co/resize/1024/height/512/wp-content/uploads/2024/07/trump-gID_7.png",
//     source: "Decrypt.co",
//     category_within_source: "n/a",
//     source_domain: "decrypt.co",
//     topics: [
//       {
//         topic: "Economy - Monetary",
//         relevance_score: "0.576289",
//       },
//       {
//         topic: "Financial Markets",
//         relevance_score: "0.360215",
//       },
//       {
//         topic: "Finance",
//         relevance_score: "1.0",
//       },
//       {
//         topic: "Blockchain",
//         relevance_score: "0.310843",
//       },
//     ],
//     overall_sentiment_score: 0.206844,
//     overall_sentiment_label: "Somewhat-Bullish",
//     ticker_sentiment: [
//       {
//         ticker: "COIN",
//         relevance_score: "0.031364",
//         ticker_sentiment_score: "0.14038",
//         ticker_sentiment_label: "Neutral",
//       },
//       {
//         ticker: "FOREX:USD",
//         relevance_score: "0.031364",
//         ticker_sentiment_score: "0.090937",
//         ticker_sentiment_label: "Neutral",
//       },
//       {
//         ticker: "CRYPTO:BTC",
//         relevance_score: "0.417995",
//         ticker_sentiment_score: "0.144865",
//         ticker_sentiment_label: "Neutral",
//       },
//     ],
//   },
// ];

const TableView = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setData, setOpen , newsData , loading ,setSelectedCategories} = props;

  
  const onActionHandler = (data) => {
    console.log("data " ,data)
    setOpen(true);
    setData({
      image  :data.banner_image,
      imgLink: data.banner_image,
      title: data.title,
      description: data.summary,
      newsLink: data.url,
    });
  };

  /* const limitChangeHandler = debounce((e) => {
    setLimit(e.target.value);
  }, 500); */

  return (
    <>
      <Box className={classes.filterContainer}>
        

        {/* <CommonTextField
          text={"Limit"}
          type={"number"}
          onChange={limitChangeHandler}
          fontSize={"15px"}
          labelPadding={"0px"}
        /> */}
      </Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ marginTop: "15px" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Sentiment</StyledTableCell>
              <StyledTableCell>Published Time</StyledTableCell>
              <StyledTableCell> Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <StyledTableCell colSpan={7}>
                  <Loader />
                </StyledTableCell>
              </TableRow>
            ) : (
              <>
                {newsData?.length > 0 ? (
                  newsData?.map((news, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ maxWidth: "300px", minWidth: "300px" }}
                      >
                        <ReadMore content={news?.title} maxLength={35} />
                      </StyledTableCell>
                      <StyledTableCell>
                        {news?.banner_image ? (
                          <img
                            src={news?.banner_image}
                            alt="img"
                            style={{
                              width: "75px",
                              maxHeight: "75px",
                              borderRadius: "3px",
                            }}
                          />
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ maxWidth: "300px", minWidth: "300px" }}
                      >
                        <ReadMore content={news?.summary} maxLength={35} />
                      </StyledTableCell>
                      <StyledTableCell>
                        {news?.overall_sentiment_label ?? "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(news?.time_published).format("DD/MM/YYYY") ??
                          "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display={"flex"} gap={1} justifyContent={"center"}>
                          <AddCircleOutlineIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => onActionHandler(news)}
                          />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7}>
                      <NoDataFound />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableView;
