// reducer.js
import {FETCH_FEEDBACK_SUCCESS ,FETCH_FEEDBACK_FAILURE ,FETCH_FEEDBACK_REQUEST } from "./feedbackContant";

const initialState = {
  feedback: [],
  loading: false,
  error: null,
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedback: action.payload,
        error: null,
      };
    case FETCH_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
