import React, { Children } from "react";
import { makeStyles } from "tss-react/mui";
import {
  InputLabel,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles()((theme) => {
  return {
    icon: {
      fill: theme?.palette?.primary?.main,
    },
    root: {
      // color: 'white',
    },
    redborder: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
    },
    select: {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.primary?.main} !important`,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.primary?.main} !important`,
      },
    },
  };
});
const SelectDropDown = ({
  text,
  height,
  width,
  values,
  menu,
  valid,
  onChange,
  name,
  value,
  defaultValue,
  defaultChecked,
  disabled,
  fontWeight,
  labelSize,
  rejection,
  className,
  size,
  backgroundColor,
  color,
  sx,
  placeholder,
  labelPadding,
  menuHeight
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  return (
    <Box>
      {text && (
        <Box mt={0} display="flex" fontSize="12px" flexDirection={"row"}>
          <InputLabel
            sx={{
              fontWeight: fontWeight,
              fontSize: labelSize || "15px",
              marginRight: "2px",
              color: theme?.palette?.bgBlack?.main,
              padding: labelPadding ?? '3px'
            }}
          >
            {text}
          </InputLabel>
          {valid && (
            <Typography
              style={{ color: theme.palette.defaultBgRejectColor.main }}
              component={"caption"}
              variant={"body2"}
            >
              *
            </Typography>
          )}
        </Box>
      )}
      <FormControl sx={{ width: width, height: height }}>
        <Select
          size={size || "small"}
          className={cx(classes.select, className)}
          value={value || ""}
          onChange={onChange}
          name={name}
          defaultValue={defaultValue || ""}
          defaultChecked={defaultChecked}
          disabled={disabled}
          displayEmpty
          sx={sx}
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <span style={{ fontSize: "14px", color: color || "#00000070", fontWeight: 600 }}>
                  {placeholder ? placeholder : "Select"}
                </span>
              );
            }
            return (
              <span style={{ color: color || "#00000070" }}>{selected}</span>
            );
            // return selected;
          }}
          // inputProps={{ 'aria-label': 'Without label' }}
          style={{
            backgroundColor: backgroundColor || "transparent",
            color: color || "white",
            width: width || "120px",
            // borderRadius: "10px",
            height:
              size === "medium" ? "55px" : size === "small" ? "39px" : "44px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: menuHeight ? menuHeight : 500,
                overflowY: 'auto',
              },
            },
            MenuListProps: {
              style: {
                padding: 0,
              },
            },
          }}
        >
          {values &&
            values?.map((val, index) => {
              return (
                <MenuItem value={val} key={index.toString()}>
                  {val}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};
export default SelectDropDown;
