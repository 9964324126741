import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  TablePagination,
  Grid,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import Loader from "../../common/Loader";
import moment from "moment";
import NoDataFound from "../../common/noDataFound";
import ErrorHandler from "../../helper/errorHandler";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import Modal from "../../common/Modal";
import { toast } from "react-toastify";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  search_input_field: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      width: 250,
      height: 50,
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  datePicker: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "14px",
      color: theme.palette.primary.main, // Default label color
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      lineHeight: "14px",
      height: "43px",
      color: theme.palette.text.primary, // Input font color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main, // Default outline color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Outline color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main, // Outline color when focused
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main, // Label color when focused
    },
  },
  formLabel: {
    color: "#444444 !important",
    "&.Mui-focused": {
      color: "#444444 !important",
    },
  },
}));

const IosUsers = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [iosUsers, setIosUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [subscriptionModal, setSubsriptionModal] = useState(false);
  const [subOption, setSubOption] = useState([
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ]);
  const [selectedValue, setSelectedValue] = useState("");
  const[loader , setLoader] = useState(false);
  const [userId , setUserId] = useState(null)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchIosUsers = () => {
    setLoading(true);
    const body = {
      page: page,
      limit: rowsPerPage,
    };
    ApiPost(`admin/iosUsers`, body)
      .then((res) => {
        console.log("res", res);
        const error = ErrorHandler(res);
        if (error) {
          setIosUsers(res.data.response);
          setCount(res.data.count);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubscriptionList = ()=>{
    setLoader(true)
    ApiGet('admin/subscriptionList')
    .then((res)=>{
      const checkError = ErrorHandler(res);
      if(checkError){
        const newArr = res.data.map((item)=>{
          return {
            value : item._id,
            label : item.subscriptionType
          }
        })
        setSubOption(newArr)
      }
    }).catch((err)=>{
      toast.error(err.message)
    }).finally(()=>{
      setLoader(false)
    })
  }
  const subscriptionClickHandler = (data) => {
    fetchSubscriptionList()
    setUserId(data._id)
    setSubsriptionModal(true);
  };

  const onCloseHandler = () => {
    setSubsriptionModal(false);
    setSelectedValue("")
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const clearState = ()=>{
    setSubsriptionModal(false);
    setUserId(false)
    setSelectedValue(null)
  }
  const submitSubscription = (e)=>{
    setLoading(true);
    const body = {
      userId:userId,
      subscriptionId:selectedValue
  }
  console.log("body is 👍👍👍" , body)
    ApiPost('admin/manuallyPurchaseSubscription',body)
    .then((res)=>{
      const checkError = ErrorHandler(res);
      if(checkError){
        fetchIosUsers()
        clearState();
      }
    }).catch((err)=>{
      toast.error(err.message);
    }).finally(()=>{
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchIosUsers();
  }, [page, rowsPerPage]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            iOS Users
          </Typography>
          {/* <Stack direction={'row'} spacing={3}>
        <CommonTextField
          fontWeight={600}
          placeholder={"Search User..."}
          type="text"
          name="search"
          onChange={(e) => handleSearch(e.target.value)}
          width={250}
          inputProps={{ className: classes.placeholder }}
        />
        </Stack> */}
        </Box>
        <Box>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ marginTop: "15px" }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">No.</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Number</StyledTableCell>
                  <StyledTableCell>Subscription</StyledTableCell>
                  <StyledTableCell>Created Date</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell colSpan={10}>
                      <Loader />
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  <>
                    {iosUsers?.length > 0 ? (
                      iosUsers?.map((user, index) => (
                        <StyledTableRow key={user?._id}>
                          {console.log("users ", user)}
                          <StyledTableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Avatar
                                sx={{ border: "1px solid gray" }}
                                src={user?.profileImage}
                              ></Avatar>
                              {user?.name}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>{user?.email}</StyledTableCell>
                          <StyledTableCell>{user?.mobileNo}</StyledTableCell>

                          <StyledTableCell
                            style={{
                              color: user?.isSubscribe ? "Green" : "Red",
                            }}
                          >
                            {user?.isSubscribe ? "Active" : "Inactive"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(user?.createdAt).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              onClick={() => subscriptionClickHandler(user)}
                            >
                              <HowToRegIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={12}>
                          <NoDataFound elevation={0} />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {count > 0 && (
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            )}
          </TableContainer>
        </Box>
      </Box>
      <Modal
        open={subscriptionModal}
        title={"iOS User Subscription"}
        handleClose={onCloseHandler}
        handleApprove={submitSubscription}
        savetext={"Save"}
        cancelText={"Cancel"}
        handleReject={onCloseHandler}
        content={
          <>
            <Grid container spacing={2} px={1} mb={"12px"}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    className={classes.formLabel}
                  >
                    Select the iOS user subscription plan
                  </FormLabel>
                  <RadioGroup
                    aria-label="options"
                    name="options"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    {subOption.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default IosUsers;
