import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorHandler from "../../helper/errorHandler";
import ConfirmationModal from "../../common/ConfirmationModal";
import Loader from "../../common/Loader";
import Modal from "../../common/Modal";
import moment from "moment";
import CommonTextField from "../../common/commonTextField";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../common/noDataFound";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Card,
  CardMedia,
  IconButton,
  TablePagination,
  Switch,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  input: {
    display: "none",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

function AddOffers() {
  //Hooks
  const theme = useTheme();
  const classes = useStyles();

  //States
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); //this is for add offer modal
  const [index, setIndex] = useState(null); //this index is to delete the offer
  const [openEditModal, setOpenEditModal] = useState(false); //this is for edit offer modal
  const [id, setId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [image, setImage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [errors, setErrors] = useState({});
  const [dataArray, setDataArray] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [disabledDropzone , setDisabledDropzone] = useState(false)

  //Functions
  const renderDate = (data) => {
    const dateString = data;
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();

    setErrors({ wrong: "", empty: "", large: "" });
    formData.append("image", acceptedFiles[0]);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisabledDropzone(true)
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setImgLink(res?.data?.image);
        setDisabledDropzone(false)
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setErrors({ ...errors, wrong: "Only images are allowed" });
        } else if (error.code === "file-too-large") {
          setErrors({ ...errors, large: "File size should be less than 5 MB" });
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled:disabledDropzone
  });


  //Handler Functions

  //this is to make isShow false when add offer modal opens
  const handleIsShow = () => {
    open && setIsShow(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //on click event handlers
  const handleDeleteClick = (data) => {
    setConfirmationModal(true);
    setIndex(data._id);
  };

  const handleEditClick = (data) => {
    setOpenEditModal(true);
    setId(data._id);
    setImage(data.offerImage);
    setIsEdit(true);
    setIsShow(data.isShow)
  };

  const onCloseHandler = () => {
    setOpenEditModal(false);
    setOpen(false);
    setImage(null);
    setIsEdit(false);
  };

  const handleToggle = () => {
    setIsShow(!isShow)
  };

 
  //API Calling Functions

  const getOffers = () => {
    setTableLoader(true)
    ApiGet(`admin/getOffer?page=${page + 1}&limit=${rowsPerPage}`)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            setDataArray(res?.data?.findOffer);
            setTotalCount(res?.data?.count);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      }).finally(()=>setTableLoader(false));
  };

  const handleSubmit = () => {
    if (!image) {
      setErrors({ ...errors, empty: "Please select a file" });
    } else {
      setLoading(true);
      const data = { offerImage: imgLink, isShow: +isShow };
      ApiPost(`admin/addOffer`, data)
        .then((res) => {
          const error = ErrorHandler(res);
          if (error && res.message) {
            toast.success(res.message || "Success");
            setImage("");
            setIsShow("");
            setOpen(false);
            getOffers();
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const EditHandler = () => {
    if (!image) {
      setErrors({ ...errors, empty: "Please select a file" });
    } else {
      setLoading(true);
      const data = {
        id: id,
        offerImage: image,
        isShow: Number(isShow),
      };
      ApiPut("admin/editOffer", data)
        .then((res) => {
          const error = ErrorHandler(res);
          if (error) {
            toast.success(res.data.message || "Success");
            setImage("");
            setIsShow("");
            setOpen(false);
            getOffers();
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
          setOpenEditModal(false);
        });
    }
  };

  const deleteHandler = async () => {
    setLoading(true);
    ApiDelete(`admin/removeoffer?id=${index}`)
      .then(async (res) => {
        let error = await ErrorHandler(res);
        if (error) {
          toast.success(res?.data?.message || "offer deleted successfully");
          setConfirmationModal(false);
          getOffers();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //UseEffects

  useEffect(() => {
    handleIsShow();
  }, [open]);

  useEffect(() => {
    getOffers();
  }, [page, rowsPerPage]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Offers
          </Typography>
          <Button onClick={() => setOpen(true)} variant="contained">
            Add offer
          </Button>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sr No</StyledTableCell>
                <StyledTableCell align="center">Image</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Create Date</StyledTableCell>
                <StyledTableCell align="center">Update Date</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableLoader ? (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    <Loader />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {dataArray.length > 0 ? (
                    <>
                      {dataArray?.map((data, index) => (
                        <StyledTableRow key={data?._id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.offerImage ? (
                              <img
                                src={data.offerImage}
                                alt="img"
                                style={{ maxWidth: "100px" }}
                              />
                            ) : (
                              <p>No Image</p>
                            )}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.isShow ?  "Hide" : "Show"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {renderDate(data?.createdAt)}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {renderDate(data?.updatedAt)}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Box
                              display={"flex"}
                              gap={1}
                              justifyContent={"center"}
                            >
                              <EditIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  handleEditClick(data);
                                }}
                              />
                              <DeleteIcon
                                sx={{
                                  marginLeft: "10px",
                                  color: theme.palette.error.main,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  handleDeleteClick(data);
                                }}
                              />
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={12}>
                        <NoDataFound elevation={0} />
                      </StyledTableCell>
                    </StyledTableRow >
                  )}
                </>
              )}
            </TableBody>
            {totalCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Table>
        </TableContainer>

        <Modal
          open={openEditModal}
          handleClose={onCloseHandler}
          title={"Edit Offer"}
          savetext={"Submit"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={EditHandler}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1}>
                <Grid
                  item
                  xs={12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                    <>
                      <Typography sx={{ fontSize: "12px", color: "red", mb: 1 }}>
                        Note :- Please select .png .jpg and .jpeg images
                      </Typography>
                      <Card
                        style={{
                          padding: "20px",
                          border: `1px dashed ${theme.palette.primary.main}`,
                          textAlign: "center",
                          boxShadow: "none"
                        }}
                      >
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <div style={{ color: theme.palette.primary.main }}>
                            <CloudUploadIcon fontSize="large" />
                            {
                              disabledDropzone ? (
                                <p>Image is uploading...</p>
                              ) : (
                                <p> Drag and drop some images here or select only .jpg ,
                           .jpeg , .png images.</p>
                              )
                            }
                            <p>
                            </p>
                          </div>
                        )}
                      </Card>
                      {errors.empty && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.empty}
                        </Typography>
                      )}
                      {errors.wrong && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.wrong}
                        </Typography>
                      )}
                      {errors.large && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.large}
                        </Typography>
                      )}
                    </>
                </Grid>

                {image && (
                  <>
                    <Grid item xs={12}>
                      <Card style={{ position: "relative" }}>
                        <CardMedia
                          style={{ paddingTop: "56.25%" }}
                          image={image}
                          title="Uploaded Image"
                        >
                          <IconButton
                            style={{
                              position: "absolute",
                              top: 5,
                              right: 8,
                              zIndex: 1,
                            }}
                            onClick={() => {
                              setImage("");
                            }}
                          >
                            <CloseIcon
                              sx={{
                                background: "white",
                                color: "red",
                                borderRadius: "50%",
                              }}
                            />
                          </IconButton>
                        </CardMedia>
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch onChange={handleToggle} checked={!isShow}/>
                    }
                    label="Is Show"
                  />
                </Grid>
              </Grid>
            </>
          }
        />

        <Modal
          open={open}
          handleClose={onCloseHandler}
          title={"Add Offer"}
          savetext={"Submit"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={handleSubmit}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1}>
                <Grid
                  item
                  xs={12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                    <>
                      <Typography sx={{ fontSize: "12px", color: "red", mb: 1 }}>
                        Note :- Please select .png .jpg and .jpeg images
                      </Typography>
                      <Card
                        style={{
                          padding: "20px",
                          border: `1px dashed ${theme.palette.primary.main}`,
                          textAlign: "center",
                          boxShadow: "none"
                        }}
                      >
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <div style={{ color: theme.palette.primary.main }}>
                            <CloudUploadIcon fontSize="large" />
                            {
                              disabledDropzone ? (
                                <p>Image is uploading...</p>
                              ):(
                                <p> Drag 'n' drop some files here, or click to select
                              files</p>
                              )
                            }
                          </div>
                        )}
                      </Card>
                      {errors.empty && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.empty}
                        </Typography>
                      )}
                      {errors.wrong && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.wrong}
                        </Typography>
                      )}
                      {errors.large && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.large}
                        </Typography>
                      )}
                    </>
                </Grid>

                {image && (
                  <>
                    <Grid item xs={12}>
                      <Card style={{ position: "relative" }}>
                        <CardMedia
                          style={{ paddingTop: "56.25%" }}
                          image={image}
                          title="Uploaded Image"
                        >
                          <IconButton
                            style={{
                              position: "absolute",
                              top: 5,
                              right: 8,
                              zIndex: 1,
                            }}
                            onClick={() => {
                              setImage("");
                            }}
                          >
                            <CloseIcon
                              sx={{
                                background: "white",
                                color: "red",
                                borderRadius: "50%",
                              }}
                            />
                          </IconButton>
                        </CardMedia>
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch onChange={handleToggle}/>
                    }
                    label="Is Show"
                  />
                </Grid>
              </Grid>
            </>
          }
        />

        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={deleteHandler}
          headTitle={"Are you sure you want to delete this offer ?"}
          loading={loading}
        />
      </Box>
    </>
  );
}

export default AddOffers;
