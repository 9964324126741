import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import CommonTextField from "../../common/commonTextField";
import { ApiPost } from "../../helper/API/ApiData";
import { toast } from "react-toastify";
import UploadIcon from "@mui/icons-material/Upload";
import { ApiGet } from "../../helper/API/ApiData";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tab_main: {
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modal_text_field: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },
  },
  gradientDivider: {
    height: "2px",
    width: "60%",
    backgroundImage:
      "linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.1) 100%)",
    margin: "8px auto",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
  },
  imagePreview: {
    maxWidth: "100px",
    maxHeight: "100px",
    marginTop: "10px",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const AddGainerLooser = () => {
  const classes = useStyles();
  const theme = useTheme();
  const queryParams = new URLSearchParams(window.location.search);
  const edit = queryParams.get("edit");
  console.log(edit, "editValue");

  const [gainersData, setGainersData] = useState([
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
  ]);

  const [loosersData, setLooserData] = useState([
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
  ]);

  const [errors, setErrors] = useState({});
  const [errorsForLoosers, setErrorsForLoosers] = useState({});

  const validateFields = () => {
    let valid = true;
    let newErrors = {};

    gainersData.forEach((item, index) => {
      if (!item.name.trim()) {
        valid = false;
        newErrors[`name-${index}`] = "Stock Name is required";
      }
      if (!item.change.trim()) {
        valid = false;
        newErrors[`change-${index}`] = "Stock Growth is required";
      }
      if (!item.symbol) {
        valid = false;
        newErrors[`image-${index}`] = "Image is required";
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const validateFieldsForLoosers = () => {
    let valid = true;
    let newErrors = {};

    loosersData.forEach((item, index) => {
      if (!item.name.trim()) {
        valid = false;
        newErrors[`name-${index}`] = "Stock Name is required";
      }
      if (!item.change.trim()) {
        valid = false;
        newErrors[`change-${index}`] = "Stock Growth is required";
      }
      if (!item.symbol) {
        valid = false;
        newErrors[`image-${index}`] = "Image is required";
      }
    });

    setErrorsForLoosers(newErrors);
    return valid;
  };

  const onChangeHandler = (event, index) => {
    const { name, value } = event.target;
    let newData = [...gainersData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };

    setGainersData(newData);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${name}-${index}`]: "",
    }));
  };

  const onChangeHandlerForLoosers = (event, index) => {
    const { name, value } = event.target;
    let newData = [...loosersData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };
    setLooserData(newData);

    setErrorsForLoosers((prevErrors) => ({
      ...prevErrors,
      [`${name}-${index}`]: "",
    }));
  };

  const fileChangeHandler = (event, index, type) => {
    const file = event.target.files[0];
    const formData = new FormData();

    setErrors({ wrong: "", empty: "", large: "" });

    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrors({
          ...errors,
          large: "File is too large. Please select a file smaller than 5 MB.",
        });
        return;
      }

      if (!file.type.startsWith("image/")) {
        setErrors({ ...errors, wrong: "Please select a valid image file." });
        return;
      }

      formData.append("image", file);
      const headers = {
        isAuth: true,
        AdditionalParams: {},
        isJsonRequest: false,
        isMultiPart: true,
      };

      ApiPost("upload/image", formData, headers)
        .then((res) => {
          const symbol = res?.data?.image;
          if (symbol) {
            if (type === "gainer") {
              setGainersData((prevData) => {
                const newData = [...prevData];
                newData[index].symbol = symbol;
                return newData;
              });
            }
            if (type === "looser") {
              setLooserData((prevData) => {
                const newData = [...prevData];
                newData[index].symbol = symbol;
                return newData;
              });
            }
            toast.success("Image Successfully Uploaded");
          } else {
            toast.error("Failed to retrieve the image URL from the response");
          }
        })
        .catch((err) => {
          toast.error("Failed to upload image");
        });
    } else {
      setErrors({ ...errors, empty: "No file selected." });
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // const paylaod = {
    //   "gainers": gainersData,
    // }
    const payload = {
      "gainers" : gainersData,
      "losers" : loosersData
    }
    if (validateFields()) {
      ApiPost("admin/addStocks", payload)
        .then((res) => {
          console.log(res, "Res");
          toast.success("toast added successfully");
        })
        .catch((err) => {
          console.log(err, "Res");
          toast.error("error adding data");
        });
    }
  };

  // const handleSubmitForLoosers = (e) => {
  //   e.preventDefault();
  //   if (validateFieldsForLoosers()) {
  //     // const payload = {
  //     // "losers":loosersData
  //     // }

  //     const payload = {
  //       "gainers" : gainersData,
  //       "losers" : loosersData
  //     }
      
  //     ApiPost("admin/addStocks", payload)
  //       .then((res) => {
  //         console.log(res, "Res");
  //         toast.success("toast added successfully");
  //       })
  //       .catch((err) => {
  //         console.log(err, "Res");
  //         toast.error("error adding data");
  //       });
  //   }
  // };

  return (
    <>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          Add Top Gainers and Loosers
        </Typography>
      </Box>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6} xl={6}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Add Top Gainers
          </Typography>
          {/* <form onSubmit={handleSubmit}> */}
            <Grid container spacing={2}>
              {gainersData &&
                gainersData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>Stock {index + 1} Data</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Name"}
                        placeholder={"Enter Stock Name"}
                        name="name"
                        value={item.name}
                        onChange={(e) => onChangeHandler(e, index)}
                      />
                      <Typography color="error">
                        {errors[`name-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Growth"}
                        placeholder={"Enter Stock Growth (In percentage)"}
                        name="change"
                        value={item.change}
                        onChange={(e) => onChangeHandler(e, index)}
                      />
                      <Typography color="error">
                        {errors[`change-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangeHandler(e, index, "gainer")}
                        style={{ display: "none" }}
                        id={`file-input-${index + 2}`}
                        name="gainer"
                      />
                      <label
                        htmlFor={`file-input-${index + 2}`}
                        className={classes.uploadButton}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<UploadIcon />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {errors.large && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.large}
                        </Typography>
                      )}
                      {errors.wrong && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.wrong}
                        </Typography>
                      )}
                      {errors.empty && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.empty}
                        </Typography>
                      )}
                      {errors[`image-${index}`] && (
                        <Typography color="error" className={classes.errorText}>
                          {errors[`image-${index}`]}
                        </Typography>
                      )}
                      {item.symbol && (
                        <img
                          src={item.symbol}
                          alt={`Stock ${index + 1}`}
                          className={classes.imagePreview}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
            {/* <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "1rem auto",
                width: "fit-content",
                display: "block",
              }}
            >
              Submit
            </Button> */}
          {/* </form> */}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Add Top Loosers
          </Typography>

          {/* <form onSubmit={handleSubmitForLoosers}> */}
            <Grid container spacing={2}>
              {loosersData &&
                loosersData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>Stock {index + 1} Data</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Name"}
                        placeholder={"Enter Stock Name"}
                        name="name"
                        value={item.name}
                        onChange={(e) => onChangeHandlerForLoosers(e, index)}
                      />
                      <Typography color="error">
                        {errorsForLoosers[`name-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Growth"}
                        placeholder={"Enter Stock Growth (In percentage)"}
                        name="change"
                        value={item.change}
                        onChange={(e) => onChangeHandlerForLoosers(e, index)}
                      />
                      <Typography color="error">
                        {errorsForLoosers[`change-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangeHandler(e, index, "looser")}
                        style={{ display: "none" }}
                        id={`file-input-looser-${index + 1}`}
                        name="losser"
                      />
                      <label
                        htmlFor={`file-input-looser-${index + 1}`}
                        className={classes.uploadButton}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<UploadIcon />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {errorsForLoosers.large && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.large}
                        </Typography>
                      )}
                      {errorsForLoosers.wrong && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.wrong}
                        </Typography>
                      )}
                      {errorsForLoosers.empty && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.empty}
                        </Typography>
                      )}
                      {errorsForLoosers[`image-${index}`] && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers[`image-${index}`]}
                        </Typography>
                      )}
                      {item.symbol && (
                        <img
                          src={item.symbol}
                          alt={`Stock ${index + 1}`}
                          className={classes.imagePreview}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
            {/* <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "1rem auto",
                width: "fit-content",
                display: "block",
              }}
            >
              Submit
            </Button> */}
          {/* </form> */}
        </Grid>
          <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "2rem auto",
                width: "fit-content",
                display: "block",
                minWidth : "250px"
              }}
              onClick={handleSubmit}
            >
              Add Top Looser & Gainers
            </Button>
      </Grid>
    </>
  );
};

export default AddGainerLooser;
// const AddGainerLooser = () => {
//   const theme = useTheme();

//   // Separate states for top gainers and top loosers
//   const [gainers, setGainers] = useState([
//     { id: 1, name: "", change: "", image: "" },
//   ]);

//   const [loosers, setLoosers] = useState([
//     { id: 1, name: "", change: "", image: "" },
//   ]);

//   // Functions to handle gainers
//   const addGainer = () => {
//     const newGainer = { id: gainers.length + 1, name: "", change: "", image: "" };
//     setGainers([...gainers, newGainer]);
//   };

//   const removeGainer = (id) => {
//     const updatedGainers = gainers.filter((gainer) => gainer.id !== id);
//     setGainers(updatedGainers);
//   };

//   const handleGainerInputChange = async (id, e) => {
//     const { name, value } = e.target;

//     if (name === "image") {
//       const file = e.target.files[0];
//       if (file) {
//         const formData = new FormData();
//         formData.append("image", file);

//         const headers = {
//           isAuth: true,
//           AdditionalParams: {},
//           isJsonRequest: false,
//           isMultiPart: true,
//         };

//         try {
//           const res = await ApiPost("upload/image", formData, headers);
//           const imageUrl = res.data.image;

//           const updatedGainers = gainers.map((gainer) =>
//             gainer.id === id ? { ...gainer, image: imageUrl } : gainer
//           );
//           setGainers(updatedGainers);
//         } catch (err) {
//           toast.error("Failed to upload image");
//         }
//       }
//     } else {
//       const updatedGainers = gainers.map((gainer) =>
//         gainer.id === id ? { ...gainer, [name]: value } : gainer
//       );
//       setGainers(updatedGainers);
//     }
//   };

//   // Functions to handle loosers
//   const addLooser = () => {
//     const newLooser = { id: loosers.length + 1, name: "", change: "", image: "" };
//     setLoosers([...loosers, newLooser]);
//   };

//   const removeLooser = (id) => {
//     const updatedLoosers = loosers.filter((looser) => looser.id !== id);
//     setLoosers(updatedLoosers);
//   };

//   const handleLooserInputChange = async (id, e) => {
//     const { name, value } = e.target;

//     if (name === "image") {
//       const file = e.target.files[0];
//       if (file) {
//         const formData = new FormData();
//         formData.append("image", file);

//         const headers = {
//           isAuth: true,
//           AdditionalParams: {},
//           isJsonRequest: false,
//           isMultiPart: true,
//         };

//         try {
//           const res = await ApiPost("upload/image", formData, headers);
//           const imageUrl = res.data.image;

//           const updatedLoosers = loosers.map((looser) =>
//             looser.id === id ? { ...looser, image: imageUrl } : looser
//           );
//           setLoosers(updatedLoosers);
//         } catch (err) {
//           toast.error("Failed to upload image");
//         }
//       }
//     } else {
//       const updatedLoosers = loosers.map((looser) =>
//         looser.id === id ? { ...looser, [name]: value } : looser
//       );
//       setLoosers(updatedLoosers);
//     }
//   };

//   useEffect(() => {
//     console.log("Gainers:", gainers);
//     console.log("Loosers:", loosers);
//   }, [gainers, loosers]);

//   return (
//     <Box display={"flex"} flexDirection={"column"} gap={2}>
//       <Typography
//         variant="h6"
//         sx={{ fontWeight: "600", color: theme.palette.primary.main }}
//       >
//         Add Top Gainers And Loosers
//       </Typography>

//       {/* Gainers Section */}
//       <Box display={'flex'} flexDirection={'column'} gap={2}>

//         <Typography
//           variant="h6"
//           sx={{ fontWeight: "600", color: theme.palette.primary.main }}
//         >
//           Add Top Gainers
//         </Typography>

//         <Box>
//           {gainers.map((gainer) => (
//             <Grid
//               container
//               display={"flex"}
//               alignItems={"center"}
//               gap={2}
//               key={gainer.id}
//             >
//               <Grid item xl={3}>
//                 <CommonTextField
//                   name={"name"}
//                   text={`Stock Name ${gainer.id}`}
//                   onChange={(e) => handleGainerInputChange(gainer.id, e)}
//                 />
//               </Grid>
//               <Grid item xl={3}>
//                 <CommonTextField
//                   name={"change"}
//                   text={"Stock Growth"}
//                   onChange={(e) => handleGainerInputChange(gainer.id, e)}
//                 />
//               </Grid>
//               <Grid item xl={3} mt={3.5}>
//                 <Box
//                   sx={{ display: "flex", alignItems: "center", gap: "10px" }}
//                 >
//                   <input
//                     name="image"
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => handleGainerInputChange(gainer.id, e)}
//                     id={`image-upload-gainer-${gainer.id}`}
//                     style={{ display: "none" }} 
//                   />
//                   <label htmlFor={`image-upload-gainer-${gainer.id}`}>
//                     <Button
//                       variant="contained"
//                       component="span"
//                       startIcon={<UploadIcon />}
//                     >
//                       Upload Image
//                     </Button>
//                   </label>
//                   {gainer.image && (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         height: "100px",
//                         width: "100px",
//                         overflow: "hidden",
//                         border: "1px solid #ccc",
//                         borderRadius: "4px",
//                       }}
//                     >
//                       <img
//                         src={gainer.image}
//                         alt={`Preview ${gainer.id}`}
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                           objectFit: "cover",
//                         }}
//                       />
//                     </Box>
//                   )}
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => removeGainer(gainer.id)}
//                     startIcon={<CloseIcon />}
//                   >
//                     Remove
//                   </Button>
//                 </Box>
//               </Grid>
//             </Grid>
//           ))}
//         </Box>
//         <Stack spacing={3}>
//           <Button
//             variant="contained"
//             style={{ maxWidth: "200px" }}
//             onClick={addGainer}
//           >
//             Add More Gainers
//           </Button>
//           <Button variant="contained">Submit Top Gainers</Button>
//         </Stack>
//       </Box>

//       {/* Loosers Section */}
//       <Box display={'flex'} flexDirection={'column'} gap={2}>
//         <Typography
//           variant="h6"
//           sx={{ fontWeight: "600", color: theme.palette.primary.main }}
//         >
//           Add Top Loosers
//         </Typography>

//         <Box>
//           {loosers.map((looser) => (
//             <Grid
//               container
//               display={"flex"}
//               alignItems={"center"}
//               gap={2}
//               key={looser.id}
//             >
//               <Grid item xl={3}>
//                 <CommonTextField
//                   name={"name"}
//                   text={`Stock Name ${looser.id}`}
//                   onChange={(e) => handleLooserInputChange(looser.id, e)}
//                 />
//               </Grid>
//               <Grid item xl={3}>
//                 <CommonTextField
//                   name={"change"}
//                   text={"Stock Loss"}
//                   onChange={(e) => handleLooserInputChange(looser.id, e)}
//                 />
//               </Grid>
//               <Grid item xl={3} mt={3.5}>
//                 <Box
//                   sx={{ display: "flex", alignItems: "center", gap: "10px" }}
//                 >
//                   <input
//                     name="image"
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => handleLooserInputChange(looser.id, e)}
//                     id={`image-upload-looser-${looser.id}`}
//                     style={{ display: "none" }} 
//                   />
//                   <label htmlFor={`image-upload-looser-${looser.id}`}>
//                     <Button
//                       variant="contained"
//                       component="span"
//                       startIcon={<UploadIcon />}
//                     >
//                       Upload Image
//                     </Button>
//                   </label>
//                   {looser.image && (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         height: "100px",
//                         width: "100px",
//                         overflow: "hidden",
//                         border: "1px solid #ccc",
//                         borderRadius: "4px",
//                       }}
//                     >
//                       <img
//                         src={looser.image}
//                         alt={`Preview ${looser.id}`}
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                           objectFit: "cover",
//                         }}
//                       />
//                     </Box>
//                   )}
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => removeLooser(looser.id)}
//                     startIcon={<CloseIcon />}
//                   >
//                     Remove
//                   </Button>
//                 </Box>
//               </Grid>
//             </Grid>
//           ))}
//         </Box>
//         <Stack spacing={3}>
//           <Button
//             variant="contained"
//             style={{ maxWidth: "200px" }}
//             onClick={addLooser}
//           >
//             Add More Loosers
//           </Button>
//           <Button variant="contained">Submit Top Loosers</Button>
//         </Stack>
//       </Box>
//     </Box>
//   );
// };

// export default AddGainerLooser;
