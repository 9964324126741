import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Avatar, Typography, TablePagination, TextField, Button, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, } from "@mui/material";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";
import NoDataFound from "../../common/noDataFound";
import ErrorHandler from "../../helper/errorHandler";
import { toast } from "react-toastify";
import Modal from "../../common/Modal";
import { sentNotificationEnum } from "../../helper/enum";
import { debounce } from "../../helper/utils";
import { getApp } from "firebase/app";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontFamily: "Poppins",
        padding: 6,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "5px",
        color: theme.palette.bgBlack.main
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#F1F4F8",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles((theme) => ({
    header_main: {
        marginBottom: "10px",
        display: "flex",
        // justifyContent: "space-between",
        justifyContent : 'flex-end',
        alignItems: "center",
        gap : 4
    },
    contained_button: {
        background: theme.palette.primary.main,
        textTransform: "none !important",
        fontWeight: "700 !important",
    },
    search_input_field: {
        "& label.Mui-focused": {
            color: theme.palette.primary.main,
        },
        "& label": {
            color: theme.palette.primary.main,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            },
            width: 250,
            height: 50,
        },
    },
    placeholder: {
        '&::placeholder': {
            fontWeight: 600
        },
    },
}));

const PromotionNotification = () => {
    const theme = useTheme()
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [promotionNotificationDetails, setPromotionNotificationDetails] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState({ sentTo: 0 }); 
    const [errors, setErrors] = useState({});

    console.log(data, promotionNotificationDetails, "dataaaaaa")
    const promotionNotificationEnum = [
        { label: "All", val: 0, color: theme.palette.bgLightGreen.main },
        { label: "Subscriber", val: 1, color: theme.palette.bgBlue.main },
        { label: "Non Subscriber", val: 2, color: theme.palette.error.main },
    ]
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const validate = () => {
        let error = {};
        let flag = true;
        if (!data?.title) {
            error['title'] = "* Please enter  title";
            flag = false;
        }
        if (!data?.description) {
            error['description'] = "* Please enter  description";
            flag = false;
        }
        setErrors(error);
        return flag;
    };

    const handleInput = (e) => {
        const { value, name } = e.target
        setData({ ...data, [name]: value });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSubmit = () => {
        if (validate()) {
            const body = {
                title: data?.title,
                description: data?.description,
                sentTo: +data?.sentTo
            };
            ApiPost("admin/promotionNotificationCreate", body)
                .then((response) => {
                    const error = ErrorHandler(response)
                    if (error) {
                        toast.success(response.message);
                        setOpen(false)
                        setData({sentTo: 0})
                        _getPromotionNotification();
                    }
                })
                .catch((err) => { toast.error(err?.message); })
                .finally(() => { setLoading(false); });
        }
    }
    const handleSearch = debounce((searchValue) => {
        setSearchValue(searchValue);
        setPage(0);
    }, 500);

    const _getPromotionNotification = async () => {
        setLoading(true);
        let body = {
            page: page + 1,
            limit: rowsPerPage,
        }
        if (searchValue) {
            body.search = searchValue
        }
        await ApiPost(`admin/promotionNotification`, body)
            .then((res) => {
                console.log(res, "response")
                const error = ErrorHandler(res)
                if (error) {
                    setPromotionNotificationDetails(res?.data);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            }).finally(() => {
                setLoading(false)
            })
    }


    useEffect(() => {
        _getPromotionNotification();
    }, [page, rowsPerPage, searchValue]);

    return (
        <Box>
            <Box className={classes.header_main}>
                {/* <Typography variant="h6" sx={{ fontWeight: "600", color: theme.palette.primary.main }}>Promotion Notification</Typography> */}
                {/* <Box display={'flex'} gap={2} alignItems={'center'} > */}
                    <CommonTextField
                        fontWeight={600}
                        placeholder={"Search Notification..."}
                        type='text'
                        name='search'
                        onChange={(e) => handleSearch(e.target.value)}
                        width={250}
                        inputProps={{ className: classes.placeholder }}
                    />
                    <Button onClick={() => setOpen(true)} variant="contained"                    >
                        Add Promotion Notification
                    </Button>
                {/* </Box> */}
            </Box>
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">No.</StyledTableCell>
                            <StyledTableCell align="center">Title</StyledTableCell>
                            <StyledTableCell align="center">Description</StyledTableCell>
                            <StyledTableCell align="center"> Sent To</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <StyledTableCell colSpan={8}>
                                    <Loader />
                                </StyledTableCell>
                            </TableRow>
                        ) : (
                            <>
                                {promotionNotificationDetails?.response?.length > 0 ? promotionNotificationDetails?.response?.map((val, index) => {
                                    const getSentToLabel = (val) => {
                                        const object = promotionNotificationEnum?.find(item => item.val == val);
                                        return object
                                    };
                                    return (
                                        <StyledTableRow key={val?._id}>
                                            <StyledTableCell align="center">{(page * rowsPerPage) + index + 1}</StyledTableCell>
                                            <StyledTableCell align="center"> {val?.title} </StyledTableCell>
                                            <StyledTableCell align="center">{val?.description}</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box display={'flex'} p={0.5} width={'140px'} borderRadius={2} justifyContent={'center'} color={'white'} bgcolor={getSentToLabel(val?.sentTo)?.color}>
                                                    {getSentToLabel(val?.sentTo)?.label}
                                                </Box>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                }) : <StyledTableRow>
                                    <StyledTableCell colSpan={12}>
                                        <NoDataFound elevation={0} />
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </>
                        )}
                    </TableBody>
                </Table>
                {promotionNotificationDetails?.count > 0 && <TablePagination
                    component="div"
                    count={promotionNotificationDetails?.count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                />}
            </TableContainer>

            {open && <Modal
                open={open}
                handleClose={() => { setOpen(false); setData({sentTo: 0}); setErrors({}) }}
                title={"Add Promotion Notification Details"}
                savetext={"Sent"}
                cancelText={"Cancel"}
                handleReject={() => { setOpen(false); setData({sentTo : 0}); setErrors({}) }}
                handleApprove={handleSubmit}
                loading={loading}
                content={
                    <>
                        <Grid container spacing={2} px={1} mb={'12px'}>
                            <Grid item xs={12}>
                                <CommonTextField
                                    fontWeight={500}
                                    text={"Title"}
                                    placeholder={"Enter title"}
                                    type="text"
                                    name="title"
                                    value={data?.title}
                                    onChange={(e) => handleInput(e)}
                                    inputProps={{ className: classes.placeholder }}
                                />
                                {!data?.title && <Typography color="error" fontSize={"12px"} style={{ marginTop: "2px" }}>{errors.title}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <CommonTextField
                                    fontWeight={500}
                                    text={"Description"}
                                    placeholder={"Enter description"}
                                    type="text"
                                    name="description"
                                    value={data?.description}
                                    onChange={(e) => handleInput(e)}
                                    inputProps={{ className: classes.placeholder }}
                                    rows={4}
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12} mt={8}>
                                {!data?.description && <Typography color="error" fontSize={"12px"} style={{marginTop:'2px' }}>{errors.description}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel style={{ fontSize: '14px', color: theme?.palette?.bgBlack?.main, fontWeight: 500 }} >Sent To</FormLabel>
                                    <RadioGroup row name="sentTo" onChange={(e) => handleInput(e)} value={data.sentTo}>
                                        <FormControlLabel value={sentNotificationEnum.all} control={<Radio name="sentTo" defaultChecked={true}/>} label="All" />
                                        <FormControlLabel value={sentNotificationEnum.subscribedUser} control={<Radio name="sentTo" />} label="Subscribed User" />
                                        <FormControlLabel value={sentNotificationEnum.non_subscribedUser} control={<Radio name="sentTo" />} label="Non Subscribed User" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>
                }
            />}
        </Box>
    )
}

export default PromotionNotification