import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@mui/styles";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ApiDelete, ApiGet } from "../../helper/API/ApiData";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../common/Modal";
import CommonTextField from "../../common/commonTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../../common/LoadingButton";
import CustomDialog from "../../common/CustomDialog";
import { ApiPost } from "../../helper/API/ApiData";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorHandler from "../../helper/errorHandler";
import ConfirmationModal from "../../common/ConfirmationModal";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  input: {
    display: "none",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none !important",
  },
  cardContent: {
    paddingRight: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    // padding: theme.spacing(1),
    paddingTop: "0 !important",
  },
  nameText: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  dropZone: {
    padding: theme.spacing(2),
    border: `2px dashed ${theme.palette.primary.main}`,
    textAlign: "center",
    cursor: "pointer",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));


const GainerLooser = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState("0");
  const [gainers, setGainers] = useState([]);
  const [loosers, setLoosers] = useState([]);
  const [data, setData] = useState({
    image: "",
    name: "",
    percentage: "",
    description: "",
  });
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [error, setError] = useState({
    image: "",
    name: "",
    percentage: "",
    description: "",
  });
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandingName, setBrandingName] = useState("");
  const [croppedLogo, setCroppedLogo] = useState(null);
  const [isEmpty, setIsEmpty] = useState("");
  const [isLarge, setIsLarge] = useState("");
  const [isWrong, setIsWrong] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [id, setId] = useState(null);

  const handleChange = (e, value) => {
    setTabValue(value);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    setData({
      image: "",
      name: "",
      percentage: "",
      description: "",
    });
    setError({
      image: "",
      name: "",
      percentage: "",
      description: "",
    });
  };
  function clear() {
    setError({
      image: "",
      name: "",
      percentage: "",
      description: "",
    });
    setData({
      image: "",
      name: "",
      percentage: "",
      description: "",
    });
    setOpen(false);
    setId("");
    setIsEdit(false);
  }

  const onAddHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const body = {
      name: data.name || "",
      symbol: data.image || "",
      change: data.percentage || "",
      type: tabValue == "0" ? 1 : 2,
    };
    const url = `admin/topGainersLosers?type=${tabValue == "0" ? 1 : 2}`;
    ApiPost(url, body)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          toast.success(res.message);
          clear();
          if (tabValue == "0") {
            getTopGainersData();
          } else {
            getTopLoosersData();
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };

  const onEditHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    let url = `admin/updatetopGainersLosers?type=${tabValue == "0" ? 1 : 2}`;
    const body = {
      _id: id,
      name: data.name || "",
      symbol: data.image || "",
      change: data.percentage || "",
    };
    ApiPost(url, body)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          if (tabValue == "0") {
            getTopGainersData();
          } else {
            getTopLoosersData();
          }
          clear();
		  toast.success(res.message)
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const getTopGainersData = () => {
    setLoading(true);
    ApiGet(`admin/gettopGainersLosers?type=${1}`)
      .then((res) => {
        setGainers(res?.data?.response);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };
  const getTopLoosersData = () => {
    setLoading(true);
    ApiGet(`admin/gettopGainersLosers?type=${2}`)
      .then((res) => {
        setLoosers(res?.data?.response);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };
  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true);
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name);
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };
  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();

    setIsWrong("");
    setIsEmpty("");
    setIsLarge("");
    setShowCropper(false);
    setUploadedLogo(null);

    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisableDropzone(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setData((prev) => ({ ...prev, image: res?.data?.image }));
        setDisableDropzone(false);
        setError({
          ...error,
          image: "",
        });
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setShowLogoPicker(false));
  };

  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setIsWrong(`Only images are allowed`);
        } else if (error.code === "file-too-large") {
          setIsLarge(`File size should be less than 5 MB`);
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disableDropzone,
  });

  const onDeleteHandler = (item) => {
    setLoading(true);
    ApiDelete(`admin/deletetopGainersLosers/${id}`)
      .then((res) => {
        const checkError = ErrorHandler(res);
        if (checkError) {
          toast.success(res.data.message);
          setConfirmationModal(false);
          clear();
          if (tabValue == "0") {
            getTopGainersData();
          } else {
            getTopLoosersData();
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const onDeleteClick = (item) => {
    setConfirmationModal(true);
    setId(item._id);
  };

  const handleEdit = (item) => {
    setOpen(true);
    setIsEdit(true);
    setId(item._id);

    setData({
      name: item.name,
      image: item.symbol,
      percentage: item.change,
    });
  };

  const validation = () => {
    const errorObj = {};
    let flag = false;
    if (!data.name) {
      errorObj.name = "Please enter the stock name!";
      flag = true;
    }
    if (!data.percentage) {
      errorObj.percentage = "Please enter the percentage!";
      flag = true;
    }
    if (!data.image) {
      errorObj.image = "Please select the image!";
      flag = true
    }
    setError(errorObj);
    return flag;
  };

  useEffect(() => {
    if (tabValue == "0") getTopGainersData();
    else getTopLoosersData();
  }, [tabValue]);

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Top Gainers and Loosers
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button variant="contained" onClick={() => setOpen(true)}>
              {tabValue == "0" ? "Add Top Gainers" : "Add Top Loosers"}
            </Button>
          </Box>
        </Box>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabValue}>
            <Box
              className={classes.tab_main}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  value={"0"}
                  sx={{ fontWeight: "600" }}
                  label="Top Gainers"
                />
                <Tab
                  value={"1"}
                  sx={{ fontWeight: "600" }}
                  label="Top Loosers"
                />
              </TabList>
            </Box>

            <TabPanel sx={{ paddingX: 0, paddingY: 1 }} value="0">
              {loading ? (
                <Loader />
              ) : gainers && gainers.length > 0 ? (
                <>
                  <Grid container spacing={3}>
                    {gainers &&
                      gainers.length > 0 &&
                      gainers.map((item, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={3} mt={1}>
                            <Card>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.symbol}
                                  alt="Full Image"
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "auto",
                                    minHeight: "140px",
                                    maxHeight: "140px",
                                  }}
                                />
                              </Box>
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  component="div"
                                  className={classes.cardContent}
                                >
                                  <span className={classes.nameText}>
                                    Stock name:
                                  </span>
                                  {`${item?.name}`}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  component="div"
                                  className={classes.cardContent}
                                >
                                  <span className={classes.nameText}>
                                    Increase in percentage:
                                  </span>
                                  {item.change ?? "-"}
                                </Typography>
                              </CardContent>
                              <Box display={"flex"} justifyContent={"flex-end"}>
                                <CardActions>
                                  <IconButton
                                    color="primary"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton color="error">
                                    <DeleteIcon
                                      onClick={() => onDeleteClick(item)}
                                    />
                                  </IconButton>
                                </CardActions>
                              </Box>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              ) : (
                <Box width={"100%"} marginTop={5} padding={2}>
                  <NoDataFound />
                </Box>
              )}
            </TabPanel>

            <TabPanel sx={{ paddingX: 0, paddingY: 1 }} value="1">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {loosers && loosers.length > 0 ? (
                    <>
                      <Grid container spacing={3}>
                        {loosers &&
                          loosers.length > 0 &&
                          loosers.map((item, index) => {
                            return (
                              <Grid item xs={12} sm={6} md={4} lg={3} mt={1}>
                                <Card>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "auto",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={item.symbol}
                                      alt="Full Image"
                                      style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "auto",
                                        minHeight: "140px",
                                        maxHeight: "140px",
                                      }}
                                    />
                                  </Box>
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      component="div"
                                      className={classes.cardContent}
                                    >
                                      <span className={classes.nameText}>
                                        Stock name:
                                      </span>
                                      {`${item?.name}`}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      component="div"
                                      className={classes.cardContent}
                                    >
                                      <span className={classes.nameText}>
                                        Decrease in percentage:
                                      </span>
                                      {item.change ?? "-"}
                                    </Typography>
                                  </CardContent>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                  >
                                    <CardActions>
                                      <IconButton
                                        color="primary"
                                        onClick={() => handleEdit(item)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton color="error">
                                        <DeleteIcon
                                          onClick={() => onDeleteClick(item)}
                                        />
                                      </IconButton>
                                    </CardActions>
                                  </Box>
                                </Card>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  ) : (
                    <Box width={"100%"} marginTop={5} padding={2}>
                      <NoDataFound />
                    </Box>
                  )}
                </>
              )}
            </TabPanel>
          </TabContext>
        </Box>
        <Modal
          open={open}
          handleClose={onCloseHandler}
          title={
            isEdit
              ? tabValue == "0"
                ? "Edit Top Gainer"
                : "Edit Top Looser"
              : tabValue == "0"
              ? "Add Top Gainer"
              : "Add Top Looser"
          }
          savetext={isEdit ? "Update" : "Save"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={isEdit ? onEditHandler : onAddHandler}
          loading={loading}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "12px", color: "red"}}>
                    Note :- Please select .png .jpg and .jpeg images
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={data.image ? 9 : 12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  <>
                    <Card
                      style={{
                        padding: "20px",
                        border: `1px dashed ${theme.palette.primary.main}`,
                        textAlign: "center",
                        boxShadow: "none",
                      }}
                    >
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div style={{ color: theme.palette.primary.main }}>
                          <CloudUploadIcon fontSize="large" />
                          {disableDropzone ? (
                            <p>Image is uploading...</p>
                          ) : (
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          )}
                        </div>
                      )}
                    </Card>
                    {isEmpty && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isEmpty}
                      </Typography>
                    )}
                    {isWrong && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isWrong}
                      </Typography>
                    )}
                    {isLarge && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {isLarge}
                      </Typography>
                    )}
                    {error.image && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {error.image}
                      </Typography>
                    )}
                  </>
                </Grid>
                {showLogoPicker && (
                  <CustomDialog
                    isOpen={showLogoPicker}
                    handleClose={() => {
                      setShowLogoPicker(false);
                      setShowCropper(false);
                      setUploadedLogo(null);
                    }}
                  >
                    <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                    <DialogContent
                      sx={{
                        width: {
                          lg: "700px",
                          md: "700px",
                          sm: "600px",
                          xs: "400px",
                        },
                      }}
                      dividers
                    >
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <div style={{ width: "100%", position: "relative" }}>
                            <Cropper
                              style={{ height: 300, width: "100%" }}
                              src={uploadedLogo}
                              viewMode={0}
                              background={false}
                              responsive={false}
                              minCropBoxHeight={20}
                              minCropBoxWidth={10}
                              dragMode="move"
                              autoCropArea={1}
                              checkOrientation={false}
                              cropBoxResizable={true}
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={false}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display={"flex"} justifyContent={"end"} gap={1}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setShowCropper(false);
                                setUploadedLogo(null);
                                setInitialLogo(null);
                                setShowLogoPicker(false);
                              }}
                              color="error"
                              sx={{ borderRadius: 5 }}
                            >
                              Cancel
                            </Button>
                            <LoadingButton
                              color="primary"
                              variant="contained"
                              sx={{ borderRadius: 5 }}
                              disableElevation
                              onClickHandler={() => {
                                getCropData();
                              }}
                              loading={disableDropzone}
                              text="Crop"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </CustomDialog>
                )}
                {data.image && (
                  <>
                    <Grid item xs={3} borderRadius={"5px"}>
                      <Box
                        sx={{
                          position: "relative",
                          background: `url(${data.image})`,
                          height: "130px",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          overflow: "hidden",
                          "&:hover .hoverButtons": {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box
                          className="hoverButtons"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Box
                            onClick={() => window.open(data.image, "_blank")}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            bgcolor={theme.palette.bgWhite.main}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "42%",
                              right: "42%",
                              cursor: "pointer",
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: theme.palette.bgBlack.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              setData({
                                ...data,
                                image: "",
                              });
                            }}
                            height={"25px"}
                            width={"25px"}
                            borderRadius={"50%"}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: theme.palette.error.main,
                                fontSize: "20px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Stock Name"}
                    placeholder={"Enter the gainer stock name"}
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      setError({
                        ...error,
                        title: "",
                      });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.name && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {error.name}
                    </Typography>
                  )}

                  {error.title && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.title}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Percentage"}
                    placeholder={"Enter the percentage"}
                    type="number"
                    name="percentage"
                    value={data.percentage}
                    onChange={(e) => {
                      setData({ ...data, percentage: e.target.value });
                      setError({ ...error, percentage: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.percentage && (
                    <Typography color="error" fontSize={"12px"}>
                      {error.percentage}
                    </Typography>
                  )}
                </Grid>
                {/* <Grid item xs={12} mb={2}>
                  <CommonTextField
                    fontWeight={400}
                    text={"Description"}
                    placeholder={"Enter Description"}
                    type="text"
                    name="description"
                    value={data.description}
                    multiline
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      setError({ ...error, description: "" });
                    }}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {error.description && (
                    <Typography color="error" fontSize={"12px"} mt={2}>
                      {error.description}
                    </Typography>
                  )}
                </Grid> */}
              </Grid>
            </>
          }
        />
      </Box>
      <ConfirmationModal
        open={confirmationModal}
        handleClose={() => setConfirmationModal(false)}
        onDeleteHandler={onDeleteHandler}
        headTitle={"Are you sure you want to delete this stock?"}
      />
    </>
  );
};

export default GainerLooser;
