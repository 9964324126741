import {
  FETCH_TIPS_FAILURE,
  FETCH_TIPS_SUCCESS,
  FETCH_TIPS_REQUEST,
} from "./tipsContants";
import { ApiGet ,ApiDelete , ApiPost , ApiPut} from "../../helper/API/ApiData";
export const fetchTipsRequest = () => ({
  type: FETCH_TIPS_REQUEST,
});

export const fetchTipsSuccess = (tips) => ({
  type: FETCH_TIPS_SUCCESS,
  payload: tips,
});

export const fetchTipsFailure = (error) => ({
  type: FETCH_TIPS_FAILURE,
  payload: error,
});

export const fetchTips = (page , limit , tab) => {
  return async (dispatch) => {
    try{
      const res =  await ApiGet(`admin/getTips?page=${page}&limit=${limit}&status=${tab}`)
      dispatch(fetchTipsSuccess(res.data))
      return res
    }
    catch(err){
      dispatch(fetchTipsFailure(err?.message))
      throw err
    }
  };
};


export const deleteTip = (e , id)=>{
  return async (dispatch)=>{
    try{
      return await ApiDelete(`admin/removeTips?id=${id}`)
    }catch(err){
      throw err
    }
  }
}

export const fetchSingleUserData = (id)=>{
  return async ()=>{
    try{
      return await ApiGet(`admin/getSingleTips/${id}`)
    }catch(err){
      throw(err)
    }
  }
}


export const addTip = (tipData)=>{
  return async ()=>{
    try{
      return await ApiPost("admin/addTips", tipData)
    }catch(err){
      throw(err)
    }
  }
}

export const editTip = (data)=>{
  return async ()=>{
    try{
      return await ApiPut('admin/editTips', data)
    }catch(err){
      throw(err)
    }
  }
}
// const getByIdUserManagment = (body) => {
//   return async (dispatch) => {
//     try {
//       const res = await ApiGetNoAuth(api.getByIdUserManagment + `/${body}`, body);
//       dispatch({ type: "getByIdChallenge/fulfilled", payload: res.data });
//       return res.data;
//     } catch (err) {
//       dispatch({ type: "getByIdChallenge/rejected", error: err?.message });
//       throw err;
//     }
//   };
// };
