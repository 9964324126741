import React, { useEffect, useRef, useState } from "react";
import { ApiGet } from "../../helper/API/ApiData";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Tab,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Button,
  Typography,
  TablePagination,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles, useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/en-in";
import { ToastContainer, toast } from "react-toastify";
import { fetchTips, deleteTip } from "../../redux/tips/tipsActions";
import Loader from "../../common/Loader";
import ConfirmationModal from "../../common/ConfirmationModal";
import NoDataFound from "../../common/noDataFound";
import ErrorHandler from "../../helper/errorHandler";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  tab_main: {
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  truncatedText: {
    maxWidth: "200px",
    flex: 1,
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
}));

const CallsTerm = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(1);
  const [tips, setTips] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [tabsArray, setTabsArray] = useState([]);
  const [boxSize, setBoxSize] = useState({ width: 0, height: 0 });

  const boxRef = useRef(null);

  useEffect(() => {
    fetchTabsData();
  }, []);

  useEffect(() => {
    const element = boxRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setBoxSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    resizeObserver.observe(element);

    return () => {
      if (resizeObserver && element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  const fetchTabsData = async () => {
    setLoading(true);
    ApiGet("admin/getTabs")
      .then(async (res) => {
        let error = await ErrorHandler(res);
        if (error) {
          if (res?.data) {
            setTabsArray(res?.data);
            setValue(res?.data[0]?.status);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (value) {
      fetchData();
    }
  }, [page, rowsPerPage, value]);

  const fetchData = () => {
    setLoading(true);
    dispatch(fetchTips(page + 1, rowsPerPage, parseInt(value)))
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          setTips(res.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
    // setRowsPerPage(10);
  };

  // const checkApiCall = ()=>{
  //   const checkPage = Math.ceil((tips.totalCount - 1) / rowsPerPage);
  //   if(checkPage == 0){
  //     fetchData();
  //   }else{
  //     if(checkPage !== page + 1){
  //       setPage(checkPage - 1)
  //     }else{
  //       fetchData();
  //     }
  //   }
   
  // }

  const onDeleteHandler = async (e, selectedId) => {
    setLoading(true);
    dispatch(deleteTip(e, selectedId))
      .then((response) => {
        const error = ErrorHandler(response);
        if (error) {
          toast.success(response.data.message);
          handleClose();
          if (tips.findTips.length === 1 && page > 0) {
            setPage((prev) => prev - 1);
          } else {
            fetchData();
          }
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModal = (e, id) => {
    setOpen(true);
    setSelectedId(id);
  };

  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Suggestions
          </Typography>
          <Button
            onClick={() => navigate(`/calls-term/add?tab=${value}`)}
            setValue={setValue}
            variant="contained"
          >
            Add Suggestion
          </Button>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              ref={boxRef}
              className={classes.tab_main}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {tabsArray?.length > 0 ? (
                  tabsArray.map((item) => (
                    <Tab
                      key={item.status}
                      sx={{ fontWeight: "600" }}
                      label={item.name.toString()}
                      value={parseInt(item.status)}
                    />
                  ))
                ) : (
                  <></>
                )}
              </TabList>
            </Box>
            <TabPanel value={value} sx={{ paddingX: 0, paddingY: 1 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Buy Price</StyledTableCell>
                      <StyledTableCell>SL Price</StyledTableCell>
                      <StyledTableCell>Target Price </StyledTableCell>
                      <StyledTableCell>Carry Forward </StyledTableCell>
                      <StyledTableCell>Days</StyledTableCell>
                      <StyledTableCell>Percantage</StyledTableCell>
                      <StyledTableCell>Created At</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <StyledTableCell colSpan={10}>
                          <Loader />
                        </StyledTableCell>
                      </TableRow>
                    ) : tips?.findTips?.length > 0 ? (
                      tips?.findTips?.map((tip, index) => (
                        <StyledTableRow key={tip._id}>
                          <StyledTableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell className={classes.truncatedText}>
                            {tip.name}
                          </StyledTableCell>
                          <StyledTableCell>{tip.buyPrice}</StyledTableCell>
                          <StyledTableCell>{tip.stopLimit}</StyledTableCell>
                          <StyledTableCell>{tip.targetPrice}</StyledTableCell>
                          <StyledTableCell>{tip.status}</StyledTableCell>
                          <StyledTableCell>{tip.days}</StyledTableCell>
                          <StyledTableCell>{tip.percentage}</StyledTableCell>
                          <StyledTableCell>
                            {tip?.createdAt &&
                              moment(tip?.createdAt).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display={"flex"} gap={1}>
                              <EditIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/calls-term/edit/${tip._id}`)
                                }
                              />
                              <DeleteIcon
                                sx={{
                                  marginLeft: "10px",
                                  color: theme.palette.error.main,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => openModal(e, tip._id)}
                              />
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <>
                      <StyledTableRow>
                            <StyledTableCell colSpan={12}>
                            <NoDataFound elevation={0} />
                          </StyledTableCell>
                          </StyledTableRow>
                        </>
                    
                    )}
                  </TableBody>

                  {tips?.findTips?.length > 0 && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={tips.totalCount}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                  </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        headTitle={"Are you sure you want to delete this call ?"}
        onDeleteHandler={(e) => onDeleteHandler(e, selectedId)}
        loading={loading}
      />
    </>
  );
};

export default CallsTerm;
