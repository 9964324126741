
import { toast } from 'react-toastify'
import { responseEnum } from './enum'

export default function ErrorHandler(data) {
    if (data?.status) {
        if (data?.status === responseEnum.InternalServerCode) {
            toast.error(data?.message, "error")
            return false
        } else if (data?.status === responseEnum.ValidationCode404) {
            toast.error(data?.message, "error")
            return false
        } else if (data?.status === responseEnum.ValidationCode400) {
            toast.error(data?.message, "error")
            return false
        } else if (data?.status === responseEnum.ValidationCode409) {
            toast.error(data?.message, "error")
            return false
        } else if (data?.status === responseEnum.UnAuth) {
            toast.error(data?.message, "error")
            localStorage.clear()
            window.location.href = '/'
            return false
        } else {
            return true
        }
    } else {
        toast.error(data?.message, "error")
        return false
    }
}