import AuthStorage from "../AuthStorage";
import { BASEURL } from "../../config/api.config";
import axios from "axios";
import { toast } from "react-toastify";
import STORAGEKEY from "../../config/APP/app.config";


const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
};

const refreshToken = async () => {
  try {
    const response = await axios.get(`${BASEURL}user/refreshToken`, {
      headers: {
        Authorization: `${localStorage.getItem("refreshToken")}`,
        "Content-Type": "application/json",
      },
    });
    AuthStorage.setStorageData(
      STORAGEKEY.token,
      response.data.data.token,
      true
    );
    AuthStorage.setStorageData(
      STORAGEKEY.refreshToken,
      response.data.data.refreshToken,
      true
    );
    return response.data.data.token;
  } catch (error) {
    if(error.response.data.status === 401){
      AuthStorage.deauthenticateUser();
      window.location.href = '/';
    }
    throw new Error("Failed to refresh token");
  }
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASEURL}${type}`, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          try {
            const res = await refreshToken();
            const response = await ApiGet(type);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${BASEURL}${type}`,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          // AuthStorage.deauthenticateUser();
          try {
            const res = await refreshToken();
            const response = await ApiGetNoAuth(type);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPost = (type, userData, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASEURL}${type}`, userData, getHttpOptions(headers))
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          // AuthStorage.deauthenticateUser();
          try {
            const res = await refreshToken();
            const response = await ApiPost(type, userData, headers);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASEURL}${type}`,
        userData,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          try {
            const res = await refreshToken();
            const response = await ApiPostNoAuth(type, userData);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("message") &&
          error.response.data.message
        ) {
          reject(error.response.data.message);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${BASEURL}${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          // AuthStorage.deauthenticateUser();
          try {
            const res = await refreshToken();
            const response = await ApiPatch(type, userData);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiDelete = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${BASEURL}${type}`, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          // AuthStorage.deauthenticateUser();
          try {
            const res = await refreshToken();
            const response = await ApiDelete(type, userData);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${BASEURL}${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          // AuthStorage.deauthenticateUser();
          try {
            const res = await refreshToken();
            const response = await ApiPut(type, userData);
            resolve(response);
          } catch (error) {
            toast.error(error.message);
            reject(error.message);
          }
        } else if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {
    Authorization: "",
    "Content-Type": "application/json",
  };

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = AuthStorage.getToken() ?? "";
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }
  if (options.hasOwnProperty("isMultiPart") && options.isMultiPart) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }
  return { headers };
};
