import {
    FETCH_TIPS_REQUEST,
    FETCH_TIPS_SUCCESS,
    FETCH_TIPS_FAILURE,
  } from './tipsContants';
  
  const initialState = {
    tips: [],
    loading: false,
    error: null,
  };
  
  const tipsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TIPS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_TIPS_SUCCESS:
        return {
          ...state,
          tips: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_TIPS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default tipsReducer;