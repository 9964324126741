import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { ApiDelete, ApiGet } from "../../helper/API/ApiData";
import ErrorHandler from "../../helper/errorHandler";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: 500,
    maxWidth: 500,
    objectFit: "cover",
  },
  content: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  fieldValue: {
    flex: 1,
    marginLeft : theme.spacing(1)
  },
}));


const ViewMutualFund = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [singleMutualFund, setSingleMutualFund] = useState({});
  const [loading, setLoading] = useState(false);

  const getSingleMutualFund = () => {
    setLoading(true);
    ApiGet(`admin/getMutualFunds/${id}`)
      .then((res) => {
        let checkErrors = ErrorHandler(res);
        if (checkErrors) {
          setSingleMutualFund(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setLoading(false));
  };

  const formatText = (data) => {
    const paragraphs = data?.split('\n').map((paragraph, index) => (
      <Typography key={index} variant="body1" className={classes.fieldValue}>{paragraph}</Typography>
    ));
    return <Box>{paragraphs}</Box>;
  };

  useEffect(() => {
    getSingleMutualFund();
  }, []);

  return (
    <Box className={classes.container} gap={3}>
      {loading ? (
        <Loader />
      ) : Object.keys(singleMutualFund)?.length > 0 ? (
        <>
          <Box className={classes.imageContainer}>
            <img
              src={singleMutualFund?.image}
              title={singleMutualFund?.name}
              alt={singleMutualFund?.name}
              className={classes.image}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Box className={classes.content}>
            <Typography variant="h4" gutterBottom>
              {singleMutualFund?.name}
            </Typography>
            <Box className={classes.fieldContainer}>
              <Typography variant="body1" className={classes.fieldLabel}>Description: </Typography>
              <Box className={classes.fieldValue}>
                {formatText(singleMutualFund?.description)}
              </Box>
            </Box>
            <Box className={classes.fieldContainer}>
              <Typography variant="body1" className={classes.fieldLabel}>Fund House :</Typography>
              <Box variant="body1" className={classes.fieldValue}>
                {singleMutualFund?.fund_house}
              </Box>
            </Box>
            <Box className={classes.fieldContainer}>
              <Typography variant="body1" className={classes.fieldLabel}>Scheme Type :</Typography>
              <Box variant="body1" className={classes.fieldValue} >
                {singleMutualFund?.scheme_type}
              </Box>
            </Box>
            <Box className={classes.fieldContainer}>
              <Typography variant="body1" className={classes.fieldLabel}>Link :</Typography>
              <Box variant="body1" className={classes.fieldValue}>
                {singleMutualFund?.link}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Grid container m={1}>
          <NoDataFound elevation={0} />
        </Grid>
      )}
    </Box>
  );
};

export default ViewMutualFund;
