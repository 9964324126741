import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Card,
  CardMedia,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchSingleUserData,
  addTip,
  editTip,
} from "../../redux/tips/tipsActions";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";
import SelectDropDown from "../../common/commonSelectDropDown";
import LoadingButton from "../../common/LoadingButton";
import ErrorHandler from "../../helper/errorHandler";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import CustomDialog from "../../common/CustomDialog";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  card_main: {
    backgroundColor: theme.palette.bgWhite.main,
    padding: "15px",
    borderRadius: "10px",
    border: `1px dashed ${theme.palette.primary.main}`,
    boxShadow: "none",
  },
  input_main: {
    "& .MuiOutlinedInput-root": {
      "& input[cateogry='number']::-webkit-inner-spin-button, input[cateogry='number']::-webkit-outer-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    display: "inline !important",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));

const AddCallTerm = ({ setValue }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab } = useParams();

  const [loading, setLoading] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    buyPrice: "",
    lastPrice: "",
    stopLimit: "",
    targetPrice: "",
    status: null,
    term: null,
    stockType: "",
    days: "",
    percentage: "",
    cateogry: null,
    stockImage: "",
  });
  const [stockList, setStockList] = useState([]);
  const [tipData, setTipData] = useState({
    name: "",
    buyPrice: "",
    lastPrice: "",
    stopLimit: "",
    targetPrice: "",
    status: null,
    term: null,
    stockType: "",
    description: "",
    days: "",
    percentage: "",
    cateogry: null,
    stockImage: "",
  });
  const [disabledDropzone, setDisabledDropzone] = useState(false);
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("");
  const [croppedLogo, setCroppedLogo] = useState(null);

  useEffect(() => {
    if (tab) {
      setValue(parseInt(tab)); // Set the tab value
    }
  }, [tab]);

  const fetchEditData = async (id) => {
    setEditLoader(true);
    dispatch(fetchSingleUserData(id))
      .then((response) => {
        const data = response?.data.findTips;

        setTipData({
          id: data._id,
          name: data?.name,
          buyPrice: data?.buyPrice,
          lastPrice: data?.lastPrice,
          stopLimit: data?.stopLimit,
          targetPrice: data?.targetPrice,
          status: data?.status,
          term: data?.term,
          stockType: data?.stockType,
          days: data?.days,
          percentage: data?.percentage,
          cateogry: data.cateogry === 0 ? "Option" : "Stock",
          stockImage: data.image,
          description: data.description,
        });
        setEditLoader(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setEditLoader(false);
      });
  };

  const fetchStockType = () => {
    setLoading(true);
    ApiGet("admin/getTabs")
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          setStockList(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValidation = function (value, name) {
    if (value === "") {
      setTipData({
        ...tipData,
        [name]: value,
        targetPrice: "",
      });
    }
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue)) {
      let newPrice = tipData.buyPrice * (1 + value / 100);
      newPrice = newPrice.toFixed(2);
      setTipData({
        ...tipData,
        [name]: value,
        targetPrice: newPrice,
      });
    }
  };
  const handlePercentageChange = (value, name) => {
    const percentageChange = (
      ((value - tipData.buyPrice) / tipData.buyPrice) *
      100
    ).toFixed(2);
    setTipData({
      ...tipData,
      [name]: value,
      percentage: percentageChange,
    });
  };
  const handleBuyPriceChange = (value, name) => {
    const parsedValue = parseFloat(value);

    // Create a copy of tipData with the new buyPrice value
    let updatedTipData = { ...tipData, [name]: value };

    if (isNaN(parsedValue)) {
      // If the input is not a valid number, reset dependent values
      updatedTipData.targetPrice = "";
      updatedTipData.percentage = "";
      setTipData(updatedTipData);
      return;
    }

    // Update targetPrice based on the percentage if percentage is present
    if (tipData.percentage) {
      const newTargetPrice = parsedValue * (1 + tipData.percentage / 100);
      updatedTipData.targetPrice = newTargetPrice.toFixed(2);
    }

    // Update percentage based on the targetPrice if targetPrice is present
    if (tipData.targetPrice) {
      const newPercentage =
        ((tipData.targetPrice - parsedValue) / parsedValue) * 100;
      updatedTipData.percentage = newPercentage.toFixed(2);
    }

    // Finally, set the updated data
    setTipData(updatedTipData);
    // }
    // setTipData({
    //   ...tipData,
    //   [name] : value,
    //   percentage : "",
    //   targetPrice:''
    // })
  };

  const onChangeHandler = (e) => {
    const { value, name } = e?.target;

    if (name == "percentage") {
      handleValidation(value, name);
    } else if (name === "targetPrice") {
      handlePercentageChange(value, name);
    } else if (name === "buyPrice") {
      handleBuyPriceChange(value, name);
    } else {
      setTipData({
        ...tipData,
        [name]: value,
      });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validation = () => {
    let flag = false;
    let obj = {};
    if (tipData.name === "") {
      obj.name = "Please enter the stock name!";
      flag = true;
    }
    if (tipData.buyPrice === "") {
      obj.buyPrice = "Please enter the buy price!";
      flag = true;
    }
    if (tipData.lastPrice === "") {
      obj.lastPrice = "Please enter the last price!";
      flag = true;
    }
    if (tipData.stopLimit === "") {
      obj.stopLimit = "Please enter the stop limit!";
      flag = true;
    }
    if (tipData.targetPrice === "") {
      obj.targetPrice = "Please enter the target price!";
      flag = true;
    }
    if (tipData.term === null) {
      obj.term = "Please select the stock cateogry!";
      flag = true;
    }
    if (tipData.status === null) {
      obj.status = "Please select the carry forward!";
      flag = true;
    }
    const regex = /^\d{1,3}(\.\d{1,2})?$/;
    if (!tipData.percentage) {
      obj.percentage = "Please enter the percentage !";
    } else if (!regex.test(tipData.percentage)) {
      obj.percentage = "Only 2 digit allowed after the period !";
    }
   
    if (tipData.cateogry === null) {
      obj.cateogry = "Please select the cateogry !";
    }
    if (tipData.cateogry == "Option") {
      if (tipData.stockType === "") {
        obj.stockType = "Please enter the stock type!";
        flag = true;
      }
    }
    if (tipData.stockImage === "") {
      obj.stockImage = "Please select the stock image!";
      flag = true;
    }
    setErrors(obj);
    return flag;
  };

  const editData = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      id: id,
      buyPrice: tipData.buyPrice,
      lastPrice: tipData.lastPrice,
      stopLimit: tipData.stopLimit,
      targetPrice: tipData.targetPrice,
      status: tipData.status,
      // days: tipData.days,
      percentage: tipData.percentage,
      cateogry: tipData.cateogry === "Option" ? 0 : 1,
      stockType: tipData.stockType,
      // term: tipData.term,
      image: tipData.stockImage,
    };
    if(tipData.days){
      payload.days = tipData.days
    }
    if (tipData.description) {
      payload.description = tipData.description;
    }
    dispatch(editTip(payload))
      .then((response) => {
        setLoading(false);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/calls-term");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };

  const addData = () => {
    if (validation()) {
      return;
    }
    let payload = {
      name: tipData.name,
      buyPrice: tipData.buyPrice,
      lastPrice: tipData.lastPrice,
      stopLimit: tipData.stopLimit,
      targetPrice: tipData.targetPrice,
      status: tipData.status,
      // days: tipData.days,
      percentage: tipData.percentage,
      term: stockList?.find((e) => e?.name == tipData?.term)?.status,
      cateogry: tipData.cateogry === "Option" ? 0 : 1,
      stockType: tipData.stockType,
      image: tipData.stockImage,
    };
    if(tipData.days){
      payload.days = tipData.days
    }
    if (tipData.description) {
      payload.description = tipData.description;
    }

    setLoading(true);
    dispatch(addTip(payload))
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/calls-term");
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
  };

  const onCancelHandler = (e) => {
    e.preventDefault();
    setTipData({
      name: "",
      buyPrice: "",
      lastPrice: "",
      stopLimit: "",
      targetPrice: "",
      term: null,
      status: null,
      stockType: "",
      stockImage: "",
      description: "",
    });
    setErrors({});
    navigate("/calls-term");
  };

  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true);
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name);
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };

  
  const UploadLogo = async (acceptedFiles) => {
    const file = acceptedFiles;
    const formData = new FormData();

    setErrors({ wrong: "", empty: "", large: "" });
    formData.append("image", acceptedFiles);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisabledDropzone(true);
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        let checkError = ErrorHandler(res);
        if (checkError) {
          setTipData({ ...tipData, stockImage: res.data.image });
          setShowLogoPicker(false);
          setShowCropper(false);
          setUploadedLogo(null);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() =>  setDisabledDropzone(false));
  };




  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setErrors({ ...errors, stockImage: "Only images are allowed" });
        } else if (error.code === "file-too-large") {
          setErrors({
            ...errors,
            stockImage: "File size should be less than 5 MB",
          });
        }
      });
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disabledDropzone,
  });

  const onKeyDownHandler = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };
  const onKeyDownHandlerforPercantage = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (id) {
      fetchEditData(id);
    }
  }, [id]);

  useEffect(() => {
    fetchStockType();
  }, []);

  return (
    <Box>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          {id ? "Edit Suggestion" : "Add Suggestion"}
        </Typography>
      </Box>
      <Box className={classes.card_main}>
        {editLoader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SelectDropDown
                  text="Type"
                  fullWidth
                  width={"100%"}
                  values={["Option", "Stock"]}
                  value={tipData.cateogry}
                  name="cateogry"
                  onChange={onChangeHandler}
                  placeholder="Select Stock Type"
                />
                {errors.cateogry && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.cateogry}
                  </Typography>
                )}
              </Grid>
              {tipData.cateogry == "Option" && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SelectDropDown
                    text={"Option Type"}
                    fullWidth
                    width={"100%"}
                    values={["Call", "Put"]}
                    value={tipData.stockType}
                    name={"stockType"}
                    onChange={onChangeHandler}
                    placeholder={"Select Stock Calls"}
                    // disabled={Boolean(id)}
                  />
                  {errors.stockType && (
                    <Typography color="error" fontSize={"12px"}>
                      {errors.stockType}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Stock Name"}
                  placeholder={"Enter Stock Name"}
                  cateogry="text"
                  name="name"
                  value={tipData.name}
                  onChange={onChangeHandler}
                  inputProps={{
                    readOnly: Boolean(id),
                    className: classes.placeholder,
                  }}
                  // disabled={Boolean(id)}
                />
                {errors.name && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  type={"number"}
                  fontWeight={400}
                  text={"Buy Price"}
                  placeholder={"Enter Buy Price"}
                  cateogry="number"
                  name="buyPrice"
                  value={tipData.buyPrice}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />
                {errors.buyPrice && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.buyPrice}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} display={"block"}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Percentage"}
                  placeholder={"Enter the Percentage"}
                  cateogry="number"
                  type={"number"}
                  name="percentage"
                  value={tipData.percentage}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandlerforPercantage}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />

                {errors.percentage && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.percentage}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"SL Price"}
                  placeholder={"Enter Stop Limit Price"}
                  cateogry="number"
                  type={"number"}
                  name="stopLimit"
                  value={tipData.stopLimit}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />

                {errors.stopLimit && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.stopLimit}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Target Price"}
                  placeholder={"Enter Target Price"}
                  cateogry="number"
                  type={"number"}
                  name="targetPrice"
                  value={tipData.targetPrice}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />
                {errors.targetPrice && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.targetPrice}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SelectDropDown
                  text="Carry Forward"
                  fullWidth
                  width={"100%"}
                  values={["Intraday", "Carry Forward"] || []}
                  value={tipData.status}
                  name="status"
                  onChange={onChangeHandler}
                  placeholder="Select Carry Forward"
                  disabled={Boolean(id)}
                  inputProps={{ className: classes.placeholder }}
                />
                {errors.status && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.status}
                  </Typography>
                )}
              </Grid>
              {!id && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SelectDropDown
                    text="Stock Type"
                    fullWidth
                    width={"100%"}
                    values={
                      stockList?.length > 0
                        ? stockList?.map((e) => e?.name)
                        : []
                    }
                    value={tipData.term}
                    name="term"
                    onChange={onChangeHandler}
                    placeholder="Select Stock Type"
                    disabled={Boolean(id)}
                  />
                  {errors.term && (
                    <Typography color="error" fontSize={"12px"}>
                      {errors.term}
                    </Typography>
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Last Trade Price"}
                  placeholder={"Enter Last Trade Price"}
                  cateogry="number"
                  type={"number"}
                  name="lastPrice"
                  value={tipData.lastPrice}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />

                {errors.lastPrice && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.lastPrice}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonTextField
                  className={classes.input_main}
                  fontWeight={400}
                  text={"Days"}
                  placeholder={"Enter the days"}
                  cateogry="text"
                  type={"number"}
                  name="days"
                  value={tipData.days}
                  onChange={onChangeHandler}
                  inputProps={{ min: 0, className: classes.placeholder }}
                />

                {errors.days && (
                  <Typography color="error" fontSize={"12px"}>
                    {errors.days}
                  </Typography>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <CommonTextField
                className={classes.input_main}
                fontWeight={400}
                text={"Description"}
                placeholder={"Enter the description"}
                cateogry="number"
                name="description"
                multiline
                rows={5}
                value={tipData.description}
                onChange={onChangeHandler}
                inputProps={{ min: 0, className: classes.placeholder }}
              />
            </Grid> */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel
                  sx={{
                    marginRight: "3px",
                    fontWeight: 400,
                    fontSize: "15px",
                    color: theme?.palette?.bgBlack?.main,
                    padding: "2px",
                    marginTop: "5px",
                  }}
                >
                  Description
                </InputLabel>
                <textarea
                  rows={5}
                  name="description"
                  value={tipData.description}
                  onChange={onChangeHandler}
                  placeholder="Enter the description"
                  style={{
                    height: "auto",
                    display: "inline",
                    borderRadius: "5px",
                    outline: "none",
                    padding: "5px",
                    width: "100%", 
                    display: "block",
                    maxWidth :'400px' 
                  }}
                />
                {errors.description && (
                  <Typography sx={{ fontSize: "12px", color: "error" }}>
                    {errors.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid mt={2}>
              <InputLabel
                sx={{
                  marginRight: "3px",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: theme?.palette?.bgBlack?.main,
                  padding: "3px",
                }}
              >
                Select Stock Image
              </InputLabel>
            </Grid>
            <Grid item container xs={6} spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                <input {...getInputProps()} />
                <>
                  {/* <Typography sx={{ fontSize: "12px", color: "red", mb: 1 }}>
                 Note :- Please select .png .jpg and .jpeg images
               </Typography> */}
                  <Card
                    style={{
                      padding: "20px",
                      border: `1px dashed ${theme.palette.primary.main}`,
                      textAlign: "center",
                      boxShadow: "none",
                      // display : "block"
                    }}
                  >
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <div style={{ color: theme.palette.primary.main }}>
                        <CloudUploadIcon fontSize="large" />
                        {disabledDropzone ? (
                          <p>Image is uploading...</p>
                        ) : (
                          <p>
                            {" "}
                            Drag and drop some images here or select only .jpg ,
                            .jpeg , .png images.
                          </p>
                        )}
                        <p></p>
                      </div>
                    )}
                  </Card>
                  {errors.stockImage && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.stockImage}
                    </Typography>
                  )}
                </>
              </Grid>
              {tipData.stockImage && (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    {/* <Card style={{ position: "relative", height: "125px", width: '175px' }}>
                    <img src={tipData.stockImage} width={"100%"} height={"100%"} />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        zIndex: 1,
                      }}
                      onClick={() => {
                        setTipData({
                          ...tipData,
                          stockImage: "",
                        });
                      }}
                    >
                      <CloseIcon
                        sx={{
                          background: "white",
                          color: "red",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </Card> */}

                    <Box
                      sx={{
                        position: "relative",
                        background: `url(${tipData.stockImage})`,
                        height: "110px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        borderRadius: "5px",
                        overflow: "hidden",
                        "&:hover .hoverButtons": {
                          opacity: 1,
                        },
                      }}
                    >
                      <Box
                        className="hoverButtons"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          opacity: 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <Box
                          onClick={() =>
                            window.open(tipData.stockImage, "_blank")
                          }
                          height={"25px"}
                          width={"25px"}
                          borderRadius={"50%"}
                          bgcolor={theme.palette.bgWhite.main}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: "42%",
                            right: "42%",
                            cursor: "pointer",
                          }}
                        >
                          <VisibilityIcon
                            sx={{
                              color: theme.palette.bgBlack.main,
                              fontSize: "20px",
                            }}
                          />
                        </Box>
                        <Box
                          onClick={() => {
                            setTipData({
                              ...tipData,
                              stockImage: "",
                            });
                          }}
                          height={"25px"}
                          width={"25px"}
                          borderRadius={"50%"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: theme.palette.error.main,
                              fontSize: "20px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}
              {showLogoPicker && (
                <CustomDialog
                  isOpen={showLogoPicker}
                  handleClose={() => {
                    setShowLogoPicker(false);
                    setShowCropper(false);
                    setUploadedLogo(null);
                  }}
                >
                  <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                  <DialogContent
                    sx={{
                      width: {
                        lg: "700px",
                        md: "700px",
                        sm: "600px",
                        xs: "400px",
                      },
                    }}
                    dividers
                  >
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ width: "100%", position: "relative" }}>
                          <Cropper
                            style={{ height: 300, width: "100%" }}
                            src={uploadedLogo}
                            viewMode={0}
                            background={false}
                            responsive={false}
                            minCropBoxHeight={20}
                            minCropBoxWidth={10}
                            dragMode="move"
                            autoCropArea={1}
                            checkOrientation={false}
                            cropBoxResizable={true}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={false}
                          />

                          {/* <IconButton
                          component="span"
                          style={{
                            justifyContent: "center", alignSelf: "center", position: "absolute", top: 0, right: 0,
                          }}
                          onClick={() => {
                            setShowCropper(false);
                            setUploadedLogo(null);
                            setInitialLogo(null);
                            setShowLogoPicker(false)
                          }}
                        >
                          <DeleteIcon style={{ color: theme.palette.bgWhite.main, marginTop: 10 }} />
                        </IconButton> */}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"end"} gap={1}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setShowCropper(false);
                              setUploadedLogo(null);
                              setInitialLogo(null);
                              setShowLogoPicker(false);
                            }}
                            color="error"
                            sx={{ borderRadius: 5 }}
                          >
                            Cancel
                          </Button>
                          {/* <Button
                            color="primary"
                            variant="contained"
                            sx={{ borderRadius: 5 }}
                            disableElevation
                            onClick={() => {
                              getCropData();
                            }}
                          >
                            Crop
                          </Button> */}
                          <LoadingButton
                           color="primary"
                           variant="contained"
                           sx={{ borderRadius: 5 }}
                           disableElevation
                           onClickHandler={() => {
                             getCropData();
                           }}
                           loading={disabledDropzone}
                           text="Crop"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </CustomDialog>
              )}
            </Grid>
          </>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="outlined"
            onClick={(e) => onCancelHandler(e)}
            color="error"
          >
            Cancel
          </Button>
          {/* <Button
            className={classes.contained_button}
            variant="contained"
            onClick={id ? editData : addData}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color="inherit"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  color: "#F1F4F8",
                  strokeWidth: 2,
                }}
              />
            ) : (
              "Save"
            )}
          </Button> */}
          <LoadingButton
            className={classes.contained_button}
            variant={"contained"}
            onClickHandler={() => (id ? editData() : addData())}
            disabled={loading}
            loading={loading}
            text={id ? "Update" : "Save"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddCallTerm;
