import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  TableRow,
  Pagination,
  Tab,
} from "@mui/material";
import CommonTextField from "../../common/commonTextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader";
import ErrorHandler from "../../helper/errorHandler";
import Modal from "../../common/Modal";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import ConfirmationModal from "../../common/ConfirmationModal";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import NoDataFound from "../../common/noDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewModal from "../../common/ViewModal";
import { debounce } from "../../helper/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PromotionNotification from "../promotion-notification";
import PromotionVideos from "../promotion-videos/PromotionVideos";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    // display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  search_input_field: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      width: 250,
      height: 50,
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
}));

function PromotionVideosAndNotifications() {
  const theme = useTheme();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState("0");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          Promotion Notification & Videos
        </Typography>
        <Box />
        <Box
          className={classes.tab_main}

        >
          <TabContext value={tabValue}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                value={"0"}
                sx={{ fontWeight: "600" }}
                label="Promotion Notification"
              />
              <Tab
                value={"1"}
                sx={{ fontWeight: "600" }}
                label="Promotion Videos"
              />
            </TabList>
            <TabPanel sx={{ paddingX: 0, paddingY: 1 }} value="0">
              <PromotionNotification/>
            </TabPanel>
            <TabPanel sx={{ paddingX: 0, paddingY: 1 }} value="1">
              <PromotionVideos/>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}

export default PromotionVideosAndNotifications;
