import React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box, Table, TableBody, TableContainer, TableHead, TableRow,
  Paper,
  TablePagination,
  Typography
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import moment from "moment";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";
import { useTheme } from "@mui/styles";
import ReadMore from "../../common/ReadMore";
import Visibility from "@mui/icons-material/Visibility";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  "&:hover .image-overlay": {
    opacity: 1,
  },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  opacity: 0,
  transition: "opacity 0.2s",
}));

const CommonTable = ({
  loading,
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteHandler,
  openModeHandler,
  showAction,
  rejectionTable
}) => {

  const theme = useTheme()

  return (
  
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">{rejectionTable ? "Reason For Rejection" : "Description" }</StyledTableCell>
              <StyledTableCell align="center">No. Of Reports</StyledTableCell>
              {showAction && <StyledTableCell align="center">Actions</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <Loader />
                </StyledTableCell>
              </TableRow>
            ) : (
              <>
                {Object.keys(data)?.length > 0 ? data?.getReport?.map((item, index) => (
                  <StyledTableRow key={item._id}>
                    <StyledTableCell align="center">{(page * rowsPerPage) + index + 1}</StyledTableCell>
                    <StyledTableCell align="center">{item.feedBack?.[0]?.name || "-"}</StyledTableCell>
                    <StyledTableCell align="center">
                      {item.feedBack?.[0]?.image ? (
                        <ImageContainer>
                          <img
                          src={item.feedBack?.[0]?.image}
                          alt="img"
                          style={{ width: "75px", maxHeight: "75px",borderRadius:'3px' }}
                        />
                        <ImageOverlay className="image-overlay">
                              <Visibility
                                style={{ cursor: "pointer", color: "white" }}
                                onClick={() =>
                                  window.open(item.feedBack?.[0]?.image, "_blank")
                                }
                              />
                            </ImageOverlay>
                        
                        </ImageContainer>
                        
                      ) : (
                        <p>-</p>
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" style={{maxWidth: "300px", fontWeight: 300, color: "#000", textAlign: "left", fontSize: "13px", lineHeight: 1.6}}>{item.feedBack?.[0]?.content || "-"}</StyledTableCell> */}
                    <StyledTableCell sx={{maxWidth : '300px' , minWidth : '300px'}}>
                      {
                        rejectionTable ? (<>
                        {
                        item?.feedBack?.[0]?.removeFeedbackReason ? (
                          <ReadMore content={item?.feedBack?.[0]?.removeFeedbackReason} maxLength={120}/>
                        ) :(<Typography>-</Typography>)
                      }</>

                        ):(
                          <>{
                            item?.feedBack?.[0]?.content ? (
                              <ReadMore content={item?.feedBack?.[0]?.content} maxLength={120}/>
                            ) :(<Typography>-</Typography>)
                          }
                          </>
                        )
                      }
                      
                    </StyledTableCell>
                    <StyledTableCell align="center">{item.count || "-"}</StyledTableCell>
                    {/* <StyledTableCell align="center">{item.createdAt && moment(item.createdAt).format('DD/MM/YYYY')}</StyledTableCell> */}
                    {showAction && (
                      <StyledTableCell align="center">
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                          <RemoveRedEyeIcon
                            onClick={() => openModeHandler(item)}
                            sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
                          />
                          <ShortcutIcon sx={{ color: theme.palette.error.main, cursor: "pointer" }} onClick={() => onDeleteHandler(item._id)} />
                        </Box>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                )) : (
                    <StyledTableCell colSpan={8}>
                      <NoDataFound elevation={0} />
                    </StyledTableCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
        {Object.keys(data)?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={data.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>

  );
};

export default CommonTable;

