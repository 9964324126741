import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { ApiDelete, ApiGet } from "../../helper/API/ApiData";
import ErrorHandler from "../../helper/errorHandler";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: 500,
    maxWidth: 500,
    objectFit: "cover",
  },
  content: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ViewNews = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [singleNewsData, setSingleNewsData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSingleNews = () => {
    setLoading(true);
    ApiGet(`admin/getblog/${id}`)
      .then((res) => {
        let checkErrors = ErrorHandler(res);
        if (checkErrors) {
          setSingleNewsData(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setLoading(false));
  };
  const formateText = (data)=>{
      const paragraphs = data.split('\n').map((paragraph, index) => (
        <Typography key={index}>{paragraph}</Typography>
      ));
      return <Box>{paragraphs}</Box>;
  }

  useEffect(() => {
    getSingleNews();
  }, []);

  return (
    <>
      <Box className={classes.container} gap={3}>
        {loading ? (
          <Loader />
        ) : Object.keys(singleNewsData).length > 0 ? (
          <>
          <Box>
            <img
              src={singleNewsData?.image}
              title="green iguana"
              alt="title"
              className={classes.image}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Box className={classes.content}>
            <Typography variant="h4" gutterBottom>
              {singleNewsData?.title}
            </Typography>
            
            {/* Adding News Sector */}
            <Typography variant="body1" color="text.primary" gutterBottom>
              <strong>Sector:</strong> {singleNewsData?.sector ?? "--"}
            </Typography>
            
            {/* Adding Description */}
            <Typography variant="body1" paragraph>
            <strong>Description:</strong> 
              {formateText(singleNewsData?.description ?? "--")}
            </Typography>
          </Box>
        </>
        ) : (
          <Grid container m={1}>
            <NoDataFound elevation={0} />
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ViewNews;
