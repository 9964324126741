import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled, useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import FeedbackIcon from "@mui/icons-material/Feedback";
import STORAGEKEY from "../config/APP/app.config";
import LogoutIcon from "@mui/icons-material/Logout";
import ConfirmationModal from "./ConfirmationModal";
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, Typography } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import ReportIcon from "@mui/icons-material/Report";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HighlightIcon from "@mui/icons-material/Highlight";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import MenuIcon from '@mui/icons-material/Menu';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AppleIcon from '@mui/icons-material/Apple';
import CommonTextField from "./commonTextField";
import { ApiGet, ApiPost } from "../helper/API/ApiData";
import ErrorHandler from "../helper/errorHandler";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { toast } from "react-toastify";
import Modal from "./Modal";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CategoryIcon from '@mui/icons-material/Category';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.bgWhite.main,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.bgWhite.main,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  border: "none",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  color: theme.palette.primary.main,
  minHeight: "63px !important",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles((theme) => ({
  non_active_tab: {
    color: theme.palette.bgWhite.main,
  },
  active_tab: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    fontWeight: 700,
    borderRadius: "30px",
    "&::before": {
      content: '""',
      position: 'absolute',
      top: '-50px',
      right: '0px',
      width: '50px',
      height: '50px',
      background: 'transparent',
      borderRadius: '50%',
      boxShadow: `25px 25px 0 ${theme.palette.background.default}`,
      zIndex: "-1",
    },
    "&::after": {
      content: '""',
      position: 'absolute',
      bottom: '-50px',
      right: '0px',
      width: '50px',
      height: '50px',
      background: 'transparent',
      borderRadius: '50%',
      boxShadow: `25px -25px 0 ${theme.palette.background.default}`,
      zIndex: "-1",
    }
  },
}));
const sidebarItems = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    name: "Stock Type",
    icon: <LegendToggleIcon />,
    path: "/tabs",
  },
  {
    name: "Suggestions",
    icon: <TipsAndUpdatesIcon />,
    path: "/calls-term",
  },
  {
    name: "Users",
    icon: <GroupIcon />,
    path: "/users",
  },
  // {
  //   name: "Subscriptions",
  //   icon: <SubscriptionsIcon />,
  //   path: "/subscription",
  // },
  {
    name: "Feedback",
    icon: <FeedbackIcon />,
    path: "/feedback",
  },
  {
    name: "Reported Feedback",
    icon: <ReportIcon />,
    path: "/reported-feedback",
  },
  // {
  //   name: "Offers",
  //   icon: <DiscountIcon />,
  //   path: "/offers",
  // },
  // {
  //   name: "Videos",
  //   icon: <VideoLibraryIcon />,
  //   path: "/videos",
  // },
  {
    name: "News Sector",
    icon: <CategoryIcon />,
    path: "/news-master",
  },
  {
    name: "News",
    icon: <NewspaperIcon />,
    path: "/news",
  },
  {
    name: "Mutual Funds",
    icon: <ShoppingBasketIcon />,
    path: "/mutualFunds",
  },
  {
    name: "Highlight Stocks",
    icon: <HighlightIcon />,
    path: "/highlight-stocks",
  },
  {
    name: 'Past Accuracy',
    icon: <ShowChartIcon />,
    path: '/pastAccuracy'
  },
  {
    name: 'Top Gainers And Loosers',
    icon: <SsidChartIcon />,
    path: '/gainer-loosers'
  },
  // {
  //   name: 'Promotion Notification',
  //   icon: <CircleNotificationsIcon />,
  //   path: '/promotion-notification'
  // },
  {
    name: "Contact Us",
    icon: <ContactPageIcon />,
    path: '/contact-us'
  },
  /* {
    name: "iOS Users",
    icon: <AppleIcon />,
    path: "/ios-users"
  } */,
  //  {
  //   name : "Promotion Videos",
  //   icon  : <VideoLibraryIcon/>,
  //   path : '/promotion-videos'
  // }
  , {
    name: 'Promotion Notifications & Videos',
    icon:
      <CircleNotificationsIcon />,
    path:"/promotion-notification-videos"
  }
];

const Sidebar = ({ isopen, handleDrawerClose, handleDrawerOpen }) => {
  // ================= Hooks =================
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();

  // ================= States =================
  const [open, setOpen] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [data, setData] = useState("");
  const [errors, setErrors] = useState({});
  const [activePath, setActivePath] = useState(window.location.pathname);
  const [tabValue, setTabValue] = useState(0);

  // ================= Handler =================
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleInput = (e) => {
    const { value, name } = e.target
    setData({ ...data, [name]: value });
  };

  const logoutHandler = () => {
    navigate("/");
    localStorage.removeItem(STORAGEKEY.token);
    localStorage.removeItem(STORAGEKEY.userData);
  };

  // ================= Validation =================
  const validate = () => {
    let error = {};
    let flag = true;
    if (!data?.appVersion) {
      error['appVersion'] = "* Please enter appVersion";
      flag = false;
    }
    setErrors(error);
    return flag;
  };

  const _updateAppVersion = () => {
    if (validate()) {
      const body = {
        "appVersion": data?.appVersion,
        "isForceUpdate": data?.isForceUpdate,
        "platformType": tabValue == 1 ? 1 : 2
      }
      ApiPost("admin/appVersionUpdate", body)
        .then((response) => {
          const error = ErrorHandler(response)
          if (error) {
            toast.success(response.message);
            setOpenSetting(false)
            setData({})
            _getAppVersion();
          }
        })
        .catch((err) => { toast.error(err?.message); })
    }
  }

  const _getAppVersion = async () => {
    await ApiGet(`admin/getAppVersion?platformType=${tabValue == 1 ? 1 : 2}`)
      .then((res) => {
        console.log(res, "response")
        const error = ErrorHandler(res)
        if (error) {
          setData({
            ...data,
            appVersion: res?.data?.appVersion,
            isForceUpdate: res?.data?.isForceUpdate,
          })
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
  }

  // ================= Use Effects =================

  useEffect(() => {
    if (openSetting) _getAppVersion();
  }, [openSetting, tabValue]);

  useEffect(() => {
    setActivePath(window.location.pathname);
    if(window.location.pathname.includes('/news-master')){
      setActivePath('/news-master')
    }else
    if (window.location.pathname.includes('/calls-term')) {
      setActivePath('/calls-term')
    } else if (window.location.pathname.includes('/news')) {
      setActivePath('/news')
    } else if (window.location.pathname.includes('gainer-loosers')) {
      setActivePath('/gainer-loosers')
    } else {
      setActivePath(window.location.pathname)
    }
  }, [window.location.pathname]);

  return (
    <>
      <Drawer
        variant="permanent"
        open={isopen}
        sx={{
          flexShrink: 0,
          zIndex: 99999,
          '& .MuiDrawer-paper': {
            borderRadius: "0 27px 27px 0",
            background: theme.palette.primary.main,
            boxSizing: 'border-box',
            borderRight: "none",
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
      >
        <DrawerHeader>
          {isopen ?
            <>
              <Box display={"flex"} justifyContent={"center"} width={"100%"} alignItems={'center'}>
                <img
                  src="Frame 345.png"
                  height={"35px"}
                  width={"100px"}
                  style={{ marginTop: 1, }}
                ></img>
              </Box>
              <IconButton color="inherit" onClick={handleDrawerClose} sx={{ zIndex: 9 }}>
                <ChevronLeftIcon sx={{ color: theme.palette.bgWhite.main }} />
              </IconButton>
            </>
            :
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
              <MenuIcon sx={{ color: theme.palette.bgWhite.main }} />
            </IconButton>
          }
        </DrawerHeader>
        <List sx={{ paddingTop: "0px !important", marginLeft: isopen ? "10px" : "5px", }}>
          {sidebarItems.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block", mb: 0.4, "& .MuiListItemButton-root:hover": { backgroundColor: activePath === item.path ? "#0000" : "" } }}
              className={activePath === item.path ? classes.active_tab : classes.non_active_tab}
            >
              <ListItemButton
                onClick={() => handleNavigation(item.path)}
              >
                <ListItemIcon
                  sx={{ color: activePath !== item.path ? '#fff' : theme.palette.primary.main }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: isopen ? 1 : 0, fontWeight: "700", textWrap: !isopen ? "nowrap" : "wrap", "& .MuiTypography-root": { lineHeight: 1.3 } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List sx={{ marginTop: "auto" }}>
          <Divider sx={{ borderColor: theme.palette.bgWhite.main }} />
          <ListItem sx={{ py: 1, pb: 0 }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isopen ? "initial" : "center",
                px: 1.3,
                color: theme.palette.bgWhite.main,
                fontWeight: "700",
              }}
              onClick={() => { setOpenSetting(true); setOpen(false) }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isopen ? 3 : "auto",
                  justifyContent: "center",
                  color: theme.palette.bgWhite.main,
                }}
              >
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Setting"}
                sx={{ opacity: isopen ? 1 : 0, fontWeight: "700" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isopen ? "initial" : "center",
                px: 1.3,
                color: theme.palette.bgWhite.main,
                fontWeight: "700",
              }}
              onClick={() => {
                setOpen(true)
                setOpenSetting(false)
              }
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isopen ? 3 : "auto",
                  justifyContent: "center",
                  color: theme.palette.bgWhite.main,
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Logout"}
                sx={{ opacity: isopen ? 1 : 0, fontWeight: "700" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      {openSetting && <Modal
        open={openSetting}
        handleClose={() => { setOpenSetting(false); setData({}); setErrors({}) }}
        title={"Setting"}
        savetext={"Update"}
        cancelText={"Cancel"}
        handleReject={() => { setOpenSetting(false); setData({}); setErrors({}) }}
        handleApprove={_updateAppVersion}
        content={
          <>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              sx={{ marginBottom: 2 }}
            >
              <Tab label="iOS" />
              <Tab label="Android" />
            </Tabs>
            {tabValue === 0 && ( // iOS Tab Content
              <Grid container spacing={2} px={1} mb={'12px'}>
                <Grid item xs={12}>
                  <Typography fontSize={"18px"} fontWeight={500} style={{ marginTop: "2px" }}>iOS App Version Setting</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"App Version"}
                    placeholder={"Enter iOS app version"}
                    type="text"
                    name="appVersion"
                    value={data?.appVersion}
                    onChange={(e) => handleInput(e)}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {!data?.appVersion && <Typography color="error" fontSize={"12px"} style={{ marginTop: "2px" }}>{errors.appVersion}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel style={{ fontSize: '14px', color: theme?.palette?.bgBlack?.main, fontWeight: 500 }} >Force Update</FormLabel>
                    <RadioGroup value={String(data?.isForceUpdate)} name="isForceUpdate" onChange={(e) => {
                      const { value } = e.target;
                      setData({ ...data, isForceUpdate: value })
                    }}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="True" />
                      <FormControlLabel value="false" control={<Radio />} label="False" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {tabValue === 1 && ( // Android Tab Content
              <Grid container spacing={2} px={1} mb={'12px'}>
                <Grid item xs={12}>
                  <Typography fontSize={"18px"} fontWeight={500} style={{ marginTop: "2px" }}>Android App Version Setting</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"App Version"}
                    placeholder={"Enter Android app version"}
                    type="text"
                    name="appVersion"
                    value={data?.appVersion}
                    onChange={(e) => handleInput(e)}
                    inputProps={{ className: classes.placeholder }}
                  />
                  {!data?.appVersion && <Typography color="error" fontSize={"12px"} style={{ marginTop: "2px" }}>{errors.appVersion}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel style={{ fontSize: '14px', color: theme?.palette?.bgBlack?.main, fontWeight: 500 }} >Force Update</FormLabel>
                    <RadioGroup value={String(data?.isForceUpdate)} name="isForceUpdate" onChange={(e) => {
                      const { value } = e.target;
                      setData({ ...data, isForceUpdate: value })
                    }}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="True" />
                      <FormControlLabel value="false" control={<Radio />} label="False" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </>
        }
      />}
      <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        onDeleteHandler={logoutHandler}
        headTitle={"Are you sure you want to logout?"}
      />
    </>
  );
};

export default Sidebar;
