// import {
//   InputLabel,
//   Typography,
//   Box,
//   InputAdornment,
//   IconButton,
//   TextField,
//   useTheme,
// } from "@mui/material";
// import React, { useState } from "react";
// import { makeStyles } from "tss-react/mui";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import emojiRegex from "emoji-regex";
// // import { lightTheme } from "../../../theme";

// const useStyles = makeStyles()((theme) => {
//   return {
//     main: {
//       background: theme?.palette?.bgWhite?.main,
//       minWidth: "50px",
//       ".MuiInputBase-input": {
//         height: "10px",
//         fontSize: "14px",
//       },
//       ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
//         borderColor: `${theme?.palette?.primary?.main} !important`,
//       },
//       ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline":
//         {
//           borderColor: `${theme?.palette?.bgLightBlack?.main} !important`,
//         },
//       ".MuiOutlinedInput-notchedOutline": {
//         borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
//       },
//       ".Mui-focused .MuiOutlinedInput-notchedOutline": {
//         borderColor: `${theme?.palette?.primary?.main} !important`,
//         borderWidth: "1px !important",
//       },
//       "& .MuiInputBase-formControl.Mui-disabled": {
//         backgroundColor: "#e0e0e0",
//         borderRadius: "8px",
//         opacity: 0.8,
//       },
//     },
//     error: {
//       border: "1px solid green",
//     },
//   };
// });
// const CommonTextField = ({
//   text,
//   type,
//   placeholder,
//   height,
//   width,
//   valid,
//   multiline,
//   rows,
//   name,
//   value,
//   onChange,
//   onInput,
//   inputProps,
//   defaultValue,
//   fontWeight,
//   showPasswordToggle,
//   className,
//   bgcolor,
//   onKeyDown,
//   onPaste,
//   onBlur,
//   readOnly,
//   disabled,
//   onDrag,
//   border,
//   background,
//   fontSize,
//   secondaryText,
// }) => {
//   const { classes } = useStyles();
//   const theme = useTheme();
//   const [showPassword, setShowPassword] = useState(false);
//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };
//   const regex = emojiRegex();
//   return (
//     <>
//       {text && (
//         <Box
//           mt={0.5}
//           //   mb={1}
//           display="flex"
//           fontSize="12px"
//           flexDirection={"row"}
//         >
//           <InputLabel
//             sx={{
//               marginRight: "3px",
//               fontWeight: fontWeight,
//               fontSize: fontSize || "14px",
//               color: theme?.palette?.bgBlack?.main,
//               padding: "3px",
//             }}
//           >
//             {text}{" "}
//             {secondaryText && (
//               <span
//                 style={{
//                   fontSize: "10px",
//                   color: theme.palette.backgroundDefaultColor.main,
//                 }}
//               >
//                 {secondaryText}
//               </span>
//             )}
//           </InputLabel>
//           {valid && (
//             <Typography color="#EF627A" component={"caption"} variant={"body2"}>
//               *
//             </Typography>
//           )}
//         </Box>
//       )}
//       <TextField
//         fullWidth
//         name={name}
//         value={value}
//         onChange={onChange}
//         type={
//           type == "password"
//             ? showPassword && showPasswordToggle
//               ? "text"
//               : type
//             : type
//         }
//         placeholder={placeholder}
//         sx={{
//           height: height || 42,
//           width: width,
//           bgcolor: bgcolor,
//           borderRadius: "5px",
//           border: border,
//           background: background || "tranparent",
//         }}
//         multiline={multiline}
//         rows={rows}
//         className={`${classes?.main} ${className}`}
//         onInput={onInput}
//         onPaste={onPaste}
//         inputProps={inputProps}
//         onKeyDown={onKeyDown}
//         defaultValue={defaultValue}
//         onDrag={onDrag}
//         // InputLabelProps={{
//         //   shrink: shrink,
//         // }}
//         InputProps={{
//           ...(showPasswordToggle && {
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   onClick={handleClickShowPassword}
//                   onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                   style={{
//                     color: showPassword
//                       ? theme.palette.primary.main
//                       : theme.palette.primary.main,
//                   }}
//                 >
//                   {showPassword ? <Visibility /> : <VisibilityOff />}
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }),
//         }}
//         onBlur={onBlur}
//         disabled={disabled ? disabled : false}
//       />
//     </>
//   );
// };

// export default CommonTextField;




import {
  InputLabel,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import emojiRegex from "emoji-regex";

const useStyles = makeStyles()((theme) => {
  return {
    main: {
      background: theme?.palette?.bgWhite?.main,
      minWidth: "50px",
      ".MuiInputBase-input": {
        height: "10px",
        fontSize: "14px",
      },
      ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.primary?.main} !important`,
      },
      ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline":
        {
          borderColor: `${theme?.palette?.bgLightBlack?.main} !important`,
        },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
      },
      ".Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.primary?.main} !important`,
        borderWidth: "1px !important",
      },
      "& .MuiInputBase-formControl.Mui-disabled": {
        backgroundColor: "#e0e0e0",
        borderRadius: "8px",
        opacity: 0.8,
      },
    },
    error: {
      border: "1px solid green",
    },
  };
});

const CommonTextField = ({
  text,
  type,
  placeholder,
  height,
  width,
  valid,
  multiline,
  rows,
  name,
  value,
  onChange,
  onInput,
  inputProps,
  defaultValue,
  fontWeight,
  showPasswordToggle,
  className,
  bgcolor,
  onKeyDown,
  onPaste,
  onBlur,
  readOnly,
  disabled,
  onDrag,
  border,
  background,
  fontSize,
  secondaryText,
  endAdornment, 
  labelPadding
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const regex = emojiRegex();
  return (
    <Box>
      {text && (
        <Box
          mt={0.5}
          display="flex"
          fontSize="12px"
          flexDirection={"row"}
        >
          <InputLabel
            sx={{
              marginRight: "3px",
              fontWeight: fontWeight,
              fontSize: fontSize || "14px",
              color: theme?.palette?.bgBlack?.main,
              padding: labelPadding || '3px'
            }}
          >
            {text}{" "}
            {secondaryText && (
              <span
                style={{
                  fontSize: "10px",
                  color: theme.palette.backgroundDefaultColor.main,
                }}
              >
                {secondaryText}
              </span>
            )}
          </InputLabel>
          {valid && (
            <Typography color="#EF627A" component={"caption"} variant={"body2"}>
              *
            </Typography>
          )}
        </Box>
      )}
      <TextField
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        type={
          type == "password"
            ? showPassword && showPasswordToggle
              ? "text"
              : type
            : type
        }
        placeholder={placeholder}
        sx={{
          height: height || 42,
          width: width,
          bgcolor: bgcolor,
          borderRadius: "5px",
          border: border,
          background: background || "tranparent",
        }}
        multiline={multiline}
        rows={rows}
        className={`${classes?.main} ${className}`}
        onInput={onInput}
        onPaste={onPaste}
        inputProps={inputProps}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        onDrag={onDrag}
        InputProps={{
          endAdornment: endAdornment || (
            showPasswordToggle && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{
                      color: showPassword
                        ? theme.palette.primary.main
                        : theme.palette.primary.main,
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          ),
        }}
        onBlur={onBlur}
        disabled={disabled ? disabled : false}
      />
    </Box>
  );
};

export default CommonTextField;
