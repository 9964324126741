import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  IconButton,
  Grid,
  CardActions,
  CardContent,
  Stack,
  Pagination,
  Checkbox,
  FormControlLabel,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Modal from "../../common/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { ApiDelete, ApiGet, ApiPost } from "../../helper/API/ApiData";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, useTheme } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ErrorHandler from "../../helper/errorHandler";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../../common/ConfirmationModal";
import DOMPurify from "dompurify";
import Loader from "../../common/Loader";
import CommonTextField from "../../common/commonTextField";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewModal from "../../common/ViewModal";
import NoDataFound from "../../common/noDataFound";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LoadingButton from "../../common/LoadingButton";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stockBox: {
    height: "100%",
    width: "100%",
    border: "2px solid",
    overflow: "hidden",
  },
  image: {
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    height: "60%",
    width: "100%",
  },
  mainStockContainer: {
    justifyContent: "space-between",
  },
  description: {
    padding: "1rem",
  },

  cardContent: {
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

}));

const HighlightStocks = () => {
  //Hooks
  const classes = useStyles();
  const theme = useTheme();

  const itemsPerPage = 8;

  //States
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    description: "",
  });
  const [errors, setErrors] = useState({
    description: "",
    imgLink: "",
  });
  const [HighlightStocks, SetHighlightStocks] = useState([]);
  const [confimationModal, setConfirmationModal] = useState(false);
  const [id, setId] = useState(null);
  const [totalDataCount, setTotalDataCount] = useState(null);
  const [page, setPage] = useState(1);
  const [isFavourite, setIsFavourite] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [disabledDropzone, setDisabledDropzone] = useState(false);

  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [cropper, setCropper] = useState();
  const [initialLogo, setInitialLogo] = useState(null);
  const [brandingName, setBrandingName] = useState("")
  const [croppedLogo, setCroppedLogo] = useState(null);


  //Handler Functions
  const onDrop = (acceptedFiles) => {
    setShowLogoPicker(true)
    setShowCropper(true);
    handleCapture(acceptedFiles);
    setBrandingName(acceptedFiles?.[0]?.name)
  };

  const handleCapture = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
    };
    reader.readAsDataURL(e[0]);
  };

  const UploadLogo = async (acceptedFiles) => {
    setShowCropper(false);
    setUploadedLogo(null);

    const file = acceptedFiles;
    const formData = new FormData();

    setErrors({ wrong: "", empty: "", large: "" });

    formData.append("image", acceptedFiles);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setDisabledDropzone(true)
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setImage(res?.data?.image);
        setErrors({ ...errors, imgLink: "" });
        setDisabledDropzone(false)
        // const reader = new FileReader();
        // reader.onload = () => {
        //   setImage(reader.result);
        // };
        // reader.readAsDataURL(file);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
    setShowLogoPicker(false);
      });
  };

  const getCropData = async () => {
    cropper.getCroppedCanvas().toBlob(
      function (blob) {
        var file = new File([blob], brandingName, {
          lastModified: new Date().getTime(),
          type: "image/jpg",
        });
        setCroppedLogo(URL.createObjectURL(blob));
        UploadLogo(file);
      },
      "image/jpg",
      0.95
    );

  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setErrors({ ...errors, wrong: "Only images are allowed" });
        } else if (error.code === "file-too-large") {
          setErrors({ ...errors, large: "File size should be less than 5 MB" });
        }
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
    onDropRejected,
    maxSize: 5242880,
    disabled: disabledDropzone
  });

  const onCloseHandler = () => {
    setModalOpen(false);
    clearState();
  };

  const clearState = () => {
    setImage("");
    setData({
      description: "",
    });
    setId(null);
    setImage("");
    setErrors({
      description: "",
      imgLink: "",
    })
    setIsFavourite(false)
  };

  const viewModalCloseHandler = () => {
    setViewModal(false);
    setData({
      description: "",
    })
  }

  const validation = () => {
    const obj = {};
    let flag = false;

    // if(data.name === ""){
    //   obj.name = "Please enter the stock name !";
    //   flag = true;
    // }

    if (data.description === "") {
      obj.description = "Please enter the description!";
      flag = true;
    }
    if (!image) {
      obj.imgLink = "Please select the image!";
      flag = true;
    }

    setErrors(obj);
    return flag;
  };

  const addStock = () => {
    if (validation()) {
      return;
    }
    setLoading(true);
    const payload = {
      image: image,
      description: data.description,
      isFavourite: isFavourite,
    }
    ApiPost("admin/addhighlight", payload)
      .then((res) => {
        let checkError = ErrorHandler(res);
        if (checkError) {
          toast.success(res.message);
          setModalOpen(false);
          getHighlightStocks();
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
        clearState();
      });
  };

  const editHandler = () => {
    if (validation()) {
      return;
    }
    setLoading(true);

    const payload = {
      _id: id,
      image: image,
      description: data.description,
      isFavourite: isFavourite,
    };
    ApiPost(`admin/updatehighlight`, payload)
      .then((res) => {
        if (res.status === 200) {
          let checkError = ErrorHandler(res);
          if (checkError) {
            toast.success(res.message);
            setModalOpen(false);
            getHighlightStocks();
          }
        }
      })
      .catch((err) => {
        console.log("err ", err);
      })
      .finally(() => {
        setLoading(false);
        clearState();
      });
  };

  const handleDescriptionChange = (e) => {
    setData({ ...data, description: e.target.value });
    setErrors({ ...errors, description: "" });
  };

  const getHighlightStocks = () => {
    setLoading(true);
    ApiGet(`admin/gethighlight?limit=${itemsPerPage}&page=${page}`)
      .then((res) => {
        SetHighlightStocks(res?.data?.response);
        setTotalDataCount(res.data.highlightData);
      })
      .catch((err) => {
        console.log(err, "res");
      })
      .finally(() => setLoading(false));
  };
  const handleEdit = (data) => {
    setModalOpen(true);
    setId(data._id);
    setData({
      ...data,
      description: data.description,
    });
    setImage(data.image);
    setIsFavourite(data.isFavourite);
  }
  const checkApiCall = () => {
    let checkPage = Math.ceil((totalDataCount - 1) / itemsPerPage);
    if (checkPage != page && page !== 1) {
      setPage(checkPage);
    } else {
      getHighlightStocks();
    }
  };

  const onDeleteHandler = () => {
    setLoading(true);
    ApiDelete(`admin/deletehighlight/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const checkError = ErrorHandler(res);
          if (checkError) {
            setConfirmationModal(false);
            toast.success(res.data.message);
            checkApiCall();
          }
        }
      })
      .catch((err) => {
      })
      .finally(() => setLoading(false), setId(null));
  };

  const viewModalClick = (data) => {
    setViewModal(true);
    setData({
      ...data,
      description: data.description,
    });

  }

  const handleClose = () => {
    setConfirmationModal(false);
  };
  const pageChange = (e, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getHighlightStocks();
  }, [page]);
  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Highlight Stocks
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add Highlight Stock
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "53%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Grid container spacing={3} mt={1}>
          {HighlightStocks &&
            HighlightStocks.length > 0 &&
            HighlightStocks.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card className={classes.cardContent}>
                    {/* <CardMedia
                        sx={{ height: 140 }}
                        image={item?.image}
                        alt="green iguana"
                      /> */}
                    <Box
                      sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={item.image}
                        alt="Full Image"
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          height: 'auto',
                          minHeight: '140px',
                          maxHeight: '140px'
                        }}
                      />
                    </Box>
                    <CardContent>
                      <Typography className={classes.cardContent}>{item.description}</Typography>
                    </CardContent>

                    <CardActions className={classes.cardActions}>
                      <Stack
                        direction="row"
                        spacing={0}
                        alignItems="flex-end"
                        style={{ marginLeft: "auto" }}
                      >
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => { viewModalClick(item) }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setId(item._id);
                            setConfirmationModal(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>

                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          {HighlightStocks?.length === 0 &&
            <Box mt={2} p={2} width={'100%'}>
              <NoDataFound />

            </Box>}
        </Grid>
      )}
      {HighlightStocks.length > 0 && totalDataCount > itemsPerPage && (
        <Box
          mt={"2rem"}
          width={"100%"}
          display={"block"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Pagination
            count={Math.ceil(totalDataCount / itemsPerPage)}
            onChange={pageChange}
            page={page}
          />
        </Box>
      )}

      <ConfirmationModal
        handleClose={() => setConfirmationModal(false)}
        open={confimationModal}
        onDeleteHandler={() => onDeleteHandler()}
        headTitle={"Are you sure you want to delete this highlight stock?"}
        loading={loading}
      />
      <ViewModal
        open={viewModal}
        buttonText={"Close"}
        content={
          <Box maxWidth={"100%"} style={{ overflowX: 'hidden' }}>
            <Typography>{data.description}</Typography>
          </Box>
        }
        handleClose={viewModalCloseHandler}
        title={"View Description"}
      />

      <Modal
        open={modalOpen}
        handleClose={onCloseHandler}
        title={id ? "Edit Hightlight Stocks" : "Add Highlight Stocks"}
        savetext={id ? "Update" : "Add"}
        cancelText={"Cancel"}
        handleReject={onCloseHandler}
        handleApprove={id ? editHandler : addStock}
        loading={loading}
        content={
          <>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <CommonTextField
                  text="Name"
                  type="text"
                  placeholder={"Please Enter Stock Name"}
                  name={"name"}
                  value={data?.name}
                  onChange={(e) => {
                    setData({ ...data, [e?.target?.name]: e?.target?.value });
                  }}
                />
                {
                  errors.name && (
                    <Typography sx={{ fontSize: "12px", color: "red" , mt:'0.7rem' }}>
                    {errors.name}
                  </Typography>
                  )
                }
                
              </Grid> */}

              <Grid item xs={12}>
                <Typography
                  sx={{
                    marginRight: "3px",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: theme.palette.primary.main,
                    padding: "3px",
                  }}
                >
                  Description
                </Typography>
                <Box>
                  <CommonTextField
                    onChange={handleDescriptionChange}
                    value={data?.description}
                    multiline
                    rows={4}
                    height={'100%'}

                  />
                </Box>
                {errors.description && (
                  <Typography
                    sx={{ fontSize: "12px", color: "red", }}
                  >
                    {errors.description}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFavourite}
                      onChange={(e) => setIsFavourite(e.target.checked)}
                      name="isFavourite"
                      color="primary"
                    />
                  }
                  label="Is Favourite"
                />
              </Grid>
              <Grid item xs={12}>
              <Typography
                    sx={{
                      marginRight: "3px",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    Upload Image
                  </Typography>
              </Grid>
              <Grid
                item
                xs={image ? 9 : 12}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                <>
                  <input {...getInputProps()} />
                  <Card
                    style={{
                      padding: "20px",
                      border: `1px dashed ${theme.palette.primary.main}`,
                      textAlign: "center",
                      boxShadow: "none",
                    }}
                  >
                    <Box sx={{ color: theme.palette.primary.main }}>
                      <CloudUploadIcon fontSize="large" />
                      <Typography>
                        {disabledDropzone ? "Image is uploading..." : ` Drag and drop some images here or select only .jpg ,
                        .jpeg ,.png images.`}
                      </Typography>
                    </Box>
                  </Card>
                  {errors.imgLink && (
                    <Typography
                      sx={{ fontSize: "12px", color: "red" }}
                    >
                      {errors.imgLink}
                    </Typography>
                  )}
                  {errors.empty && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.empty}
                    </Typography>
                  )}
                  {errors.wrong && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.wrong}
                    </Typography>
                  )}
                  {errors.large && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.large}
                    </Typography>
                  )}
                </>
              </Grid>
              {image && (
                <>
                  <Grid item xs={3}>
                    {/* <Card style={{ position: "relative" }}>
                    <CardMedia
                      style={{ paddingTop: "56.25%" }}
                      image={image}
                      title="Uploaded Image"
                    >
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 8,
                          zIndex: 1,
                        }}
                        onClick={() => {
                          setImage("");
                        }}
                      >
                        <CloseIcon
                          sx={{
                            background: "white",
                            color: "red",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </CardMedia>
                  </Card> */}

                    <Box sx={{
                      position: "relative",
                      background: `url(${image})`,
                      height: "130px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: 'center',
                      borderRadius: "5px",
                      overflow: "hidden",
                      "&:hover .hoverButtons": {
                        opacity: 1,
                      },
                    }}>
                      <Box
                        className="hoverButtons"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          opacity: 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <Box onClick={() => window.open(image, "_blank")} height={"25px"} width={"25px"} borderRadius={"50%"} bgcolor={theme.palette.bgWhite.main} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "42%", right: "42%", cursor: 'pointer' }}>
                          <VisibilityIcon sx={{ color: theme.palette.bgBlack.main, fontSize: "20px" }} />
                        </Box>
                        <Box
                          onClick={() => {
                            setImage("");
                          }} height={"25px"} width={"25px"} borderRadius={"50%"} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "10px", right: "10px", cursor: 'pointer' }}>
                          <DeleteIcon sx={{ color: theme.palette.error.main, fontSize: "20px" }} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            {showLogoPicker &&
              <CustomDialog
                isOpen={showLogoPicker}
                handleClose={() => {
                  setShowLogoPicker(false);
                  setShowCropper(false);
                  setUploadedLogo(null);
                }}

              >
                <DialogTitle sx={{ m: 0, p: 1 }}>Image Crooper</DialogTitle>
                <DialogContent sx={{ width: { lg: "700px", md: "700px", sm: "600px", xs: "400px" }, }} dividers >
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <div style={{ width: "100%", position: 'relative', }}>
                        <Cropper
                          style={{ height: 300, width: "100%" }}
                          src={uploadedLogo}
                          viewMode={0}
                          background={false}
                          responsive={false}
                          minCropBoxHeight={20}
                          minCropBoxWidth={10}
                          dragMode="move"
                          autoCropArea={1}
                          checkOrientation={false}
                          cropBoxResizable={true}
                          onInitialized={(instance) => {
                            setCropper(instance);
                          }}
                          guides={false}
                        />

                        {/* <IconButton
                          component="span"
                          style={{
                            justifyContent: "center", alignSelf: "center", position: "absolute", top: 0, right: 0,
                          }}
                          onClick={() => {
                            setShowCropper(false);
                            setUploadedLogo(null);
                            setInitialLogo(null);
                            setShowLogoPicker(false)
                          }}
                        >
                          <DeleteIcon style={{ color: theme.palette.bgWhite.main, marginTop: 10 }} />
                        </IconButton> */}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display={"flex"} justifyContent={"end"} gap={1}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowCropper(false);
                            setUploadedLogo(null);
                            setInitialLogo(null);
                            setShowLogoPicker(false)
                          }}
                          color="error"
                          sx={{ borderRadius: 5 }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          color="primary"
                          variant="contained"
                          sx={{ borderRadius: 5 }}
                          disableElevation
                          onClickHandler={() => { getCropData(); }}
                          text="Crop"
                          loading={disabledDropzone}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
              </CustomDialog>
            }
          </>
        }
      />
    </>
  );
};


export default HighlightStocks;