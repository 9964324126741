import React, { useEffect } from "react";
import AuthLayout from "../layout/AuthLayout";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Layout from "../layout/Layout";
import Login from "./login/Login";
import Error from "./error/Error";
import AuthStorage from "../helper/AuthStorage";
import STORAGEKEY from "../config/APP/app.config";
import CallsTerm from "./callsTerm/CallsTerm";
import Users from "./users/Users";
import AddCallTerm from "./callsTerm/AddCallTerm";
import Subscription from "./subscription/Subscription";
import Add from "./subscription/Add";
import ReferralAdd from "./subscription/ReferralAdd";
import Feedback from "./feedback/Feedback";
import TabTable from "./stock-type/TabTable";
import AddOffers from "./Offers/AddOffers";
import VideoLibrary from "./videos/VideoLibrary";
import ReaportedFeedback from "./feedback/ReaportedFeedback";
import News from "./news/News";
import MutualFunds from "./mutualFunds/MutualFunds";
import AddEditNews from "./news/AddEditNews";
import HighlightStocks from "./highlight-stocks/HighlightStocks";

import ViewNews from "./news/ViewNews";
import Accuracy from "./pastAccuracy/Accuracy";
import GainerLooser from "./gainer-looser/GainerLooser";
import AddGainerLooser from "./gainer-looser/AddGainerLooser";
import EditGainerLooser from "./gainer-looser/EditGainerLooser";
import ContactUs from "./contact-us/ContactUs";
import AppVersion from "./appVersion/AppVersion";
import PromotionNotification from "./promotion-notification";
import IosUsers from "./Ios-users/IosUsers";
import ViewMutualFund from "./mutualFunds/ViewMutualFund";
import PromotionVideos from "./promotion-videos/PromotionVideos";
import PromotionVideosAndNotifications from "./PromotionNotification&Videos/PromotionVideosAndNotifications";
import NewsMaster from "./news-master/NewsMaster";
const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { pathname } = { ...location };

  let publicRoute = ["/"];
  let privateRoute = [
  "/dashboard",
  "/calls-term",
  "/calls-term/add",
  "/users",
  "/calls-term/edit/:id",
  // "/subscription",
  // "/subscription/add",
  "/feedback",
  "/reported-feedback",
  // "/subscription/edit/:id",
  "/tabs",
  // "/offers",
  "/referral/add",
  //  "/videos",
  "/news",
  "/news-master",
  "/mutualFunds",
  "//mutualFunds/view/:id",
  "/news/view/:id",
  "/highlight-stocks",
  "/pastAccuracy",
  "/videos",
  "/gainer-loosers",
  "/contact-us",
  "/promotion-notification",
 /*  "/ios-users" */,
 "/promotion-videos",
 "/promotion-notification-videos",
];


  useEffect(() => {
    if (
      AuthStorage.getStorageJsonData(STORAGEKEY.userData) &&
      AuthStorage.getToken()
    ) {
      if (
        privateRoute.includes(pathname) ||
        pathname.includes("edit/") ||
        pathname.includes("view/")
      ) {
        navigate(pathname);
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, []);

  if (publicRoute.includes(pathname)) {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </Layout>
    );
  } else if (
    privateRoute.includes(pathname) ||
    pathname.includes("edit/") ||
    pathname.includes("view/")
  ) {
    return (
      <AuthLayout>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/calls-term" element={<CallsTerm />} />
          <Route path="/calls-term/add" element={<AddCallTerm />} />
          <Route path="/calls-term/edit/:id" element={<AddCallTerm />} />
          <Route path="/users" element={<Users />} />
          {/* <Route path="/subscription" element={<Subscription />} /> */}
          {/* <Route path="/subscription/add" element={<Add />} /> */}
          {/* <Route path="/subscription/edit/:id" element={<Add />} /> */}
          <Route path="/referral/add" element={<ReferralAdd />} />
          <Route path="/referral/edit/:id" element={<ReferralAdd />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/reported-feedback" element={<ReaportedFeedback />} />
          <Route path="/tabs" element={<TabTable />} />
          {/* <Route path="/offers" element={<AddOffers />} /> */}
          <Route path="/highlight-stocks" element={<HighlightStocks />} />
          {/* <Route path="/videos" element={<VideoLibrary />} /> */}
          <Route path="/news" element={<News />} />
          <Route path="/news-master" element={<NewsMaster />} />

          <Route path="/mutualFunds" element={<MutualFunds />} />
          <Route path="/mutualFunds/view/:id" element={<ViewMutualFund/>}/>
          <Route path="/news/view/:id" element={<ViewNews />} />
          <Route path="/pastAccuracy" element={<Accuracy />} />
          <Route path="/gainer-loosers" element={<GainerLooser />} />
          
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/promotion-notification" element={<PromotionNotification />} />
          <Route path="/promotion-videos" element={<PromotionVideos/>}/>
          <Route path="/promotion-notification-videos" element={<PromotionVideosAndNotifications/>}/>
          {/* <Route path="/ios-users" element={<IosUsers/>} /> */}

        </Routes>
      </AuthLayout>
    );
  } else {
    return (
      <Routes>
        <Route path="*" element={<Error />} />
      </Routes>
    );
  }
};

export default Index;
