import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  TablePagination,
  TextField,
  Tab,
  Stack,
} from "@mui/material";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import Loader from "../../common/Loader";
import moment from "moment";
import CommonTextField from "../../common/commonTextField";
import NoDataFound from "../../common/noDataFound";
import ErrorHandler from "../../helper/errorHandler";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "../../helper/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  search_input_field: {
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      width: 250,
      height: 50,
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  datePicker: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "14px",
      color: theme.palette.primary.main, // Default label color
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      lineHeight: "14px",
      height: "43px",
      color: theme.palette.text.primary, // Input font color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main, // Default outline color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Outline color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main, // Outline color when focused
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.main, // Label color when focused
    },
  },
}));

const Users = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const urlPrams = new URLSearchParams(location.search);
  const isComingFromDashboard = urlPrams.get('subscribedUser');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(null);

  const today = dayjs();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };


  const handleSearch = debounce((searchValue) => {
    setSearchValue(searchValue);
    setPage(0);
  }, 500);
  const getPayload = () => {
    switch (value) {
      case "0":
        return {
          page: page + 1,
          limit: rowsPerPage,
          sortKey: "subscriptionDate",
        };
      case "1":
        return {
          page: page + 1,
          limit: rowsPerPage,
          tabValue: [1, 4],
          sortKey: "subscriptionDate",
        };
      case "2":
        return {
          page: page + 1,
          limit: rowsPerPage,
          tabValue: [16, 14],
          sortKey: "createdAt",
        };
      case "3":
        return {
          page: page + 1,
          limit: rowsPerPage,
          tabValue: [3],
          sortKey: "createdAt",
        };
      case "4":
        return {
          page: page + 1,
          limit: rowsPerPage,
          tabValue: [2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 17],
          sortKey: "createdAt",
        };
      default:
        return {};
    }
  };

  const fetchUsers = () => {
    setLoading(true);
    let body = getPayload();
    if (searchValue) {
      body.search = searchValue;
    }
    if (selectedDate) {
      // body.date = dayjs(new Date(selectedDate)).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
      body.date = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    ApiPost(`admin/users`, body)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          setUsers(res?.data?.response);
          setCount(res?.data?.count);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    setPage(0);
  };
  const tabChangeHandler = (e, newValue) => {
    setValue(newValue);
    setPage(0);
  };
  useEffect(()=>{
    if(isComingFromDashboard){
      setValue("1")
    }
  },[])
  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, searchValue, value, selectedDate]);

  return (
    <Box>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          Users
        </Typography>
        <Stack direction={'row'} spacing={3}>

          <Box display="flex" alignItems="center" gap={1} minWidth={"275px"}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              size="small"
              label="Filter by Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              className={classes.datePicker}
              format="DD/MM/YYYY"
              maxDate={today}
            />
          </LocalizationProvider> */}
            {selectedDate && (
              <CancelIcon
                onClick={() => setSelectedDate(null)}
                sx={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  ml: 1,
                }}
              />
            )}
          </Box>

          <CommonTextField
            fontWeight={600}
            placeholder={"Search User..."}
            type="text"
            name="search"
            onChange={(e) => handleSearch(e.target.value)}
            width={250}
            inputProps={{ className: classes.placeholder }}
          />
        </Stack>
      </Box>
      <Box>
        <TabContext value={value}>
          <Box
            className={classes.tab_main}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              onChange={tabChangeHandler}
              aria-label="lab API tabs example"
            >
              <Tab sx={{ fontWeight: "600" }} label="All" value="0" />
              <Tab
                sx={{ fontWeight: "600" }}
                label="Subscription Users"
                value="1"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                label="Pending Subscription Users"
                value="2"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                label="Cancel Subscription Users"
                value="3"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                label="Error Subscription Users"
                value="4"
              />
            </TabList>
          </Box>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ marginTop: "15px" }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">No.</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Number</StyledTableCell>
                  <StyledTableCell>Platform Type</StyledTableCell>

                  {value == "1" && (
                    <StyledTableCell>Subscription</StyledTableCell>
                  )}
                  {value == "1" && <StyledTableCell>User Plan</StyledTableCell>}
                  {value == "1" && (
                    <StyledTableCell> Subscription Date</StyledTableCell>
                  )}
                  {value == "1" && (
                    <StyledTableCell> Subscription Expiry Date</StyledTableCell>
                  )}
                  {value == "0" && (
                    <StyledTableCell>Subscription</StyledTableCell>
                  )}
                  <StyledTableCell>Created Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell
                      colSpan={value == "1" ? 11 : value === "0" ? 8 : 7}
                    >
                      <Loader />
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  <>
                    {users?.length > 0 ? (
                      users?.map((user, index) => (
                        <StyledTableRow key={user?._id}>
                          <StyledTableCell align="center">
                            {page * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Avatar
                                sx={{ border: "1px solid gray" }}
                                src={user?.profileImage ? user?.profileImage : user?.name}
                                imgProps={{ loading: "lazy" }}
                                alt={user?.name}
                              ></Avatar>
                              {user?.name.toUpperCase()}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>{user?.email}</StyledTableCell>
                          <StyledTableCell>{user?.mobileNo}</StyledTableCell>
                          <StyledTableCell>{user?.platformType === 1 ? "Android" : user?.platformType ===2 ? "iOS" : "--"}</StyledTableCell>

                          {value == "1" && (
                            <StyledTableCell
                              style={{
                                color: user?.isSubscribe ? "Green" : "Red",
                              }}
                            >
                              {user?.isSubscribe ? "Active" : "Inactive"}
                            </StyledTableCell>
                          )}
                          {value == "0" && (
                            <StyledTableCell
                              style={{
                                color: user?.isSubscribe ? "Green" : "Red",
                              }}
                            >
                              {user?.isSubscribe ? "Active" : "Inactive"}
                            </StyledTableCell>
                          )}
                          {value == "1" && (
                            <StyledTableCell>
                              {user?.subscriptionsDetails?.subscriptionType}
                            </StyledTableCell>
                          )}
                          {value == "1" && (
                            <StyledTableCell>
                              {user?.planHistories?.planStartDate ? (
                                moment(
                                  user?.planHistories?.planStartDate
                                ).format("DD/MM/YYYY")
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </StyledTableCell>
                          )}
                          {value == "1" && (
                            <StyledTableCell>
                              {user?.planHistories?.planStartDate ? (
                                moment(user?.planHistories?.planEndDate).format(
                                  "DD/MM/YYYY"
                                )
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </StyledTableCell>
                          )}
                          <StyledTableCell>
                            {moment(user?.createdAt).format("DD/MM/YYYY")}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={12}>
                          <NoDataFound elevation={0} />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {count > 0 && (
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            )}
          </TableContainer>
        </TabContext>
        <TabContext></TabContext>
      </Box>
    </Box>
  );
};

export default Users;
