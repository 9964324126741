export const responseEnum = {
    SuccessCode: 200,
    ValidationCode404: 404,
    ValidationCode400: 400,
    ValidationCode409: 409,
    InternalServerCode: 500,
    UnAuth: 401,
    PageRequiredCode : 400
}

export const sentNotificationEnum = {
    all: 0,
    subscribedUser: 1,
    non_subscribedUser: 2,
}

export const newsFilterTopicsEnum = {
        Blockchain: "blockchain",
        Earnings: "earnings",
        IPO: "ipo",
        MergersAndAcquisitions: "mergers_and_acquisitions",
        FinancialMarkets: "financial_markets",
        EconomyFiscal: "economy_fiscal",
        EconomyMonetary: "economy_monetary",
        EconomyMacro: "economy_macro",
        EnergyTransportation: "energy_transportation",
        Finance: "finance",
        LifeSciences: "life_sciences",
        Manufacturing: "manufacturing",
        RealEstate: "real_estate",
        RetailWholesale: "retail_wholesale",
        Technology: "technology" 
}