// actions.js
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { FETCH_FEEDBACK_FAILURE , FETCH_FEEDBACK_SUCCESS , FETCH_FEEDBACK_REQUEST } from "./feedbackContant";

export const fetchFeedbackRequest = () => ({
  type: FETCH_FEEDBACK_REQUEST,
});

export const fetchFeedbackSuccess = (feedback) => ({
  type: FETCH_FEEDBACK_SUCCESS,
  payload: feedback,
});

export const fetchFeedbackFailure = (error) => ({
  type: FETCH_FEEDBACK_FAILURE,
  payload: error,
});


export const fetchAllFeedback = (tab = 0 , page = 1 , limit = 1)=>{
    return async (dispatch)=>{
        dispatch(fetchFeedbackRequest())
        try{
            const res =await ApiGet(`admin/getFeedback?page=${page}&limit=${limit}&isVerify=${tab}`)
            if(res.status == 200){
                dispatch(fetchFeedbackSuccess(res.data))
            }else{
                dispatch(fetchFeedbackFailure(res.message))
            }
            return res;
        }catch(err){
            dispatch(fetchFeedbackFailure(err?.message))
            throw err
        }
    }
}

export const handleApproveFeedback = (data)=>{
    return async(dispatch)=>{
      try{
        return await ApiPost('admin/approveFeedback', data)

      }catch(err){
        throw err
      }
    }
}