import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  Checkbox,
  FormControlLabel,
  TableRow,
  Switch,
  Input,
  InputLabel,
  Pagination,
} from "@mui/material";
import CommonTextField from "../../common/commonTextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader";
import ErrorHandler from "../../helper/errorHandler";
import Modal from "../../common/Modal";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import ConfirmationModal from "../../common/ConfirmationModal";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import NoDataFound from "../../common/noDataFound";
import { BorderBottom, Image, Label } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: "Poppins",
    padding: 6,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
    color: theme.palette.bgBlack.main,
  },
}));

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contained_button: {
    background: theme.palette.primary.main,
    textTransform: "none !important",
    fontWeight: "700 !important",
  },
  input: {
    display: "none",
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none !important",
  },
  video: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  cardContent: {
    padding: theme.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingBottom: "0 !important",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  nameText: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  dropZone: {
    padding: theme.spacing(2),
    border: `2px dashed ${theme.palette.primary.main}`,
    textAlign: "center",
    cursor: "pointer",
  },
  videoPreview: {
    width: "100%",
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 600,
    },
  },
  inputFile: {
    "&::before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0) !important",
    },
    "&::after": {
      borderBottom: "2px solid rgba(0, 0, 0, 0)   !important",
    },

  },
  inputLabel: {
    color: `${theme?.palette?.bgBlack?.main} !important`,
    marginRight: "3px !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    padding: "3px !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const VideoLibrary = () => {
  const theme = useTheme();
  const classes = useStyles();

  const itemsPerPage = 8;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoData, setVideoData] = useState({
    video: null,
    videoLink: "",
    name: "",
    status: 0,
  });
  const [errors , setErrros] = useState({
    video: null,
    videoLink: "",
    name: "",
    thumbnail : "",
  })
  const [isEmpty, setIsEmpty] = useState("");
  const [isLarge, setIsLarge] = useState("");
  const [isWrong, setIsWrong] = useState("");
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [nameError, setNameError] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [thumbnailError , setThumbnailError] = useState("");
  const[page , setPage] = useState(1);
  const[totalCount , setTotalCount] = useState(null);
  const[videoLoader , setVideoLoader] = useState(false);
  const[thumbnailLoader , setThumbnailLoader] = useState(false);

  const handleVideoClick = (videoId) => {
    const updatedData = data.map((item) => {
      if (item._id == videoId) {
        return { ...item, showControls: !item.showControls };
      }
      return item;
    });
    setData(updatedData);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsEdit(false);
    setVideoData({ video: null, videoLink: "", name: "", status: 0 });
    setNameError("");
    setIsEmpty("");
    setThumbnailError("");
    setThumbnailUrl("");
    setErrros({
      video: "",
      videoLink: "",
      name: "",
      thumbnail : "",
    })
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();

    setIsWrong("");
    setIsEmpty("");
    setIsLarge("");

    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setVideoLoader(true)

    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setVideoData((prev) => ({ ...prev, videoLink: res?.data?.image }));
        setVideoLoader(false)
        setErrros({...errors , video : ""})
        const reader = new FileReader();
        reader.onload = () => {
          setVideoData((prev) => ({ ...prev, video: reader.result }));
        };
        reader.readAsDataURL(file);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(()=>setVideoLoader(false));
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setIsWrong(`Only videos are allowed`);
        } else if (error.code === "file-too-large") {
          setIsLarge(`File size should be less than 50 MB`);
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/avi": [".avi"],
      "video/x-matroska": [".mkv"],
    },
    onDrop,
    onDropRejected,
    maxSize: 52428800, // 50 MB limit,
    disabled : videoLoader
  });

  const handleThumbnailDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();

    formData.append("image", file);
    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };
    setThumbnailLoader(true)
    ApiPost("upload/image", formData, headers)
      .then((res) => {
        setThumbnailUrl(res?.data?.image);
        setErrros({...errors , thumbnail : ""})
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(()=>setThumbnailLoader(false));
  };

  const handleThumbnailDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      const { file, errors } = fileRejection;
      errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          setThumbnailError(`Only images are allowed`);
        } else if (error.code === "file-too-large") {
          setThumbnailError(`File size should be less than 5 MB`);
        }
      });
    });
  };

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
    isDragActive: isThumbnailDragActive,
  } = useDropzone({
    accept: "image/*",
    onDrop: handleThumbnailDrop,
    onDropRejected: handleThumbnailDropRejected,
    maxSize: 5242880, // 5 MB limit
    disabled : thumbnailLoader
  });


  const getlearningVideo = () => {
    setLoading(true);
    ApiGet(`admin/getlearningVideo?limit=${itemsPerPage}&page=${page}`)
      .then((res) => {
        const error = ErrorHandler(res);
        if (error) {
          if (res?.data) {
            setData(res?.data.response);
            setTotalCount(res?.data?.video);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => setLoading(false));
  };

  const validation = ()=>{
    let flag = false;
    const obj = {};
    if(videoData.name === ""){
      obj.name = "Please enter the name !";
      flag = true;
    }
    if(videoData.videoLink === ""){
      obj.video = "Please select the video !";
      flag = true;
    }
    if(thumbnailUrl === ""){
      obj.thumbnail = "Please select the thumbnail !";
      flag = true;
    }
    setErrros(obj);
    return flag;
  }

  const handleSubmit = () => {
      if(validation()) {
        return;
      }
      setLoading(true);
      const data = {
        url: videoData.videoLink,
        name: videoData.name,
        status: Number(videoData.status),
        thumbnail: thumbnailUrl,
      };
      ApiPost(`admin/learningVideo?id=${id}`, data)
        .then((res) => {
          const error = ErrorHandler(res);
          if (error && res.message) {
            toast.success(res.message || "Success");
            setVideoData({ video: null, videoLink: "", name: "", status: 0 });
            setThumbnailUrl("")
            setOpen(false);
            getlearningVideo();
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
  
  };

  const handleEdit = () => {
      if(validation()){
        return;
      }
      setLoading(true);
      const data = {
        id: id,
        url: videoData.videoLink,
        name: videoData.name,
        status: Number(videoData.status),
        thumbnail: thumbnailUrl
      };
      ApiPut("admin/editLearningVideo", data)
        .then((res) => {
          const error = ErrorHandler(res);
          if (error) {
            toast.success(res.data.message || "Success");
            setVideoData({ video: null, videoLink: "", name: "", status: 0 });
            setOpen(false);
            setIsEdit(false);
            setThumbnailUrl("")
            getlearningVideo();
          }
        })
        .catch((err) => {
          // toast.error(err?.message);
        })
        .finally(() => {
          setLoading(false);
          setIsEdit(false);
        });
  };
  const handleDelete = async () => {
    setLoading(true);
    ApiDelete(`admin/deleteLearningVideo?id=${id}`)
      .then(async (res) => {
        let error = ErrorHandler(res);
        if (error) {
          toast.success(res?.data?.message || "Video deleted successfully");
          setConfirmationModal(false);
          getlearningVideo();
        }
      })
      .catch((error) => {
        // toast.error(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const EditHandler = (_id) => {
    setIsEdit(true);
    setId(_id);
  };

  const onFileSelect = (event) => {
    const selectedImage = event.target.files[0];
    const formData = new FormData();
    formData.append("image", selectedImage);

    const headers = {
      isAuth: true,
      AdditionalParams: {},
      isJsonRequest: false,
      isMultiPart: true,
    };

    ApiPost("upload/image", formData, headers)
      .then((res) => {
        let responseWithError = ErrorHandler(res);
        if (responseWithError) {
          setThumbnailUrl(res?.data?.image);
          setThumbnailError("")
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnailUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const switchToggleHandler = (e) => {
    setVideoData({ ...videoData, status: !videoData.status })
  }

  const DeleteHandler = (_id) => {
    setConfirmationModal(true);
    setId(_id);
  };
  const pageChange = (e , newPage)=>{
    setPage(newPage)
  }

  useEffect(() => {
    getlearningVideo();
  }, [page]);

 

  useEffect(() => {
    if (isEdit) {
      const videoToEdit = data.find((video) => video._id === id);
      if (videoToEdit) {
        setVideoData({
          video: videoToEdit.url,
          videoLink: videoToEdit.url,
          name: videoToEdit.name,
          status: videoToEdit.status,
        });
        setThumbnailUrl(videoToEdit?.thumbnail)
      }
      setOpen(true);
    }
  }, [isEdit, id, data]);


  return (
    <>
      <Box>
        <Box className={classes.header_main}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Videos
          </Typography>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add Video
          </Button>
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "53%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <StyledTableCell colSpan={8}>
                <Loader />
              </StyledTableCell>
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                    <Card className={classes.card}>
                      <video
                        className={classes.video}
                        id={`video-${index}`}
                        onClick={() => handleVideoClick(item._id)}
                        controls={item.showControls || false}
                        poster={item?.thumbnail}
                      >
                        <source src={item.url} type="video/mp4" />
                        <source src={item.url} type="video/avi" />
                        <source src={item.url} type="video/x-matroska" />
                        Your browser does not support the video tag.
                      </video>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          variant="body1"
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span className={classes.nameText}>Name:</span>
                          {item.name}
                        </Typography>
                        <Typography variant="body1">
                          <span className={classes.nameText}>Status:</span>{" "}
                          {item.status ? "Hide" : "Show"}
                        </Typography>
                      </CardContent>
                      <Box display={"flex"} justifyContent={"flex-end"}>
                        <CardActions className={classes.cardActions}>
                          <IconButton
                            onClick={() => EditHandler(item._id)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => DeleteHandler(item._id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CardActions>
                      </Box>
                    </Card>
                  </Grid>
                ))
              ) : (
                // <Typography variant="body1" align="center" margin={'auto'}>
                //   No videos available.
                // </Typography>
                <Grid container m={5}>
                  <NoDataFound elevation={0} />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Modal
          open={open || isEdit}
          handleClose={onCloseHandler}
          title={isEdit ? "Edit Video" : "Add Video"}
          savetext={"Submit"}
          cancelText={"Cancel"}
          handleReject={onCloseHandler}
          handleApprove={isEdit ? handleEdit : handleSubmit}
          loading={loading}
          content={
            <>
              <Grid container spacing={2} px={1}>
                
                <Grid
                  item
                  xs={12}
                  {...getRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  
                    <>
                      <Typography
                        sx={{ fontSize: "12px", color: "red", mb: 1 }}
                      >
                        Note :- Please select .mp4, .avi, or other video formats
                      </Typography>
                      <Card
                        style={{
                          padding: "20px",
                          border: `1px dashed ${theme.palette.primary.main}`,
                          textAlign: "center",
                          boxShadow: "none"
                        }}
                      >
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <div style={{ color: theme.palette.primary.main }}>
                            <CloudUploadIcon fontSize="large" />
                            {
                              videoLoader ? (<p>Video is uploading...</p>) : ( <p>
                                Drag 'n' drop some files here, or click to select
                                files
                              </p>)
                            }
                           
                          </div>
                        )}
                      </Card>
                      {/* <Typography sx={{ fontSize: "12px", color: "red" }}>
                        * Please select .mp4, .avi, or other video formats
                      </Typography> */}
                      {isWrong && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {isWrong}
                        </Typography>
                      )}
                      {isLarge && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {isLarge}
                        </Typography>
                      )}
                      {
                        errors.video && (
                          <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.video}
                        </Typography>
                        )
                      }
                    </>
    
                </Grid>
                {videoData.video && (
                  <>
                    <Grid item xs={12}>
                      <Card style={{ position: "relative" }}>
                        <video controls style={{ width: "100%" }}>
                          <source src={videoData.videoLink} type="video/mp4" />
                          <source src={videoData.videoLink} type="video/avi" />
                          <source
                            src={videoData.videoLink}
                            type="video/x-matroska"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <IconButton
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 8,
                            zIndex: 1,
                          }}
                          onClick={() => {
                            setVideoData({ video: null, videoLink: "" });
                          }}
                        >
                          <CloseIcon
                            sx={{
                              background: "white",
                              color: "red",
                              borderRadius: "50%",
                            }}
                          />
                        </IconButton>
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <CommonTextField
                    fontWeight={500}
                    text={"Video Name"}
                    placeholder={"Enter Video Name"}
                    type="text"
                    name="name"
                    value={videoData.name}
                    onChange={(e) => {
                      setVideoData({ ...videoData, name: e.target.value });
                      setErrros({...errors , name : ""})
                    }}
                    inputProps={{ className: classes.placeholder }}
                  // error={Boolean(nameError)}
                  // helperText={nameError}
                  />
                  {errors.name && (
                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                      {errors.name}
                    </Typography>
                  )}
                </Grid>
                
                <Grid
                  item
                  xs={12}
                  {...getThumbnailRootProps()}
                  style={{ cursor: "pointer" }}
                >
                  <InputLabel
                    className={classes.inputLabel}
                  >
                    Select Thumbnail
                  </InputLabel>
                  <input {...getThumbnailInputProps()} />
                    <>
                      <Card
                        style={{
                          padding: "20px",
                          border: `1px dashed ${theme.palette.primary.main}`,
                          textAlign: "center",
                          boxShadow: "none"
                        }}
                      >
                        {isThumbnailDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <div style={{ color: theme.palette.primary.main }}>
                            <CloudUploadIcon fontSize="large" />
                            {
                              thumbnailLoader ? (<p>Image is uploading...</p>) : ( <p>
                                Drag 'n' drop a thumbnail image here, or click to select a file (JPG, PNG)
                              </p>)
                            }
                          </div>
                        )}
                      </Card>
                     
                      {isWrong && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {isWrong}
                        </Typography>
                      )}
                      {isLarge && (
                        <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {isLarge}
                        </Typography>
                      )}
                      {
                        errors.thumbnail && (
                          <Typography sx={{ fontSize: "12px", color: "red" }}>
                          {errors.thumbnail}
                        </Typography>
                        )
                      }
                    </>
                </Grid>
                {thumbnailUrl  && (
                  <>
                    <Grid item xs={12}>
                      <Card style={{ position: "relative" }}>
                      <img src={thumbnailUrl} alt="Thumbnail Preview" style={{ width: "100%" }} />
                        <IconButton
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 8,
                            zIndex: 1,
                          }}
                          onClick={() => setThumbnailUrl("")}
                        >
                          <CloseIcon
                            sx={{
                              background: "white",
                              color: "red",
                              borderRadius: "50%",
                            }}
                          />
                        </IconButton>
                      </Card>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!videoData.status}
                        onChange={switchToggleHandler}


                      />
                    }
                    label="Is Show"
                  />
                </Grid>
              </Grid>
            </>
          }
        />
        {data?.length > 0 && totalCount > itemsPerPage  && (
          <Box
            mt={"2rem"}
            width={"100%"}
            display={"block"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Pagination
              count={Math.ceil(totalCount/itemsPerPage)}
              onChange={pageChange}
              page={page}
            />
          </Box>
        )}
        <ConfirmationModal
          open={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onDeleteHandler={handleDelete}
          headTitle={"Are you sure you want to delete this Video ?"}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default VideoLibrary;
