import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { ApiDelete, ApiGet } from "../../helper/API/ApiData";
import Loader from "../../common/Loader";
import NoDataFound from "../../common/noDataFound";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    justifyContent: "center",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(3)
    },
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: 500,
    maxWidth: 500,
    objectFit: "cover",
  },
  content: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const AddEditNews = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [singleNewsData, setSingleNewsData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSingleNews = () => {
    setLoading(true);
    ApiGet(`admin/getblog/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setSingleNewsData(res.data.BlogById);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSingleNews();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : Object.keys(singleNewsData).length > 0 ? (
        <Box className={classes.container} gap={3}>
          <Box>
            <img
              src={singleNewsData?.image}
              title="News Image"
              alt="News Image"
              className={classes.image}
            />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Box className={classes.content}>
            <Typography variant="h4" gutterBottom>
              {singleNewsData?.title}
            </Typography>
            <Typography variant="body1">
              {singleNewsData?.description}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid container >
          <NoDataFound elevation={0} />
        </Grid>
      )}
    </>
  );
};

export default AddEditNews;
