import { Box, Grid, Typography, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import CommonTextField from "../../common/commonTextField";
import { ApiPost } from "../../helper/API/ApiData";
import { toast } from "react-toastify";
import UploadIcon from "@mui/icons-material/Upload";
import { ApiGet } from "../../helper/API/ApiData";

const useStyles = makeStyles((theme) => ({
  header_main: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tab_main: {
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modal_text_field: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: "1px",
    },
  },
  gradientDivider: {
    height: "2px",
    width: "60%",
    backgroundImage:
      "linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.1) 100%)",
    margin: "8px auto",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
  },
  imagePreview: {
    marginTop: "10px",
    height : '100px',
    width : '100px',
    borderRadius : '5px'
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const EditGainerLooser = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [gainersData, setGainersData] = useState([
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
  ]);

  const [loosersData, setLooserData] = useState([
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
    { name: "", change: "", symbol: "" },
  ]);

  const [errors, setErrors] = useState({});
  const [errorsForLoosers, setErrorsForLoosers] = useState({});

  const validateFields = () => {
    let valid = true;
    let newErrors = {};

    gainersData.forEach((item, index) => {
      if (!item.name) {
        valid = false;
        newErrors[`name-${index}`] = "Stock Name is required";
      }
      if (!item.change) {
        valid = false;
        newErrors[`change-${index}`] = "Stock Growth is required";
      }
      if (!item.symbol) {
        valid = false;
        newErrors[`image-${index}`] = "Image is required";
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const validateFieldsForLoosers = () => {
    let valid = true;
    let newErrors = {};

    loosersData.forEach((item, index) => {
      if (!item.name) {
        valid = false;
        newErrors[`name-${index}`] = "Stock Name is required";
      }
      if (!item.change) {
        valid = false;
        newErrors[`change-${index}`] = "Stock Growth is required";
      }
      if (!item.symbol) {
        valid = false;
        newErrors[`image-${index}`] = "Image is required";
      }
    });

    setErrorsForLoosers(newErrors);
    return valid;
  };

  const onChangeHandler = (event, index) => {
    const { name, value } = event.target;
    let newData = [...gainersData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };
    {
    }
    setGainersData(newData);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${name}-${index}`]: "",
    }));
  };

  const onChangeHandlerForLoosers = (event, index) => {
    const { name, value } = event.target;
    let newData = [...loosersData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };
    setLooserData(newData);

    setErrorsForLoosers((prevErrors) => ({
      ...prevErrors,
      [`${name}-${index}`]: "",
    }));
  };

  const fileChangeHandler = (event, index, type) => {
    const file = event.target.files[0];
    const formData = new FormData();

    setErrors({ wrong: "", empty: "", large: "" });

    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // 5 MB
        setErrors({
          ...errors,
          large: "File is too large. Please select a file smaller than 5 MB.",
        });
        return;
      }

      if (!file.type.startsWith("image/")) {
        setErrors({ ...errors, wrong: "Please select a valid image file." });
        return;
      }

      formData.append("image", file);
      const headers = {
        isAuth: true,
        AdditionalParams: {},
        isJsonRequest: false,
        isMultiPart: true,
      };

      ApiPost("upload/image", formData, headers)
        .then((res) => {
          const symbol = res?.data?.image;
          if (symbol) {
            if (type === "gainer") {
              setGainersData((prevData) => {
                const newData = [...prevData];
                newData[index].symbol = symbol;
                return newData;
              });
            }
            if (type === "looser") {
              setLooserData((prevData) => {
                const newData = [...prevData];
                newData[index].symbol = symbol;
                return newData;
              });
            }
            toast.success("Image Successfully Uploaded");
          } else {
            toast.error("Failed to retrieve the image URL from the response");
          }
        })
        .catch((err) => {
          toast.error("Failed to upload image");
        });
    } else {
      setErrors({ ...errors, empty: "No file selected." });
    }
  };

  const handleSubmit = (e) => {
    if (validateFields()) {
      const body = {
        gainers: gainersData,
        losers: loosersData,
      };



      ApiPost("admin/updateStocks", body)
        .then((res) => {
          console.log(res, "Res");
          toast.success(res.message);
        })
        .catch((err) => {
          let message ;
          if(err.message === "We couldn't find the stock data for today you requested!"){
            message = 'You can only update the data if you have added it today !'
            toast.warn(message)
          }else{
            message = err.message
            toast.error(message);
          }
        });
    }
  };

  const getData = () => {
    ApiGet("admin/getStocks")
      .then((res) => {
        console.log(res, "res");
        setGainersData(res?.data?.gainers);
        setLooserData(res?.data?.losers);
      })
      .catch((err) => {
        console.log(err, "res");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box className={classes.header_main}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "600", color: theme.palette.primary.main }}
        >
          Edit Top Gainers and Loosers
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Edit Top Gainers
          </Typography>
          {/* <form onSubmit={handleSubmit}> */}
            <Grid container spacing={2}>
              {gainersData &&
                gainersData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>Stock {index + 1} Data</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Name"}
                        placeholder={"Enter Stock Name"}
                        name="name"
                        value={item.name}
                        onChange={(e) => onChangeHandler(e, index)}
                      />
                      <Typography color="error">
                        {errors[`name-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Growth"}
                        placeholder={"Enter Stock Growth (In percentage)"}
                        name="change"
                        value={item.change}
                        onChange={(e) => onChangeHandler(e, index)}
                      />
                      <Typography color="error">
                        {errors[`change-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangeHandler(e, index, "gainer")}
                        style={{ display: "none" }}
                        id={`file-input-${index + 2}`}
                        name="gainer"
                      />
                      <label
                        htmlFor={`file-input-${index + 2}`}
                        className={classes.uploadButton}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<UploadIcon />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {errors.large && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.large}
                        </Typography>
                      )}
                      {errors.wrong && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.wrong}
                        </Typography>
                      )}
                      {errors.empty && (
                        <Typography color="error" className={classes.errorText}>
                          {errors.empty}
                        </Typography>
                      )}
                      {errors[`image-${index}`] && (
                        <Typography color="error" className={classes.errorText}>
                          {errors[`image-${index}`]}
                        </Typography>
                      )}
                      {item.symbol && (
                        <img
                          src={item.symbol}
                          alt={`Stock ${index + 1}`}
                          className={classes.imagePreview}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
            {/* <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "1rem auto",
                width: "fit-content",
                display: "block",
              }}
            >
              Update
            </Button> */}
          {/* </form> */}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "600", color: theme.palette.primary.main }}
          >
            Edit Top Loosers
          </Typography>

          {/* <form onSubmit={handleSubmit}> */}
            <Grid container spacing={2}>
              {loosersData &&
                loosersData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Typography>Stock {index + 1} Data</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Name"}
                        placeholder={"Enter Stock Name"}
                        name="name"
                        value={item.name}
                        onChange={(e) => onChangeHandlerForLoosers(e, index)}
                      />
                      <Typography color="error">
                        {errorsForLoosers[`name-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <CommonTextField
                        className={classes.input_main}
                        fontWeight={400}
                        text={"Stock Growth"}
                        placeholder={"Enter Stock Growth (In percentage)"}
                        name="change"
                        value={item.change}
                        onChange={(e) => onChangeHandlerForLoosers(e, index)}
                      />
                      <Typography color="error">
                        {errorsForLoosers[`change-${index}`]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangeHandler(e, index, "looser")}
                        style={{ display: "none" }}
                        id={`file-input-looser-${index + 1}`}
                        name="losser"
                      />
                      <label
                        htmlFor={`file-input-looser-${index + 1}`}
                        className={classes.uploadButton}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<UploadIcon />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {errorsForLoosers.large && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.large}
                        </Typography>
                      )}
                      {errorsForLoosers.wrong && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.wrong}
                        </Typography>
                      )}
                      {errorsForLoosers.empty && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers.empty}
                        </Typography>
                      )}
                      {errorsForLoosers[`image-${index}`] && (
                        <Typography color="error" className={classes.errorText}>
                          {errorsForLoosers[`image-${index}`]}
                        </Typography>
                      )}
                      {item.symbol && (
                        <img
                          src={item.symbol}
                          alt={`Stock ${index + 1}`}
                          className={classes.imagePreview}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
            {/* <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "1rem auto",
                width: "fit-content",
                display: "block",
              }}
            >
              Update
            </Button> */}
          {/* </form> */}
        </Grid>
        <Button
              variant="contained"
              type="submit"
              sx={{
                margin: "2rem auto",
                width: "fit-content",
                display: "block",
              }}
              onClick={handleSubmit}
            >
              Update Top Gainers & Loosers
            </Button>
      </Grid>
    </>
  );
};

export default EditGainerLooser;
